import { Link } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

function Neo(props) {
    const prodInfos = [
        {
            'title': '통합 업무 관리',
            'data': '한 화면에서 매출 내역, 고객 및 차량 정보, 기술자 소견까지 모든 정보를 한눈에 파악할 수 있는 직관적인 인터페이스를 제공합니다.'
        },
        {
            'title': '현장 조작의 용이성',
            'data': '터치모니터를 활용한 간편 조회 기능으로, 현장에서도 빠르게 필요한 정보를 얻을 수 있습니다. 이를 통해 작업 흐름이 원활해집니다.'
        },
        {
            'title': '데이터 기반 의사결정 지원',
            'data': '매출 분석, 현금 출납 관리 등을 포함한 포괄적인 통계 자료를 제공하여, 경영 전략을 데이터에 기반하여 수립할 수 있게 합니다.'
        }
    ]

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>업계에서 가장 호평받는 전문정비 전용 네오하이웨이</h2>
                            <p className="pt-4 product-sub-descr1">
                                전문정비용 프로그램의 완성판 <span className="pt-3 neo-color">3급 전문 정비업용 네오하이웨이</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img src="./assets/prod/neo_bg01.png" alt="네오하이웨이" className="client-thumb" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/neo1.png" alt="" />
                                                    </li>
                                                    <li>
                                                        종합적인 업무 관리
                                                    </li>
                                                    <li className="neo">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        차량의 입고부터 출고, 사후 관리에 이르기까지 종합적인 시스템을 구축하여 경영 효율성과 차량 관리를 극대화합니다. 이로 인해 고객 만족도 및 운영의 효율성이 증대됩니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neo2.png" alt="" />
                                                </li>
                                                <li>
                                                    포괄적 관리 도구
                                                </li>
                                                <li className="neo">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    정비 이력의 실시간 업데이트, 결산 관리, 재고 조절, 예방 점검 등 모든 경영 관리 과정을 아우르는 다기능 솔루션을 제공합니다. 이는 관리의 복잡성을 해소하고, 시간과 비용을 절약할 수 있게 합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neo3.png" alt="" />
                                                </li>
                                                <li>
                                                    융통성 있는 서비스 연동
                                                </li>
                                                <li className="neo">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    사무실과 현장의 경계 없이 PC와 모바일을 통해 원활하게 업무를 진행할 수 있는 유연한 서비스를 지원합니다. 어디서나 업무를 손쉽게 처리할 수 있는 원스톱 서비스를 경험할 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div
                            className={`col-12 text-center opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                        >
                            <img src="../assets/prod/img_neo01.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper neo">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">네오하이웨이로 운영의 질을 높이세요!</span><br />
                                <span className="product-copyright-sub text-white">전문 정비업을 위한 최적의 관리 솔루션으로,</span><br />
                                <span className="font-weight-bold">효율적인 운영과 고객 만족</span>을 동시에 달성하세요.<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="neo-color">네오 하이웨이</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${(props.boxAnimation02 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neo-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_neo_pc01.png" alt={prodInfos[0].title} className="client-thumb" />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neo-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation03 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img src="../assets/prod/img_neo_pc02.png" alt={prodInfos[1].title} className="client-thumb" />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti neo-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti neo-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${(props.boxAnimation04 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neo-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_neo_pc03.png" alt={prodInfos[2].title} className="client-thumb" />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neo-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>



            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod05?tabIndex=1">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-mobile-alt" />
                                    <p>
                                        네오탭
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=1">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-car" />
                                    <p>원부조회</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=2">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-comment-slash" />
                                    <p>080수신거부</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Neo