import { combineReducers, createStore } from 'redux';

// 로딩Bar 상태 StateReducer 시작
const initLoading = true

const rdcLoading = (state = initLoading, action) => {
    switch (action.type) {
        case 'loadingChange':
            state = action.payload
            return state
        default:
            return state
    }
}
// 로딩Bar 상태 StateReducer 끝

// reducer 여러개 사용시 combineReducers로 합쳐서 obj형태로 저장함
let store = createStore(combineReducers({ rdcLoading }))

export { store }