import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { useSpring, animated } from 'react-spring';
import { Wizard, useWizard } from 'react-use-wizard';
import { cls } from '../js/util';

const RadioOptions = ({ options, answerIndex, setAnswer, answer }) => {
    return (
        <>
            {options.map((option, index) => (
                <li key={index} className={index > 0 ? "pt-1" : ""}>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            id={`radioQuestion${answerIndex}-${index + 1}`}
                            checked={answer[answerIndex] === option.value}
                            onChange={() => setAnswer((prev) => {
                                let newAnswers = [...prev];
                                newAnswers[answerIndex] = option.value;
                                return newAnswers;
                            })} />
                        <label className="form-check-label" htmlFor={`radioQuestion${answerIndex}-${index + 1}`}>
                            {option.label}
                        </label>
                    </div>
                </li>
            ))}
        </>
    );
};

const Step = ({ children }) => {
    const animationProps = useSpring({
        from: { opacity: 0, transform: 'translateX(30%)' },
        to: { opacity: 1, transform: 'translateX(0%)' },
        config: { duration: 200 },
    });

    return <animated.div style={{ ...animationProps, width: '100%' }}>{children}</animated.div>;
};

const ProgressStep = ({ stepIndex }) => {
    return (
        <div className="row">
            <div className="col-4 d-flex justify-content-center align-items-center">
                <span className={cls(stepIndex === 0 && `active`, "stepper-circle")}>
                    <i className="fa-regular fa-building"></i>
                </span>
            </div>
            <div className="col-4 d-flex justify-content-center align-items-center">
                <span className={cls(stepIndex === 1 && `active`, "stepper-circle")}>
                    <i className="fa-regular fa-clipboard-list-check" />
                </span>
            </div>
            <div className="col-4 d-flex justify-content-center align-items-center">
                <span className={cls(stepIndex === 2 && `active`, "stepper-circle")}>
                    <i className="fa-regular fa-wand-magic-sparkles" />
                </span>
            </div>
        </div>
    )
}

const Step1 = ({ answer, setAnswer, stepIndex }) => {
    const { nextStep } = useWizard();
    const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

    return (
        <Step>
            <div className="container">
                <ProgressStep stepIndex={stepIndex} />

                <div className="row pt-5">
                    <div className="col-12">
                        <h4>사업 유형을 선택해 주세요.</h4>
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-12 pl-4">
                        <ul>
                            <RadioOptions
                                options={[
                                    { label: "1, 2급 자동차 종합정비업", value: 1 },
                                    { label: "3급 자동차 전문정비업", value: 2 }
                                ]}
                                answerIndex={0}
                                setAnswer={setAnswer}
                                answer={answer}
                            />
                            <li className="pt-4 text-center">
                                <button onClick={() => {
                                    if (!answer[0]) {
                                        alert("답변 선택 후 다음 단계로 진행이 가능합니다.");
                                    } else {
                                        nextStep()
                                    }
                                }} className="btn btn-light">다음</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Step>
    );
};

const Step2 = ({ answer, setAnswer, stepIndex }) => {
    const { previousStep, nextStep } = useWizard();
    const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

    return (
        <Step>
            <div className="container">
                <ProgressStep stepIndex={stepIndex} />

                <div className="row pt-5">
                    <div className="col-12">
                        {
                            answer[0] === 1
                                ? <h4>귀하가 주로 다루는 업무는 무엇입니까?</h4>
                                : <h4>귀하의 사업에서 중점을 두고 싶은 부분은 무엇입니까?</h4>
                        }
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-12 pl-4">
                        <ul>
                            {
                                answer[0] === 1
                                    ? <>
                                        <RadioOptions
                                            options={[
                                                { label: "고객 관리 및 일반 및 수입차 정비 등 종합 관리", value: 1 },
                                                { label: "종합 관리 포함 수입차 전문 정비 및 공임 관리", value: 2 },
                                                { label: "검사장 관리", value: 3 },
                                                { label: "보험 견적 관리", value: 4 },
                                            ]}
                                            answerIndex={1}
                                            setAnswer={setAnswer}
                                            answer={answer}
                                        />
                                    </>
                                    : <>
                                        <RadioOptions
                                            options={[
                                                { label: "고객 관리 및 기본 정비 등 종합 관리", value: 1 },
                                                { label: "종합 관리를 포함하여 차량재원 확보, 비대면 접수/예약 등", value: 2 },
                                                { label: "보험 견적 관리", value: 3 },
                                            ]}
                                            answerIndex={1}
                                            setAnswer={setAnswer}
                                            answer={answer}
                                        />
                                    </>
                            }

                            <li className="pt-4 text-center">
                                <button
                                    className="btn btn-light mr-2"
                                    onClick={() => previousStep()}>이전</button>

                                <button
                                    className="btn btn-light"
                                    onClick={() => {
                                        if (!answer[1]) {
                                            alert("답변 선택 후 다음 단계로 진행이 가능합니다.");
                                        } else {
                                            nextStep()
                                        }
                                    }} >다음</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Step>
    );
};

const Step3 = ({ answer, stepIndex }) => {
    const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

    const searchSolution = (arr) => {
        if (arr[0] === 1 && arr[1] === 1) {
            return `오토X<br /><button class="btn btn-outline-secondary mt-4" onClick="document.location.href='/Prod01?tabIndex=0'"><i class="fa-regular fa-circle-info" />&nbsp;자세히 보기</button>`
        } else if (arr[0] === 1 && arr[1] === 2) {
            return `아너스 하이웨이<br /><button class="btn btn-outline-secondary mt-4" onClick="document.location.href='/Prod01?tabIndex=3'"><i class="fa-regular fa-circle-info" />&nbsp;자세히 보기</button>`
        } else if (arr[0] === 1 && arr[1] === 3) {
            return `검사관리 시스템<br /><button class="btn btn-outline-secondary mt-4" onClick="document.location.href='/Prod01?tabIndex=2'"><i class="fa-regular fa-circle-info" />&nbsp;자세히 보기</button>`
        } else if (arr[0] === 1 && arr[1] === 4) {
            return `오토디엘<br /><button class="btn btn-outline-secondary mt-4" onClick="document.location.href='/Prod02?tabIndex=1'"><i class="fa-regular fa-circle-info" />&nbsp;자세히 보기</button>`
        } else if (arr[0] === 2 && arr[1] === 1) {
            return `네오하이웨이<br /><button class="btn btn-outline-secondary mt-4" onClick="document.location.href='/Prod03?tabIndex=1'"><i class="fa-regular fa-circle-info" />&nbsp;자세히 보기</button>`
        } else if (arr[0] === 2 && arr[1] === 2) {
            return `프리미엄네오<br /><button class="btn btn-outline-secondary mt-4" onClick="document.location.href='/Prod03?tabIndex=0'"><i class="fa-regular fa-circle-info" />&nbsp;자세히 보기</button>`
        } else if (arr[0] === 2 && arr[1] === 3) {
            return `오토디엘<br /><button class="btn btn-outline-secondary mt-4" onClick="document.location.href='/Prod02?tabIndex=2'"><i class="fa-regular fa-circle-info" />&nbsp;자세히 보기</button>`
        }
    }
    return (
        <Step>
            <div className="container">
                <ProgressStep stepIndex={stepIndex} />

                <div className="row pt-5 pb-5">
                    <div className="col-12 text-center">
                        <h4 dangerouslySetInnerHTML={{ __html: searchSolution(answer) }}></h4>
                    </div>
                </div>
            </div>
        </Step>
    );
};

function WidgetProgramChooseHelper({ handleCloseModal, showModal }) {
    const [answer, setAnswer] = useState([null, null])

    return (
        <Modal
            centered
            show={showModal}
            onHide={handleCloseModal}
            size="md"
        >
            <Modal.Header>
                <h4>나한테 맞는 솔루션 찾기</h4>
            </Modal.Header>
            <Modal.Body className="question-modal pt-4 overflow-hidden">
                <Wizard>
                    <Step1 answer={answer} setAnswer={setAnswer} stepIndex={0} />
                    <Step2 answer={answer} setAnswer={setAnswer} stepIndex={1} />
                    <Step3 answer={answer} stepIndex={2} />
                </Wizard>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleCloseModal}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default WidgetProgramChooseHelper