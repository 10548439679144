import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

function JumboCount(props) {
    return (
        <div className="jumbotron jumbotron-fluid main-jumbo-tron-bg01 mt-9">
            <div className="container">
                <VisibilitySensor
                    scrollCheck={true}
                    onChange={props.onVisibilityChange}
                    delayedCall
                >
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="text-white">
                                SINCE&nbsp;
                                <CountUp
                                    start={0}
                                    end={props.countNum[0]}
                                    delay={0.5}
                                    duration={1}
                                />
                            </h2>
                            <p className="text-white pt-1">
                                33년을 한결같이 고객과 함께 성장하는 인트라밴입니다.
                            </p>
                        </div>
                    </div>
                </VisibilitySensor>

                <div className="row">
                    <div className="col-12 text-center">
                        <ul className="main-jumbo-list-wrapper">
                            <li>
                                <p className="count-num">
                                    <CountUp
                                        start={0}
                                        end={props.countNum[1]}
                                        delay={0.5}
                                        duration={2}
                                        separator={","}
                                    />
                                </p>
                                인트라밴 솔루션
                                <br />
                                사용자수
                            </li>
                            <li>
                                <p className="count-num">1위</p>
                                정비업 솔루션
                                <br />
                                시장 점유
                            </li>
                            <li>
                                <p className="count-num">
                                    <CountUp
                                        start={0}
                                        end={props.countNum[2]}
                                        delay={0.5}
                                        duration={1}
                                    />
                                    년
                                </p>
                                자동차정비업솔루션
                                <br />
                                최장수기업
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JumboCount;
