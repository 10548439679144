import VisibilitySensor from "react-visibility-sensor";

function Identify(props) {
    const prodInfos = [
        {
            title: "손쉬운 조회로 신속한 응대 가능",
            data: "차량 번호 입력만으로 신속하게 차량 정보를 조회할 수 있으며, 이는 정확한 부품 수급 및 신속한 고객 응대에 기여합니다.",
        },
        {
            title: "다양한 차량 식별정보 제공",
            data: "차대번호, 엔진, 배기량, 연료, 미션, 연식 등의 차량 식별 정보를 포함하여, 정비소가 정확한 견적 및 정비 계획을 수립하는 데 필요한 정보를 제공합니다.",
        },
        {
            title: "소모품 및 주요 경정비 항목 정보까지",
            data: "엔진오일, 미션오일, 배터리, 와이퍼, 타이어, 에어컨 가스 주입량 등의 규격 정보 제공하여, 정비 과정을 보다 효율적으로 관리할 수 있습니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>국산차, 수입차 제원 및 규격정보 제공</h2>
                            <p className="pt-4 product-sub-descr1">
                                프리미엄네오를 통해 국산차 및 수입차량의 정확한 부품 수급과 신속한 정비를 지원하는 <span className="pt-3 etc-service-color">차량제원 및 규격정보</span> 조회 서비스
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/bg01_Identification.png"
                                alt="네오셋"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img
                                                            src="../assets/prod/ic_Identification.png"
                                                            alt=""
                                                        />
                                                    </li>
                                                    <li>차량 정보 파악</li>
                                                    <li className="etc-service">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        차량의 차대번호부터 엔진, 배기량, 연료 유형, 변속기 종류, 제조 연도 등 다양한 식별 정보를 제공합니다. 이를 통해 정비 및 견적 작성에 필요한 핵심 정보를 쉽게 얻을 수 있습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/ic_standard.png" alt="" />
                                                </li>
                                                <li>정비 용이성 강화</li>
                                                <li className="etc-service">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    엔진오일, 미션오일, 배터리, 와이퍼, 타이어 등의 규격 정보를 제공하여, 정비소의 서비스 품질을 향상시키고 고객 응대를 원활하게 합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/ic_update.png" alt="" />
                                                </li>
                                                <li>최신 차량 정보 제공</li>
                                                <li className="etc-service">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    신차 정보를 포함한 지속적인 업데이트를 통해, 최신 차종에 대한 파악이 용이해지며, 이는 정비 계획 수립에 있어 중요한 역할을 합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper neoset">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p style={{ lineHeight: "240%" }}>
                                <span className="product-copyright-ti">
                                    차량 식별 정보와 주요 소모품 규격 정보, 이제 프리미엄 네오에서 한번에!
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    정확한 정보로 시간을 절약
                                </span>{" "}
                                하고 고객 만족도를 높여보세요.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="etc-service-color">차량식별정보</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span
                                    className="product-main-function-descr-ti etc-service-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_Identification_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span
                                    className="product-main-function-descr-ti etc-service-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_Identification_pc02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti etc-service-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti etc-service-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti etc-service-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_Identification_pc03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti etc-service-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod03">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>프리미엄네오</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Identify;
