//ie11 사용을 위한 설정 시작
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
//ie11 사용을 위한 설정 끝
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

//Redux 사용 설정 시작
import { Provider } from 'react-redux'
import { store } from './reducer'

ReactDOM.render(
    <BrowserRouter>
        <Provider store={ store }>
            <App />
        </Provider>
    </BrowserRouter>,
    
    document.getElementById('root')
);

reportWebVitals();
