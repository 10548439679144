import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Header from "../comm/Header";

function Intro() {
    const dispatch = useDispatch();
    const [bottomTxtAnimation01, setBottomTxtAnimation01] = useState(null);
    const [bottomTxtAnimation02, setBottomTxtAnimation02] = useState(null);
    const [bottomTxtAnimation03, setBottomTxtAnimation03] = useState(null);

    const onVisibilityChangeText01 = (isVisible) => {
        if (isVisible) {
            setBottomTxtAnimation01("1");
        }
    };

    const onVisibilityChangeText02 = (isVisible) => {
        if (isVisible) {
            setBottomTxtAnimation02("1");
        }
    };

    const onVisibilityChangeText03 = (isVisible) => {
        if (isVisible) {
            setBottomTxtAnimation03("1");
        }
    };

    useEffect(
        () => {
            dispatch({ type: "loadingChange", payload: true });

            setTimeout(() => {
                dispatch({ type: "loadingChange", payload: false });
            }, 500);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            <section className="section intro">
                <Header page="sub" />

                <div className="container mt-7">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-white">회사소개</h2>
                            <p className="text-white pt-3">회사소개 &gt; 회사소개</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row pt-99 pb-99">
                    <div className="col-12">
                        <h2>성공 정비업의 시작!</h2>
                        <h4 className="pt-2">탁월한 솔루션 선택이 성공경영을 만듭니다.</h4>

                        <p className="pt-4 animated animatedFadeInUp fadeInUp">
                            <span className="text-bold sub-contents-line-height">
                                (주)인트라밴
                            </span>
                            은 1991년부터 30년 이상 오직 자동차 정비업 소프트웨어만을 전문으로
                            개발하고 있는 하이웨이시스템의 소프트웨어를 유통하는 IT전문 플랫폼
                            비즈니스 회사입니다
                        </p>

                        <p className="pt-4 animated animatedFadeInUp fadeInUp">
                            이에 <span className="text-bold">(주)인트라밴</span>은 고객에게
                            인정받는 소프트웨어를 중심으로 다양한 콘텐츠를 서비스하고 있으며
                            고객 감동을 실현하기 위해 최선을 다하고 있습니다.
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-100 pt-99 pb-5" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3 col-md-6 col-sm-12 pt-2">
                            <div className="intro-box">
                                <div
                                    className="d-flex d-flex-wrap align-items-center justify-content-center"
                                >
                                    <VisibilitySensor
                                        scrollCheck={true}
                                        onChange={onVisibilityChangeMid}
                                        delayedCall
                                    >
                                        <div 
                                            className={`text-center opacity-0 ${(midBoxAnimation === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                                        >
                                            <img src="../assets/img_consulting.png" alt="컨설팅" />
                                            <h4 className="pt-4">컨설팅</h4>
                                            <p className="pt-3">정비업 통합관리</p>
                                            <p>네트워크/DB관리</p>
                                            <p>정비업 경영정보관리</p>
                                            <p>체인점 통합관리</p>
                                            <p>비대면 고객관리</p>
                                        </div>  
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 pt-2">
                            <div className="intro-box">
                                <div
                                    className="d-flex d-flex-wrap align-items-center justify-content-center"
                                >
                                    <VisibilitySensor
                                        scrollCheck={true}
                                        onChange={onVisibilityChangeMid}
                                        delayedCall
                                    >
                                        <div 
                                            className={`text-center opacity-0 ${(midBoxAnimation === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                                        >
                                            <img src="../assets/img_si.png" alt="Si" />
                                            <h4 className="pt-4">Si</h4>
                                            <p className="pt-3">경영,고객관리시스템</p>
                                            <p>기업차량관리전산화</p>
                                            <p>기관간 협업시스템</p>
                                            <p>정비종합정보시스템</p>
                                            <p>보험차량견적EDI</p>
                                        </div>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 pt-2">
                            <div className="intro-box">
                                <div
                                    className="d-flex d-flex-wrap align-items-center justify-content-center"
                                >
                                    <VisibilitySensor
                                        scrollCheck={true}
                                        onChange={onVisibilityChangeMid}
                                        delayedCall
                                    >
                                        <div 
                                            className={`text-center opacity-0 ${(midBoxAnimation === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                                        >
                                            <img src="../assets/img_asp.png" alt="ASP" />
                                            <h4 className="pt-4">ASP솔루션</h4>
                                            <p className="pt-3">정비공장 종합관리</p>
                                            <p>전문정비업 종합관리</p>
                                            <p>정비업 체인솔루션</p>
                                            <p>협단체 물류관리</p>
                                            <p>사전응대 콜서비스</p>
                                        </div>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 pt-2">
                            <div className="intro-box">
                                <div
                                    className="d-flex d-flex-wrap align-items-center justify-content-center"
                                >
                                    <VisibilitySensor
                                        scrollCheck={true}
                                        onChange={onVisibilityChangeMid}
                                        delayedCall
                                    >
                                        <div 
                                            className={`text-center opacity-0 ${(midBoxAnimation === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                                        >
                                            <img src="../assets/img_mobile.png" alt="모바일" />
                                            <h4 className="pt-4">모바일솔루션</h4>
                                            <p className="pt-3">정비업 전문ASP개발</p>
                                            <p>ASP연동솔루션</p>
                                            <p>정비업 영업관리</p>
                                            <p>협단체 물류연동</p>
                                            <p>B2B2C 솔루션 </p>
                                        </div>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-1"></div>
                        <div className="col-sm-10 col-md-10 col-lg-10 text-center">
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={onVisibilityChangeText01}
                                delayedCall
                            >
                                <p
                                    className={`opacity-0 ${bottomTxtAnimation01 === "1"
                                        ? "animated animatedFadeInUp fadeInUp"
                                        : ""
                                        }`}
                                >
                                    2000년 2월 동종업계 최초로 BM 특허를 출원하였으며, 2014년에는
                                    자동차 정비업 협단체 물류 시스템의 전산화를 위해 다자간 거래
                                    확인을 위한 통합 물류 시스템(오토포스)으로 특허를 획득, 우수한
                                    기술력을 다시 한번 확인하였습니다. 이외에도 (주)인트라밴은
                                    자동차 정비 관련 특허를 다수 획득, 기술력으로서도 업계를
                                    리드해 가고 있습니다.
                                </p>
                            </VisibilitySensor>

                            <img
                                src="../assets/img_ebusiness.png"
                                alt=""
                                className="pt-9 pb-9 img-fluid"
                            />
                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>
            </div>

            <div className="w-100 pt-99 pb-99">
                <div className="container">
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-sm-10 col-md-10 col-lg-10 text-center">
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={onVisibilityChangeText02}
                                delayedCall
                            >
                                <p
                                    className={`opacity-0 ${bottomTxtAnimation02 === "1"
                                        ? "animated animatedFadeInUp fadeInUp"
                                        : ""
                                        }`}
                                >
                                    하이웨이 시스템과 (주)인트라밴은 지난 30여 년간 국가기관
                                    차량관리 시스템을 개발 및 유지 보수해 오고 있으며, 당사
                                    소프트웨어 사용자 간의 네트워크를 구축. 기술을 통한 휴먼
                                    드림을 완성해 가고 있습니다.
                                    <br />
                                    <br />
                                    앞으로도 자동차 정비업 IT를 리드하며 업계 발전을 이끌어
                                    가겠습니다.
                                </p>
                            </VisibilitySensor>

                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={onVisibilityChangeText03}
                                delayedCall
                            >
                                <p
                                    className={`ceo-sign pt-6 opacity-0 ${bottomTxtAnimation03 === "1"
                                        ? "animated animatedFadeInUp fadeInUp"
                                        : ""
                                        }`}
                                >
                                    대표이사&nbsp;&nbsp;&nbsp;<span>이건택</span>
                                </p>
                            </VisibilitySensor>
                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
