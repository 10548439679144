import "./Loading.css";

function Loading(props) {
  const fadeStyle = props.loading ? "fadeIn" : "fadeOut";

  return (
    <div className={`loading-container ${fadeStyle}`}>
      <div className="loading">
        <div className="loadingio-spinner-radio-nmqcy29klc">
          <div className="ldio-qgrgcihayo">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
