import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Header from "../comm/Header";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import "./StatsView.css";
//인쇄 컴포넌트
import ComponentToPrint from "./ComponentToPrint";
//DatePicker 컴포넌트
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//DatePicker 컴포넌트 언어설정
import { ko } from "date-fns/esm/locale";
import { reqData } from "../../js/server";
import { useHistory } from "react-router-dom";

function StatsView({ match, location, history }) {
  const dispatch = useDispatch();
  const usehistory = useHistory();
  const componentRef = useRef(null);
  const query = queryString.parse(location.search);
  const prgcode = query.prgcode || "";
  const comcode = query.comcode || "";

  let now = new Date();
  const [yyyymm, setYyyymm] = useState(
    new Date(now.setMonth(now.getMonth() - 1))
  );

  const [cominfo, setCominfo] = useState({});
  const [addService, setAddService] = useState([]);
  const [chartData, setChartData] = useState({
    data01: [],
    data01_reverse: [],
    data02: [],
    data03: [],
    data04: [],
    data04_reverse: [],
    data05: [],
    dataMessage: "",
    inno_order_total: [],
    inno_order_cnt: 0,
    inno_visit_cnt: 0,
  });
  const fetchComData = () => {
    const jsonData = {
      servicename: "STAT_Company",
      comcode: comcode,
      prgcode: prgcode,
    };

    reqData(jsonData)
      .then((res) => {
        if (res.data.result === "OK") {
          setCominfo(res.data.jsonData[0]);
          setAddService(res.data.jsonData_rc);
        } else {
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  const fetchChartData = () => {
    const _yyyymm =
      yyyymm.getFullYear() +
      "-" +
      ((yyyymm.getMonth() + 1).toString().length === 1
        ? "0" + (yyyymm.getMonth() + 1)
        : yyyymm.getMonth() + 1);
    const jsonData = {
      servicename: "STAT_DATA",
      comcode: comcode,
      yyyymm: _yyyymm,
    };

    reqData(jsonData)
      .then((res) => {
        if (res.data.result === "OK") {
          let _chartData = {};
          _chartData["data01"] = res.data.data01;
          _chartData["data01_reverse"] = res.data.data01_reverse;
          _chartData["data02"] = res.data.data02;
          _chartData["data03"] = res.data.data03;
          _chartData["data04"] = res.data.data04;
          _chartData["data04_reverse"] = res.data.data04_reverse;
          _chartData["data05"] = res.data.data05;
          _chartData["dataMessage"] = res.data.dataMessage;
          _chartData["inno_order_total"] = res.data.inno_order_total;
          _chartData["inno_order_cnt"] = res.data.inno_order_cnt;
          _chartData["inno_visit_cnt"] = res.data.inno_visit_cnt;
          setChartData(_chartData);
        } else {
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: "loadingChange", payload: false });
        }, 500);
      });
  };

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      if (comcode && prgcode) {
        fetchComData(); //업체정보
        fetchChartData(); //ChartData
      } else {
        alert("인트라밴 솔루션을 통해 요청해 주세요.");
        usehistory.push("/");
      }
    },
    // eslint-disable-next-line
    [yyyymm]
  );

  console.log(JSON.stringify(chartData));
  console.log(JSON.stringify(cominfo));

  //조회 기준일 이전달
  function fnPreClick() {
    let _yyyymm;
    _yyyymm = yyyymm.setMonth(yyyymm.getMonth() - 1);

    setYyyymm(new Date(_yyyymm));
  }

  //   //조회 기준일 다음달
  function fnNextClick() {
    let _yyyymm;
    _yyyymm = yyyymm.setMonth(yyyymm.getMonth() + 1);

    setYyyymm(new Date(_yyyymm));
  }

  return (
    <>
      <section className="section prod">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">
                {cominfo.comname || ""} 고객관리 활용도 분석
              </h2>
              <p className="text-white pt-3">통계 &gt; 고객관리 매니지먼트</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container pt-99">
        <div className="row">
          <div className="col-12 stats-box2">
            <ul className="search-bar2">
              <li>조회기준일</li>
              <li>
                <DatePicker
                  locale={ko}
                  dateFormat="yyyy-MM"
                  className="input-datepicker"
                  closeOnScroll={true}
                  placeholderText="yyyy-mm"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  selected={yyyymm}
                  onChange={(date) => setYyyymm(date)}
                />
              </li>
              <li>
                <div className="btn-group" role="group">
                  <button className="btn" type="button" onClick={fnPreClick}>
                    <i className="fas fa-arrow-left"></i>
                  </button>
                  <button className="btn" type="button" onClick={fnNextClick}>
                    <i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              </li>
              <li>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn" type="button">
                      <i className="fas fa-print"></i>
                    </button>
                  )}
                  content={() => componentRef.current}
                  pageStyle="@page { size: 2.5in 4in }"
                  documentTitle={`${
                    cominfo.comname
                  }_Report_${yyyymm.getFullYear()}-${
                    yyyymm.getMonth().toString().length === 1
                      ? "0" + (yyyymm.getMonth() + 1)
                      : yyyymm.getMonth() + 1
                  }`}
                />{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ComponentToPrint
        printRef={componentRef}
        chartData={chartData}
        cominfo={cominfo}
        addService={addService}
        yyyymm={`${yyyymm.getFullYear()}-${
          yyyymm.getMonth().toString().length === 1
            ? "0" + (yyyymm.getMonth() + 1)
            : yyyymm.getMonth() + 1
        }`}
      />
    </>
  );
}

export default StatsView;
