export const productInfo = [
    {
        prodName: "차량 원부 조회",
        funcDescr: "이 서비스는 <span>자동차 등록 원부</span> 조회가 가능하며, <span>차대번호</span>, <span>원동기 형식</span>, <span>최초 등록일</span>, <span>검사 만료일</span> 등 차량 수리에 필요한 필수 정보를 조회할 수 있습니다. 또한, 중고차 ,매매 의뢰 시 <span>압류 및 근저당 확인</span>까지 가능합니다.",
        tips: "1. 입고차량의 <span>자동차 등록증</span>을 확인해야 할 경우<br />2. 정확한 수리를 위해 차량의<span>세부 제원 정보</span> 가 필요할 경우<br />3. 정확한 부품 수급을 위한 <span> 차대번호</span>와 <span>형식정보</span>가 필요할 경우 <br />4. <span>검사만료일</span> 등 고객 알림 서비스를 하고자 할 경우 <br />5. 중고차 매매를 위한 고객 문의에 대해 <>압류 등 근저당 확인</> 이 필요한 경우"
    },
    {
        prodName: "차량식별 및 규격정보",
        funcDescr: "차량번호 입력만으로 <span>차대번호</span>, <span>연식</span>, <span>연료</span>, <span>엔진</span>, <span>변속기</span>, <span>바디</span> 등 차량 식별 정보와 <span>엔진오일 주입량</span>, <span>와이퍼</span>, <span>배터리</span>, <span>타이어 규격</span> 등의 규격 정보를 조회할 수 있습니다. 이를 통해 신속한 부품 수급과 효과적인 고객 응대가 가능합니다.",
        tips: "1. 국산, 외산 차량의 <span>소모품 규격정보</span>를 알고 싶을 경우<br />2. 전화문의 고객 차량의 정보를 <span>신속하게 조회</span>하고자 할 경우<br />3. 차종별 <span>정확한 주입량과 부품 규격</span>을 알고 싶을 경우"
    },
    {
        prodName: "수입차 부품 검색&구매",
        funcDescr: "본 서비스는 <span>수입차의 필수 제원 정보</span>와 <span>OE부품 품번</span> 조회를 제공합니다. 다양한 규격부품의 정보(품목, 가격) 비교와 함께 제공되며, 이를 통해 즉시 온라인 부품 구매가 가능합니다.",
        tips: "1. 외산 차량의 <span>엔진코드</span>, <span>샤시정보</span> 및 <span>소모품 주입량</span> 및 <span>규격정보</span>를 알고 싶을 경우<br />2. <span>OE품번</span>을 알고 싶을 경우<br />3. 외산차 부품을 <span>구매</span>하고 싶을 경우<br />4. OE부품 외에 <span>규격품 정보(제조사, 가격)</span>를 알고 싶을 경우"
    },
    {
        prodName: "Honors 수입차 견적",
        funcDescr: "본 서비스는 제작사별 주요 <span>정비작업시간</span>에 대한 <span>참고 작업시간</span>을 제공하여, 수입차 수리비 산정에 필요한 신뢰할 수 있는 길라잡이가 됩니다. 또한, 모델별 <span>엔진코드</span>와 <span>샤시</span> 정보 제공을 통해 더욱 정확하고 효율적인 정비가 가능하게 합니다.",
        tips: "1. <span>수입차 공임</span>을 얼마나 받아야 하는지 고민하는 업체<br />2. 차종 모델에 따라 <span>소모품별 작업시간</span>이 궁금한 업체<br />3. 프랜차이즈를 운영하며 <span>공임을 통일화</span> 하고자 하는 회사"
    },
    {
        prodName: "네오셋 (세트부품)",
        funcDescr: "본 서비스는 <span>약 120여 개의 정비 작업 항목</span>(소모품 포함)과 <span>약 30만 개의 부품 데이터</span>(품번, 가격)를 연동하여, 수리 전 신속한 예상 견적 작성과 정확한 부품 주문을 가능하게 합니다.",
        tips: "1. 입고차량의 신속한 <span>예상 견적</span>을 작성해야 할 경우<br />2. 소요부품의 <span>확한 품번과 가격</span>을 조회하여 <span>고객응대</span>를 해야 할 경우<br />3. 수리내용을 입력할 시 <span>견적내용을 쉽게 불러오고</span> 싶을 경우<br />4. 현재 <span>현대, 기아 차량위주</span>로 개발되어 있으며 지속적인 업데이트 진행"
    },
    {
        prodName: "사고차 보험 견적 및 청구",
        funcDescr: "본 시스템은 AI 기술을 활용해 <span>사고차 견적 빅데이터</span>를 분석, <span>평균 작업 시간</span>을 제공하며 <span>다양한 수가</span> 정보(카이마, GES 등)가 탑재되어 있습니다. <span>작업 항목 누락 방지</span>와 <span>자동 견적 작성</span>을 지원하고, 타 업체 간 <span>견적 공유</span>를 통한 비교 <span>견적 학습</span>이 가능합니다. 또한, <span>모바일 연동</span>을 통해 정비공장의 종합 관리까지 지원합니다.",
        tips: "1. <span>보험 견적 작성</span>과 <span>수리비 청구</span>가 필요한 업체  (카톡, 이메일 발송 기능)<br />2. 모바일 앱과 연동하여 <span>작업 사진 촬영</span> 및 <span>부서별 사진관리</span> 기능이 필요한 업체<br />3. 일반, 보험 정비에 대한 작업구분와 청구, 입출금 관리 등 <span>종합관리</span>가 필요한 업체"
    },
    {
        prodName: "국토부 정비이력 전송 프로그램",
        funcDescr: "본 서비스는 <span>자동차관리법에 규정된 항목</span> 정비 시 그 내용을 법에서 지정한 서버로 전송가능하도록 구성되어 있습니다. 이를 통해 수리 내용 <span>작성과 동시에 정보 전송</span>이 가능하여, <span>중복 작업을 방지</span>하고 효율성을 극대화할 수 있습니다.",
        tips: "1. <span>국토부 전송이력</span>을 별도로 관리하고 싶은 업체<br />2. 소규모 프랜차이즈 운영사로 가맹점 <span>일괄 전송 시스템을 구축</span>하고 싶은 업체<br />3. 법적 정비이력 <span>전송의무</span>가 있는 업체"
    },
    {
        prodName: "클라우드 실시간 백업",
        funcDescr: "<span>랜섬웨어</span>로부터 안전한 <span>클라우드 저장 공간</span>에서 데이터가 백업됩니다. 언제 어디서나 데이터에 접근할 수 있으며, PC 환경이 바뀌어도 모든 정보는 <span>손상 없이 그대로 유지</span>됩니다.",
        tips: "1. 악성 바이러스 침투로 <span>데이터 손실</span>을 경험한 업체<br />2. 컴퓨터 <span>교체가 잦은</span> 업체<br />3. <span>사무실 외부</span>에서 전산 관리가 필요한 업체"
    },
    {
        prodName: "모바일앱 (2대)",
        funcDescr: "언제 어디서나 모바일 앱을 통해 기본 <span>솔루션과 연동</span>, <span>현장</span>에서도 고객과의 <span>원활한 소통</span>을 지원하는 편리한 업무처리 도구를 제공합니다.",
        tips: "1. <span>업무 공유</span>를 모바일로 부서별 혹은 현장에서도 원활히 하고 싶은 업체<br />2. <span>일별</span>, <span>월별 매출 현황</span>과 <span>입출고 현황</span>을 모바일로 확인하고자 할 경우<br />3. 고객 차량 정보를 모바일로 조회하고 관리를 병행하고 싶은 업체"
    }
]