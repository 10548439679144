import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../comm/Header";
import AutoX from "./AutoX"; //오토X 상품소개
import Auto7 from "./Auto7"; //오토7 상품소개
import TrsSystem from "./TrsSystem"; //차검사정비예약
import Honors from "./Honors"; //아너스하이웨이
import queryString from "query-string";
import Tabs from "./Tabs";
import Tab from "./Tab";

function Prod01({ match, location, history }) {
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const tabIndex = Number(query.tabIndex) || 0;
  const [subTitle, setSubTitle] = useState();

  //탭1 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01, setBoxAnimation01] = useState(null);
  const [boxAnimation01_sub, setBoxAnimation01_sub] = useState(null);
  const [boxAnimation02, setBoxAnimation02] = useState(null);
  const [boxAnimation03, setBoxAnimation03] = useState(null);
  const [boxAnimation04, setBoxAnimation04] = useState(null);
  const [boxAnimation05, setBoxAnimation05] = useState(null);
  const [boxAnimation06, setBoxAnimation06] = useState(null);
  const [boxAnimation07, setBoxAnimation07] = useState(null);

  const onVisibilityBox01Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01("1");
    }
  };

  const onVisibilityBox01ChangeSub = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_sub("1");
    }
  };

  const onVisibilityBox02Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02("1");
    }
  };

  const onVisibilityBox03Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03("1");
    }
  };

  const onVisibilityBox04Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04("1");
    }
  };

  const onVisibilityBox05Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05("1");
    }
  };

  const onVisibilityBox06Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation06("1");
    }
  };

  const onVisibilityBox07Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation07("1");
    }
  };
  //탭1 CSS 관련 STATE 및 Function 끝

  //탭2 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_1, setBoxAnimation01_1] = useState(null);
  const [boxAnimation02_1, setBoxAnimation02_1] = useState(null);
  const [boxAnimation03_1, setBoxAnimation03_1] = useState(null);
  const [boxAnimation04_1, setBoxAnimation04_1] = useState(null);

  const onVisibilityBox01_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_1("1");
    }
  };

  const onVisibilityBox02_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_1("1");
    }
  };

  const onVisibilityBox03_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_1("1");
    }
  };

  const onVisibilityBox04_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_1("1");
    }
  };
  //탭2 CSS 관련 STATE 및 Function 끝

  //탭3 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_2, setBoxAnimation01_2] = useState(null);
  const [boxAnimation02_2, setBoxAnimation02_2] = useState(null);
  const [boxAnimation03_2, setBoxAnimation03_2] = useState(null);
  const [boxAnimation04_2, setBoxAnimation04_2] = useState(null);
  const [boxAnimation05_2, setBoxAnimation05_2] = useState(null);
  const [boxAnimation06_2, setBoxAnimation06_2] = useState(null);
  const [boxAnimation07_2, setBoxAnimation07_2] = useState(null);
  const [boxAnimation08_2, setBoxAnimation08_2] = useState(null);
  const [boxAnimation09_2, setBoxAnimation09_2] = useState(null);
  const [boxAnimation10_2, setBoxAnimation10_2] = useState(null);
  const [boxAnimation11_2, setBoxAnimation11_2] = useState(null);

  const onVisibilityBox01_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_2("1");
    }
  };

  const onVisibilityBox02_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_2("1");
    }
  };

  const onVisibilityBox03_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_2("1");
    }
  };

  const onVisibilityBox04_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_2("1");
    }
  };

  const onVisibilityBox05_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05_2("1");
    }
  };

  const onVisibilityBox06_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation06_2("1");
    }
  };

  const onVisibilityBox07_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation07_2("1");
    }
  };

  const onVisibilityBox08_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation08_2("1");
    }
  };

  const onVisibilityBox09_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation09_2("1");
    }
  };

  const onVisibilityBox10_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation10_2("1");
    }
  };

  const onVisibilityBox11_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation11_2("1");
    }
  };
  //탭3 CSS 관련 STATE 및 Function 끝

  //탭4 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_3, setBoxAnimation01_3] = useState(null);
  const [boxAnimation02_3, setBoxAnimation02_3] = useState(null);
  const [boxAnimation03_3, setBoxAnimation03_3] = useState(null);
  const [boxAnimation04_3, setBoxAnimation04_3] = useState(null);
  const [boxAnimation05_3, setBoxAnimation05_3] = useState(null);
  const [boxAnimation06_3, setBoxAnimation06_3] = useState(null);
  const [boxAnimation07_3, setBoxAnimation07_3] = useState(null);

  const onVisibilityBox01_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_3("1");
    }
  };

  const onVisibilityBox02_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_3("1");
    }
  };

  const onVisibilityBox03_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_3("1");
    }
  };

  const onVisibilityBox04_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_3("1");
    }
  };

  const onVisibilityBox05_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05_3("1");
    }
  };

  const onVisibilityBox06_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation06_3("1");
    }
  };

  const onVisibilityBox07_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation07_3("1");
    }
  };
  //탭4 CSS 관련 STATE 및 Function 끝

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="section prod">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">{subTitle}</h2>
              <p className="text-white pt-3">솔루션 비즈니스 &gt; {subTitle}</p>
            </div>
          </div>
        </div>
      </section>

      <div className="tabs pt-99 pb-99">
        <Tabs index={tabIndex} setSubTitle={setSubTitle}>
          <Tab label="오토X">
            <AutoX
              boxAnimation01={boxAnimation01}
              boxAnimation01_sub={boxAnimation01_sub}
              boxAnimation02={boxAnimation02}
              boxAnimation03={boxAnimation03}
              boxAnimation04={boxAnimation04}
              boxAnimation05={boxAnimation05}
              boxAnimation06={boxAnimation06}
              boxAnimation07={boxAnimation07}
              onVisibilityBox01Change={onVisibilityBox01Change}
              onVisibilityBox01ChangeSub={onVisibilityBox01ChangeSub}
              onVisibilityBox02Change={onVisibilityBox02Change}
              onVisibilityBox03Change={onVisibilityBox03Change}
              onVisibilityBox04Change={onVisibilityBox04Change}
              onVisibilityBox05Change={onVisibilityBox05Change}
              onVisibilityBox06Change={onVisibilityBox06Change}
              onVisibilityBox07Change={onVisibilityBox07Change}
            />
          </Tab>
          <Tab label="오토7">
            <Auto7
              boxAnimation01={boxAnimation01_1}
              boxAnimation02={boxAnimation02_1}
              boxAnimation03={boxAnimation03_1}
              boxAnimation04={boxAnimation04_1}
              onVisibilityBox01Change={onVisibilityBox01_1Change}
              onVisibilityBox02Change={onVisibilityBox02_1Change}
              onVisibilityBox03Change={onVisibilityBox03_1Change}
              onVisibilityBox04Change={onVisibilityBox04_1Change}
            />
          </Tab>
          <Tab label="검사관리시스템">
            <TrsSystem
              boxAnimation01={boxAnimation01_2}
              boxAnimation02={boxAnimation02_2}
              boxAnimation03={boxAnimation03_2}
              boxAnimation04={boxAnimation04_2}
              boxAnimation05={boxAnimation05_2}
              boxAnimation06={boxAnimation06_2}
              boxAnimation07={boxAnimation07_2}
              boxAnimation08={boxAnimation08_2}
              boxAnimation09={boxAnimation09_2}
              boxAnimation10={boxAnimation10_2}
              boxAnimation11={boxAnimation11_2}
              onVisibilityBox01Change={onVisibilityBox01_2Change}
              onVisibilityBox02Change={onVisibilityBox02_2Change}
              onVisibilityBox03Change={onVisibilityBox03_2Change}
              onVisibilityBox04Change={onVisibilityBox04_2Change}
              onVisibilityBox05Change={onVisibilityBox05_2Change}
              onVisibilityBox06Change={onVisibilityBox06_2Change}
              onVisibilityBox07Change={onVisibilityBox07_2Change}
              onVisibilityBox08Change={onVisibilityBox08_2Change}
              onVisibilityBox09Change={onVisibilityBox09_2Change}
              onVisibilityBox10Change={onVisibilityBox10_2Change}
              onVisibilityBox11Change={onVisibilityBox11_2Change}
            />
          </Tab>
          <Tab label="아너스 하이웨이">
            <Honors
              boxAnimation01={boxAnimation01_3}
              boxAnimation02={boxAnimation02_3}
              boxAnimation03={boxAnimation03_3}
              boxAnimation04={boxAnimation04_3}
              boxAnimation05={boxAnimation05_3}
              boxAnimation06={boxAnimation06_3}
              boxAnimation07={boxAnimation07_3}
              onVisibilityBox01Change={onVisibilityBox01_3Change}
              onVisibilityBox02Change={onVisibilityBox02_3Change}
              onVisibilityBox03Change={onVisibilityBox03_3Change}
              onVisibilityBox04Change={onVisibilityBox04_3Change}
              onVisibilityBox05Change={onVisibilityBox05_3Change}
              onVisibilityBox06Change={onVisibilityBox06_3Change}
              onVisibilityBox07Change={onVisibilityBox07_3Change}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default Prod01;
