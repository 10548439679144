import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

function Honors(props) {
    const prodInfos = [
        {
            title: "아너스 메인 대시보드",
            data: "다양한 메뉴를 통해 전반적인 관리와 기능을 효과적으로 사용할 수 있습니다. 사용자 친화적인 인터페이스로 모든 기능을 한눈에 파악하고 손쉽게 접근할 수 있습니다.",
        },
        {
            title: "차량 규격 정보 확인",
            data: "차량의 중요 규격 정보를 손쉽게 조회할 수 있으며, 이를 통해 보다 정확한 정비를 지원합니다. 모든 차량 정보가 업데이트되어 정비의 정확성을 보장합니다.",
        },
        {
            title: `자동 전표 입력 "네오스캔"`,
            data: "사진 촬영만으로 매입 전표를 자동으로 입력할 수 있는 혁신적인 시스템입니다. 이를 통해 관리의 편의성을 대폭 향상시키고 작업 시간을 단축시킵니다.",
        },
        {
            title: `온라인 모바일 차계부 "네오북"`,
            data: "고객에게 직접 제공되는 온라인 모바일 차계부 서비스로, 고객의 차량 관리를 더욱 편리하게 해줍니다. 이는 고객 만족도를 높이는 데 크게 기여합니다.",
        },
        {
            title: `내 업소 위치 고객 전송 서비스 "정비맛집"`,
            data: "업소의 주력 서비스와 위치 정보를 고객에게 쉽고 빠르게 전송할 수 있는 서비스입니다. 이를 통해 고객 유치 및 서비스 접근성을 높일 수 있습니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>"아너스 하이웨이" 수입차 정비의 혁신</h2>
                            <p className="pt-4 product-sub-descr1">
                                국내최초! 수입차 정비항목의{" "}
                                <span className="pt-3 honors-color">
                                    표준작업시간 개념 도입!
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/honors_bg01.png"
                                alt="아너스하이웨이"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/honors1.png" alt="" />
                                                    </li>
                                                    <li>차량정보 제공</li>
                                                    <li className="honors">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        국산 및 수입차의 차량제원 및 규격을 일목요연하게 제공합니다. 정비에 필요한 모든 정보를 손쉽게 접근할 수 있어, 시간을 절약하고 정확도를 높일 수 있습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/honors2.png" alt="" />
                                                </li>
                                                <li>표준작업시간</li>
                                                <li className="honors">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    수입차 정비에 필요한 시간을 최적화하기 위해 표준작업시간 개념을 도입했습니다. 이를 통해 정비소의 작업 효율성을 극대화하고 고객 대기 시간을 최소화할 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/honors3.png" alt="" />
                                                </li>
                                                <li>부품 신속조회</li>
                                                <li className="honors">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    차량번호 또는 차대번호를 통해 필요한 부품을 신속하게 검색하고 구매까지 이어질 수 있는 편리한 시스템을 제공합니다. 이는 정비 과정의 속도와 정확성을 동시에 향상시킵니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="honors-color">아너스 하이웨이</span>
                            <span>의 차별화된 기술력</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="honors-color">전문 플랫폼 제공</span>
                                    <br />
                                    <br />
                                    <span>
                                        수입차 정비에 최적화된
                                        <br />
                                        원스톱 예약정비 서비스 제공
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="honors-color">프랜차이즈 역할 강화</span>
                                    <br />
                                    <br />
                                    <span>
                                        필요 정보 분석을 통한
                                        <br />
                                        가맹점 맞춤형 대책 지원
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="honors-color">통일된 정비 서비스</span>
                                    <br />
                                    <br />
                                    <span>
                                        전국적으로 일관된 수준의
                                        <br />
                                        정비 서비스 보장
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="honors-color">고급 고객 관리 기능</span>
                                    <br />
                                    <br />
                                    <span>
                                        프랜차이즈 본사 수준의 SMS
                                        <br />및 전국 통일 프로모션 지원
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="honors-color">수입차 정비 최적화</span>
                                    <br />
                                    <br />
                                    <span>
                                        차량번호로 차종별
                                        <br />
                                        맞춤 부품 신속 제공
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="honors-color">획기적인 정보 제공</span>
                                    <br />
                                    <br />
                                    <span>
                                        국산 및 수입차에 대한
                                        <br />
                                        30여 가지 규격 정보 제공
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox07Change}
                            delayedCall
                        >
                            <div className="row">
                                <div className="col-12">&nbsp;</div>
                            </div>
                        </VisibilitySensor>

                        <div
                            className={`col-12 opacity-0 ${props.boxAnimation07 === "1"
                                ? "animated animatedFadeInUp fadeIn"
                                : ""
                                }`}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 product-why-wrapper">
                                        <div className="text-center product-main-function-ti">
                                            <span>Why?</span>&nbsp;
                                            <span className="honors-color">Honors Highway</span>
                                        </div>

                                        <div className="col-12">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/icon04_honors.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            국산 및 수입차에 대한 차량제원 및 규격정보를
                                                            포함한 30여 가지의 중요 정보를 제공함으로써,
                                                            정비의 정확성과 효율성을 극대화합니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/icon05_honors.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            수입차 정비에 특화된 원스톱 예약정비 서비스를
                                                            제공하며, 가맹점에 필요한 정보를 분석하여 맞춤형
                                                            대책을 지원합니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/icon06_honors.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            프랜차이즈 본사 수준의 SMS 및 전국 통일 프로모션
                                                            지원을 통해 고객과의 연결을 강화하고, 서비스
                                                            만족도를 높입니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 text-center">
                                            <img
                                                src="../assets/prod/icon07_honors.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>

                                        <div className="col-12 text-center product-why-wrapper">
                                            <h4
                                                className="honors-color"
                                                style={{ display: "inline" }}
                                            >
                                                "아너스 하이웨이"
                                            </h4>
                                            <h4 style={{ display: "inline" }}>
                                                는 이 3가지 이유로 수입차 정비업체와 프랜차이즈의 필수
                                                솔루션이 됩니다.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper honors">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    수입차 정비의 혁신, 아너스 하이웨이!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    33년의 전문성을 바탕으로 (주)인트라밴이 선보이는 최고의 수입차
                                    관리 솔루션.
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    곧 경험하실 수 있습니다!
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="honors-color">아너스 하이웨이</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti honors-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_honors_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti honors-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_honors_pc02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti honors-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti honors-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti honors-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_honors_pc03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti honors-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_honors_pc04.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti honors-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti honors-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${props.boxAnimation06 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti honors-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox06Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_honors_pc05.png"
                                    alt={prodInfos[4].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti honors-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod05?tabIndex=0">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-mobile-alt" />
                                    <p>오토X 모바일</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=1">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-car" />
                                    <p>원부조회</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=2">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-comment-slash" />
                                    <p>080수신거부</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Honors;
