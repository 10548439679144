import VisibilitySensor from 'react-visibility-sensor'

function AutoposMob(props) {
    const prodInfos = [
        {
            'title': '직관적인 업체 관리',
            'data': '업체를 지역별, 순회 일정별로 선정하여 관리할 수 있으며, 초성 검색 기능을 통해 필요한 정보를 빠르게 찾을 수 있습니다.'
        },
        {
            'title': '사용자 친화적 출고 처리',
            'data': '상품 검색 및 출고 작업을 쉽고 효율적으로 수행할 수 있도록 설계되었습니다. 이는 업무의 부담을 줄여주며 사용자 경험을 향상시킵니다.'
        },
        {
            'title': '상세한 매출 분석',
            'data': '일별, 월별 매출 현황을 손쉽게 확인할 수 있으며, 어느 위치에서든 매출 동향을 파악할 수 있습니다. 이는 경영 의사결정에 귀중한 정보를 제공합니다.'
        }
    ]

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>PC만이 아닌 모바일로도 물류 확인 가능합니다.</h2>
                            <p className="pt-4 product-sub-descr1">
                                오토포스 PC버전과 연동되는 <span className="pt-3 autopos-mob-color">협단체용 물류 관리 프로그램 모바일 오토포스</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img src="./assets/prod/autopos_m_bg01.png" alt="오토포스" className="client-thumb" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/autopos-mob1.png" alt="" />
                                                    </li>
                                                    <li>
                                                        유연한 사용 경험
                                                    </li>
                                                    <li className="autopos-mob">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        협력업체 직원들이 현장을 순회하며 매출 데이터를 입력할 수 있어, 언제 어디서나 업무 진행이 가능합니다.<br />이는 시간적 제약을 크게 줄여줍니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autopos-mob2.png" alt="" />
                                                </li>
                                                <li>
                                                    강화된 업무 관리
                                                </li>
                                                <li className="autopos-mob">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    실시간으로 지회의 판매 데이터를 파악하여, 필요에 따라 각 업체 간의 협조를 요청할 수 있습니다.<br />이는 전체 네트워크의 효율성을 높여줍니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autopos-mob3.png" alt="" />
                                                </li>
                                                <li>
                                                    즉각적인 업무 지원
                                                </li>
                                                <li className="autopos-mob">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    모바일 앱을 통해 현장에서 프린터에 연결하여 명세서를 인쇄하고 고객에게 제공할 수 있습니다.<br />이는 업무의 신속성을 극대화합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper autopos-mob">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">오토포스 모바일, 업무 효율성의 새 지평을 엽니다!</span><br />
                                <span className="product-copyright-sub text-white">현장에서 직접 물류와 매출을 관리하는</span><br />
                                <span className="font-weight-bold">혁신적인 방법을</span> 제공합니다.<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="autopos-mob-color">모바일 오토포스</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${(props.boxAnimation02 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autopos-mob-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                <img src="../assets/prod/img_autopos_m_m01.png" alt={prodInfos[0].title} />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autopos-mob-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation03 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                    <img src="../assets/prod/img_autopos_m_m02.png" alt={prodInfos[1].title} />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti autopos-mob-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti autopos-mob-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${(props.boxAnimation04 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autopos-mob-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                <img src="../assets/prod/img_autopos_m_m03.png" alt={prodInfos[2].title} />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autopos-mob-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod07?tabIndex=0">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-desktop" />
                                    <p>
                                        오토포스
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AutoposMob