import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../comm/Header'

function NationWideNetwork() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: 'loadingChange', payload: true })

        setTimeout(() => {
            dispatch({ type: 'loadingChange', payload: false })
        }, 500)
    },
        // eslint-disable-next-line
        []);

    return (
        <>
            <section className="section intro">
                <Header page="sub" />

                <div className="container mt-7">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-white">전국 서비스망 네트워크</h2>
                            <p className="text-white pt-3">고객센터 &gt; 전국 서비스망 네트워크</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row pt-99 pb-9">
                    <div className="col-12">
                        <h2>인트라밴은 업계 유일 전국 오프라인 A/S 네트워크망을 구축하고 있습니다.</h2>
                        <p className="pt-4 animated animatedFadeInUp fadeInUp">
                            (주)인트라밴은{" "}
                            <span className="text-bold sub-contents-line-height">
                                업계에서 유일
                            </span>
                            하게{" "}
                            <span className="text-bold sub-contents-line-height">전국적인 오프라인 A/S 네트워크</span>
                            를 보유하고 있으며, 이는 단순한 서비스 네트워크를 넘어 고객님의 성공적인 비즈니스 파트너로 자리매김하고 있습니다.
                        </p>

                        <p className="pt-4 animated animatedFadeInUp fadeInUp">
                            온라인 플랫폼과 결합된 우리의 오프라인 서비스 접근 방식은{" "}
                            <span className="text-bold sub-contents-line-height">[신속성, 정확성, 친절함]</span>이라는 서비스의 3대 원칙을 바탕으로, 전국 어디에서나 동일한 최고 수준의 지원을 제공합니다.
                        </p>

                        <p className="pt-4 animated animatedFadeInUp fadeInUp">
                            경쟁사들이 추구하는 것과는 차별화된,{" "}
                            <span className="text-bold sub-contents-line-height">직접적인 현장 지원</span>
                            을 통해 여러분의 비즈니스는 언제나 최상의 조건에서 운영될 수 있습니다.
                        </p>

                        <p className="pt-4 animated animatedFadeInUp fadeInUp">
                            <span className="text-bold sub-contents-line-height">
                                (주)인트라밴
                            </span>은 단순히 문제를 해결하는 것을 넘어, 고객님의 성공적인 사업 운영을 위한 든든한 기반이 되고자 합니다
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-100 pt-9 pb-9" style={{ 'backgroundColor': '#f9f9f9' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <img src="../assets/iv_network.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12 mt-9">
                        <div className="question-info-container-2">
                            <div className="row">
                                <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                                    <img src="../assets/question/sub04_logo.png" alt="" />
                                </div>
                                <div className="col-lg-9 col-md-12 col-sm-12 text-left">
                                    대표전화 : 1522-3840<br />대표메일 : intravan@intravan.com
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row pt-5 pb-99'>
                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>본사</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>1522-3840</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>대전본부</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>042-826-0615</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>경기지사</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>031-918-5118</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>인천지사</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>032-552-6433</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>강원지사</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>010-6344-6919</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>전북지사</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>0505-304-1181~2</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>광주지사</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>062-381-0035</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>부산지사</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>051-892-1474</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 pt-5'>
                        <ul className="area-worker">
                            <li>제주지사</li>
                            {
                                (localStorage.getItem('comcode'))
                                    ? (
                                        <li>010-6798-9393</li>
                                    )
                                    : null
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NationWideNetwork