import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

function PEO(props) {
    const prodInfos = [
        {
            title: "통합 업무 관리",
            data: "한 화면에서 매출 내역, 고객 및 차량 정보, 기술자 소견까지 모든 정보를 한눈에 파악할 수 있는 직관적인 인터페이스를 제공합니다.",
        },
        {
            title: "현장 조작의 용이성",
            data: "터치모니터를 활용한 간편 조회 기능으로, 현장에서도 빠르게 필요한 정보를 얻을 수 있습니다. 이를 통해 작업 흐름이 원활해집니다.",
        },
        {
            title: "데이터 기반 의사결정 지원",
            data: "매출 분석, 현금 출납 관리 등을 포함한 포괄적인 통계 자료를 제공하여, 경영 전략을 데이터에 기반하여 수립할 수 있게 합니다.",
        },
        {
            title: "비대면 접수 및 마이 데이터 제공",
            data: "QR코드를 통한 접수와 차량제원 제공을 통해 고객에게 맞춤형 서비스를 제공하며, 이를 통해 고객 만족도를 높이고 추가 수익을 창출할 수 있습니다.",
        },
        {
            title: "월 200건 무료 제공",
            data: "월 200건까지 무료로 차량 원부 조회가 가능하며, 이를 통해 정확한 진단과 효율적인 정비 계획 수립이 가능합니다.",
        },
        {
            title: "차량 규격 정보 제공",
            data: "국산차 및 수입차 제원과 규격정보를 제공합니다.<br /><br />제작사 : 현대, 기아, 르노, 쌍용, 쉐보레, BENZ, AUDI, BMW, CHRYSLER, DODGE, FORD, HONDA, JAGUAR, JEEP, LAND ROVER, LEXUS, LINCOLN, MASERATI, MINI, NISSAN, PEUGEOT, TOYOTA, VOLVO, VOLKSWAGEN<br />제공범위 : 2000년 이후 차종",
        },
        {
            title: "수입차 부품 가격 정보",
            data: "수입차 OE품번을 검색하여 다양한 브랜드의 상품 가격정보가 확인 가능합니다.<br />- 차량번호 입력으로 해당차량 부품 확인<br />- 주요 소모품 목록을 쉽게 확인하여 견적 작성<br />- 재고 유·무 확인 및 선택 부품 온라인 구매 가능",
        },
        {
            title: "세트부품 조회 기능 제공",
            data: "카샵에서 주로 사용되는 교환작업(소모품), 경정비 작업 위주로 간편하고 신속한 견적작업과 작업금액을 확인하고, 입고차량의 정확한 차량 및 부품 정보확인이 가능합니다.<br />검색정보를 작업차량에 즉시 반영하여 수리 내역을 작성할 수 있습니다.<br />※ 22년 10월 기준(250대 차종 430개 모델 적용, 130여개 작업항목)",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>
                                원하는 기능이 없어 아쉬우시죠? 프리미엄으로 오시면 됩니다.
                            </h2>
                            <p className="pt-4 product-sub-descr1">
                                전문정비용 프로그램의 완성판{" "}
                                <span className="pt-3 peo-color">
                                    3급 전문 정비업용 프리미엄 네오
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/pneo_bg01.png"
                                alt="프리미엄네오"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/peo1.png" alt="" />
                                                    </li>
                                                    <li>서비스 강화</li>
                                                    <li className="peo">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        원부조회와 QR코드를 이용한 비대면 접수 등, 차량에 대한 정밀한 인식을 가능하게 하는 다양한 기능을 갖춰, 정비 과정을 더욱 심층적으로 관리합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/peo2.png" alt="" />
                                                </li>
                                                <li>견적관리의 발전</li>
                                                <li className="peo">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    현대, 기아를 포함한 다양한 제조사의 120여 가지 작업 항목과 연동되는 세트부품 기능을 통해, 견적 작성의 신속성을 제고합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/peo3.png" alt="" />
                                                </li>
                                                <li>커뮤니케이션의 향상</li>
                                                <li className="peo">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    알림톡을 통해 고객과의 접점을 강화하며, 마케팅 전략을 더욱 효과적으로 전개할 수 있게 합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="peo-color">프리미엄네오</span>
                            <span>의 차별화된 기술력</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-peo">
                                <p>
                                    <span>Everywhere</span>
                                    <br />
                                    <br />
                                    <span>사무실, 현장, 출장지, 해외 어디서든 접속 가능</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-peo">
                                <p>
                                    <span>Safe data</span>
                                    <br />
                                    <br />
                                    <span>
                                        최적의 데이터 보호
                                        <br />
                                        시스템 구축
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-peo">
                                <p>
                                    <span>Any device</span>
                                    <br />
                                    <br />
                                    <span>PC, 모바일, 테블릿 등 기기 호환성 지원</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-peo">
                                <p>
                                    <span>Strong security</span>
                                    <br />
                                    <br />
                                    <span>
                                        랜섬웨어 등의 다양한 바이러스 공격을 철저하게 방어
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-peo">
                                <p>
                                    <span>Data service</span>
                                    <br />
                                    <br />
                                    <span>
                                        차량식별정보와
                                        <br />
                                        규격정보 제공
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-peo">
                                <p>
                                    <span>Special Benefits</span>
                                    <br />
                                    <br />
                                    <span>
                                        원부조회 기능 제공
                                        <br />
                                        홍보 플랫폼 정비맛집 등록
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="row">
                            <div className="col-12">&nbsp;</div>
                        </div>
                        <div className={`col-12`}>
                            <img
                                src="../assets/prod/img_pneo01.png"
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="row">
                            <div className="col-12">&nbsp;</div>
                        </div>

                        <div className={`col-12`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 product-why-wrapper">
                                        <div className="text-center product-main-function-ti">
                                            <span>Why?</span>&nbsp;
                                            <span className="peo-color">Premium NEO</span>
                                        </div>

                                        <div className="col-12">
                                            <div className="container">
                                                <div className="row pt-5">
                                                    <div className="col-12 col-sm-12 col-lg-3 col-md-3 product-why-peo">
                                                        <img
                                                            src="../assets/prod/ic_pneo_cloud.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p className="accent-title">
                                                            가장 쉬운 국토부 정비이력
                                                            <br />
                                                            전송이 가능합니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-9 col-md-9 product-why-peo text-left side-div-line">
                                                        <p>
                                                            자동차 관리법에 의거 특정 정비 항목을 정비할 경우
                                                            72시간 이내 국토부 전산에 전송해야 합니다.
                                                            <br />
                                                            (이를 어길시 건당 과태료 부과)
                                                            <br />
                                                            <br />
                                                            수리 후 전송 내역을 전송하지 않을 경우 알림 기능을
                                                            통해 비전송 과태료를 예방해 주며 해당 정비 항목이
                                                            전송내역인지 아닌지를 자동으로 분류하여 클릭
                                                            한번으로 쉽게 전송할 수 있습니다.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row pt-5">
                                                    <div className="col-12 col-sm-12 col-lg-3 col-md-3 product-why-peo">
                                                        <img
                                                            src="../assets/prod/ic_pneo_message.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p className="accent-title">
                                                            예방 정비 문자 등 체계적인
                                                            <br />
                                                            고객관리를 할 수 있습니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-9 col-md-9 product-why-peo text-left side-div-line">
                                                        <p>
                                                            최초 방문한 고객의 재방문을 이끌어 내기 위한
                                                            체계적인 차량관리를 위해 주요 소모품에 대한
                                                            예방점검 알림 기능을 적극 활용해야 합니다.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row pt-5">
                                                    <div className="col-12 col-sm-12 col-lg-3 col-md-3 product-why-peo">
                                                        <img
                                                            src="../assets/prod/ic_pneo_carinfo.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p className="accent-title">
                                                            국내 유일 수입차 규격 및
                                                            <br />
                                                            식별정보를 제공합니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-9 col-md-9 product-why-peo text-left side-div-line">
                                                        <p>
                                                            아직도 일반 로드숍에서는 수입차에 대한 제원 정보
                                                            및 식별 정보를 구하기가 쉽지 않습니다.
                                                            <br />
                                                            그뿐 아니라 가장 빈번하게 교환하는 엔진오일양 정보
                                                            또한 쉽게 구할 수 없는 것이 현실입니다.
                                                            <br />
                                                            <br />
                                                            프리미엄네오를 선택하신 다면 이런 고민없이 오직
                                                            고객만족에 집중할 수 있습니다.
                                                            <br />
                                                            프리미엄네오에서 제공하는 데이터는 지속적으로
                                                            업데이트되기에 믿고 사용하시면 됩니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper peo">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    더 높은 수준의 정비업 경영이 가능합니다!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    정확하고 신속한 견적 작성부터 고객과의 원활한 소통까지
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    프리미엄네오가 전문적인 지원
                                </span>
                                을 제공합니다.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="peo-color">프리미엄네오</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti peo-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_pneo_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti peo-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_pneo_pc02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti peo-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti peo-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti peo-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_pneo_pc03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti peo-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_pneo_pc04.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti peo-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti peo-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation06 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti peo-color">
                                    {prodInfos[4].title}
                                </span>
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox06Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_pneo_pc05.png"
                                    alt={prodInfos[4].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti peo-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation07 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox07Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_pneo_pc07.png"
                                        alt={prodInfos[5].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti peo-color">
                                        {prodInfos[5].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[5].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti peo-color">
                                        {prodInfos[5].title}
                                    </span>
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[5].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation08 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti peo-color">
                                    {prodInfos[6].title}
                                </span>
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[6].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox08Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_pneo_pc08.png"
                                    alt={prodInfos[6].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti peo-color">
                                    {prodInfos[6].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[6].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation09 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox09Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_pneo_pc09.png"
                                        alt={prodInfos[7].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti peo-color">
                                        {prodInfos[7].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[7].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti peo-color">
                                        {prodInfos[7].title}
                                    </span>
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[7].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod05?tabIndex=1">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-mobile-alt" />
                                    <p>네오탭</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=1">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-car" />
                                    <p>원부조회</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=2">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-comment-slash" />
                                    <p>080수신거부</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=3">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-tools" />
                                    <p>네오셋</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PEO;
