import VisibilitySensor from "react-visibility-sensor";

function AiCarno(props) {
    const prodInfos = [
        {
            title: "간편한 정비 상담",
            data: "차량번호판 촬영만으로 작업 현장에서 즉시 입고차량의 수리 이력을 확인할 수 있습니다. 이는 고객 상담의 효율성과 정확성을 극대화합니다.",
        },
        {
            title: "자동화된 정보 입력",
            data: "차량의 번호판과 계기판 주행거리를 촬영하면, 해당 정보가 솔루션과 연동되어 자동으로 입력됩니다. 이는 데이터 처리 과정에서의 정확성과 신속성을 보장합니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>차량번호판 사진만 찍으면 수리이력 척척 조회!</h2>
                            <p className="pt-4 product-sub-descr1">
                                차량 번호판을 자동 인식하여 <span className="pt-3 ai-carno-color">수리 정보와 고객 정보</span>를 신속하고 편리하게 조회할 수 있는 혁신적인 솔루션입니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/bg01_recognition.png"
                                alt="네오셋"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img
                                                            src="../assets/prod/ic_carfilming.png"
                                                            alt=""
                                                        />
                                                    </li>
                                                    <li>번호판 인식 기능</li>
                                                    <li className="ai-carno">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        번호판을 촬영하는 것만으로 차량의 수리 이력을 즉각적으로 조회할 수 있습니다. 이는 바쁜 작업 현장에서 시간과 노력을 대폭 절약해 줍니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/ic_mileage.png" alt="" />
                                                </li>
                                                <li>주행거리 인식 및 오류 방지</li>
                                                <li className="ai-carno">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    계기판의 주행거리를 촬영함으로써, 데이터 입력 과정에서 발생할 수 있는 오류를 최소화합니다. 이는 정비 점검 주기의 정확성을 보장합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/ic_question,.png" alt="" />
                                                </li>
                                                <li>현장 정비 상담</li>
                                                <li className="ai-carno">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    모든 직원이 현장에서 차량번호판을 촬영하여 수리 이력을 조회할 수 있으며, 이를 통해 고객에게 즉각적인 상담과 정보 제공이 가능합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper ai-carno">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p style={{ lineHeight: "240%" }}>
                                <span className="product-copyright-ti">
                                    차량번호 입력은 이제 그만, 번호판을 찍어보세요!
                                </span>
                                <br />
                                <span className="font-weight-bold">네오탭</span>에 탑재된 네오샷을 통해 빠르고 편리한 차량 정보 조회를 경험하세요.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="ai-carno-color">네오샷</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span
                                    className="product-main-function-descr-ti ai-carno-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-6 product-main-function-img">
                                <img
                                    src="../assets/prod/img_recognition_m01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span
                                    className="product-main-function-descr-ti ai-carno-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-12 col-lg-6 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_recognition_m02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti ai-carno-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti ai-carno-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod03">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>프리미엄네오</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AiCarno;
