import React from 'react'
import { Button, Modal } from "react-bootstrap";

function ModalInstallPriceInfo({ handleClose, modalShow }) {
    return (
        <Modal
            centered
            show={modalShow}
            onHide={handleClose}
        >
            <Modal.Header>
                <h4>방문 설치비 안내</h4>
            </Modal.Header>
            <Modal.Body className="question-modal pt-4">
                <h4>
                    방문 설치비란?
                </h4>

                <p className="pt-2">
                    방문 설치비는 당사 <span>전문 기술자</span>가 직접 고객의 <span>현장을 방문</span>하여 필요한 <span>소프트웨어 설치</span> 및 <span>시스템 구성</span>을 완료하는데 필요한 비용입니다.
                </p>

                <hr />

                <h4>
                    왜 발생하나요?
                </h4>

                <p className="pt-2">
                    이 비용은 <span>네트워크 설정</span> 조정, 소프트웨어 설치, <span>서버와의 통신</span> 검증을 포함한 전체적인 <span>시스템 구축</span>과정을 위해 발생됩니다. 또한, <span>사용자 교육</span>과 기존 <span>데이터 이전</span> 작업을 통해 새로운 솔루션으로의 부드러운 <span>전환을 보장</span>하기 위한 것입니다.
                </p>

                <hr />

                <h4>
                    솔루션별 방문 설치비
                </h4>

                <table className="table table-sm table-bordered">
                    <thead>
                        <tr className="text-center">
                            <th scope="col" colSpan={2}>
                                구분
                            </th>
                            <th scope="col">
                                비용
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan={5}>전문정비업체용</td>
                            <td>비즈하이5</td>
                            <td className="text-right">70,000원</td>
                        </tr>
                        <tr>
                            <td>프리미엄 비즈하이5</td>
                            <td className="text-right">70,000원</td>
                        </tr>
                        <tr>
                            <td>네오하이웨이</td>
                            <td className="text-right">70,000원</td>
                        </tr>
                        <tr>
                            <td>프리미엄네오</td>
                            <td className="text-right">70,000원</td>
                        </tr>
                        <tr>
                            <td>HONORS (일반정비)</td>
                            <td className="text-right">100,000원</td>
                        </tr>
                        <tr>
                            <td rowSpan={2}>종합정비</td>
                            <td>오토X</td>
                            <td className="text-right">1,000,000원</td>
                        </tr>
                        <tr>
                            <td>HONORS (일반+보험)</td>
                            <td className="text-right">1,000,000원</td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    확인
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ModalInstallPriceInfo