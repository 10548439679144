import { Carousel } from "react-bootstrap";
import bannerImage01 from "../assets/main_bg01.png";
import bannerImage02 from "../assets/main_bg02.png";
import bannerImage03 from "../assets/main_bg03.png";
import bannerImage04 from "../assets/main_bg04.png";

function Banner(props) {
  return (
    <Carousel fade={true}>
      <Carousel.Item>
        <video
          style={{ height: "650px", width: "100%", objectFit: "cover" }}
          autoPlay={true}
          controls={false}
          loop
          muted
        >
          <source src="../assets/videos/1026842726-sd.mov" type="video/mp4" />
          <img src={bannerImage03} alt="..." />
        </video>

        <Carousel.Caption>
          {/* <h1 className="text-white">가여낙성(可與樂成)</h1> */}
          {/* <h1 className="text-white">Enjoy the results of work together</h1>
                    <p className="p-2 pb-5">고객과 함께 성장하는 인트라밴</p> */}

          <h2 className="text-white">
            "BEST" 업계 IT 최고 수준의 솔루션 개발팀 보유
            <br />
            "ONLY" 빅데이터팀 (부품가격 및 표준작업시간) 운영
          </h2>
          <p className="p-2 pb-5">
            평균 개발 경력 10년 이상, 정비산업군별 솔루션 개발 및 각종 데이터
            구축, 분석
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <video
          style={{ height: "650px", width: "100%", objectFit: "cover" }}
          autoPlay={true}
          controls={false}
          loop
          muted
        >
          <source src="../assets/videos/1068196079-sd.mov" type="video/mp4" />
          <img src={bannerImage01} alt="..." />
        </video>

        <Carousel.Caption>
          {/* <h1 className="text-white">철중쟁쟁(鐵中錚錚)</h1> */}
          {/* <h1 className="text-white">Industry-leading know-how</h1>
                    <p className="p-2 pb-5">정비업계 최고의 프로그램</p> */}

          <h1 className="text-white">업계 최다 솔루션 사용률 유지</h1>
          <p className="p-2 pb-5">
            국내 자동차 정비업계 12,000여 업체 사용 “점유율 1위”
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <video
          style={{ height: "650px", width: "100%", objectFit: "cover" }}
          autoPlay={true}
          controls={false}
          loop
          muted
        >
          <source src="../assets/videos/1025767430-sd.mov" type="video/mp4" />
          <img src={bannerImage02} alt="..." />
        </video>

        <Carousel.Caption>
          {/* <h1 className="text-white">마고소양(麻姑搔痒)</h1> */}
          {/* <h1 className="text-white">The evolution of customer service</h1>
                    <p className="p-2 pb-5">고객의 필요를 미리 알고 반영하는 인트라밴</p> */}

          <h1 className="text-white">
            업계 최장 자동차 정비산업군 솔루션 유통기업
          </h1>
          <p className="p-2 pb-5">
            1990년대부터 고객과 함께 꾸준히 성장하는 장수기업
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <video
          style={{ height: "650px", width: "100%", objectFit: "cover" }}
          autoPlay={true}
          controls={false}
          loop
          muted
        >
          <source src="../assets/videos/1054069406-sd.mov" type="video/mp4" />
          <img src={bannerImage04} alt="..." />
        </video>

        <Carousel.Caption>
          {/* <h1 className="text-white">가여낙성(可與樂成)</h1> */}
          {/* <h1 className="text-white">Enjoy the results of work together</h1>
                    <p className="p-2 pb-5">고객과 함께 성장하는 인트라밴</p> */}

          <h1 className="text-white">고객을 위한 전국 서비스 네트워크 운영</h1>
          <p className="p-2 pb-5">
            전국 9개 지사 운영, 온라인 & 오프라인 서비스 지원 체계
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Banner;
