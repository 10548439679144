import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../comm/Header";
import ModalInstallPriceInfo from "./ModalInstallPriceInfo";
import ModalProdInfo from "./ModalProdInfo";

function Question() {
    const dispatch = useDispatch();
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [keysPressed, setKeysPressed] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalInstallPriceShow, setModalInstallPriceShow] = useState(false);
    const [prodIndex, setProdIndex] = useState(false);

    const handleClose = () => setModalShow(false);
    const handleInstallPriceClose = () => setModalInstallPriceShow(false);

    useEffect(
        () => {
            dispatch({ type: "loadingChange", payload: true });

            if (localStorage.getItem('comcode')) {
                setIsTableVisible(true);
            }

            const handleKeyPress = (event) => {
                if (['9', '0', '1'].includes(event.key)) {
                    setKeysPressed((prevKeys) => [...prevKeys, event.key]);
                }
            };

            window.addEventListener('keydown', handleKeyPress);

            setTimeout(() => {
                dispatch({ type: "loadingChange", payload: false });
            }, 500);

            return () => {
                window.removeEventListener('keydown', handleKeyPress);
            };
        },
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        const keyString = keysPressed.join('');
        if (keyString.includes(process.env.REACT_APP_QUESTION_PW)) {
            setIsTableVisible((prev) => setIsTableVisible(!prev));
            setKeysPressed([]); // 키 입력 상태를 초기화합니다.
        }
    }, [keysPressed]);

    return (
        <>
            <section className="section question">
                <Header page="sub" />

                <div className="container mt-7">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-white">제품문의</h2>
                            <p className="text-white pt-3">제품문의 &gt; 제품문의</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row pt-99 pb-9">
                    <div className="col-12">
                        <h2>인트라밴 솔루션에 궁금한 점이 있으세요?</h2>
                        <p className="pt-3">고객님의 문의에 정성껏 답변해 드리겠습니다.</p>
                    </div>

                    <div className="col-12 mt-5">
                        <div className="table-responsive datatable-list">
                            {!isTableVisible ?
                                <table className="table table-bordered custom-table-price">
                                    <thead>
                                        <tr className="text-center">
                                            <th scope="col" rowSpan={2} className="align-middle">
                                                구분
                                            </th>
                                            <th scope="col" colSpan={5}>
                                                전문정비업체용
                                            </th>
                                            <th scope="col" colSpan={2}>
                                                종합정비
                                                &nbsp;
                                            </th>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="col">비즈하이5<br />(cs버전)</th>
                                            <th scope="col">프리미엄<br />비즈하이5</th>
                                            <th scope="col">네오하이웨이<br />&nbsp;</th>
                                            <th scope="col">프리미엄네오<br />&nbsp;</th>
                                            <th scope="col">HONORS<br />(일반정비)</th>
                                            <th scope="col">오토X<br />&nbsp;</th>
                                            <th scope="col">HONORS<br />(일반+보험)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">기본 솔루션</th>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">
                                                차량 원부 조회
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(0);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td>별도비용</td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">
                                                차량식별 및 규격정보
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(1);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td>별도비용</td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">
                                                수입차 부품 검색&구매
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(2);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td></td>
                                            <td></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">
                                                Honors 수입차 견적
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(3);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">
                                                네오셋 (세트부품)
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(4);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td>별도비용</td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">
                                                사고차 보험 견적 및 청구
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(5);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">
                                                국토부 정비이력 전송 프로그램
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(6);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td>별도비용</td>
                                            <td>별도비용</td>
                                        </tr>
                                        <tr className="text-center text-nowrap">
                                            <th scope="row" className="text-left">
                                                클라우드 실시간 백업
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(7);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td></td>
                                            <td></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                        <tr className="text-center">
                                            <th scope="row" className="text-left text-nowrap">
                                                모바일앱 (2대)
                                                <button
                                                    className="btn btn-sm btn-secondary ml-2"
                                                    onClick={() => {
                                                        setProdIndex(8);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    자세히
                                                </button>
                                            </th>
                                            <td>별도비용</td>
                                            <td>별도비용</td>
                                            <td>별도비용</td>
                                            <td>별도비용</td>
                                            <td>별도비용</td>
                                            <td>별도비용</td>
                                            <td><i className="fa-duotone fa-check" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                : <>
                                    <table className="table table-bordered custom-table-show-price">
                                        <thead>
                                            <tr className="text-center">
                                                <th scope="col" rowSpan={2} colSpan={2} className="align-middle">
                                                    구분
                                                </th>
                                                <th scope="col" colSpan={5}>
                                                    전문정비업체용
                                                </th>
                                                <th scope="col" colSpan={2}>
                                                    종합정비
                                                    &nbsp;
                                                </th>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="col">비즈하이5<br />(cs버전)</th>
                                                <th scope="col">프리미엄<br />비즈하이5</th>
                                                <th scope="col">네오하이웨이<br />&nbsp;</th>
                                                <th scope="col">프리미엄네오<br />&nbsp;</th>
                                                <th scope="col">HONORS<br />(일반정비)</th>
                                                <th scope="col">오토X<br />&nbsp;</th>
                                                <th scope="col">HONORS<br />(일반+보험)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap align-middle" rowSpan={2}>기본 솔루션</th>
                                                <td className="text-right">35,000원</td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr className="text-center">
                                                <td className="text-right">100,000원</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap">
                                                    차량 원부 조회
                                                    <button
                                                        className="btn btn-sm btn-secondary ml-2"
                                                        onClick={() => {
                                                            setProdIndex(0);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">40,000원</td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td>별도비용</td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap">
                                                    차량식별 및 규격정보
                                                    <button
                                                        className="btn btn-sm btn-secondary ml-2"
                                                        onClick={() => {
                                                            setProdIndex(1);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">10,000원</td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td>별도비용</td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap">
                                                    수입차 부품 검색&구매<br />
                                                    <button
                                                        className="btn btn-sm btn-secondary mt-2"
                                                        onClick={() => {
                                                            setProdIndex(2);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">10,000원</td>
                                                <td></td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap">
                                                    Honors 수입차 견적
                                                    <button
                                                        className="btn btn-sm btn-secondary ml-2"
                                                        onClick={() => {
                                                            setProdIndex(3);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">30,000원</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap">
                                                    네오셋 (세트부품)
                                                    <button
                                                        className="btn btn-sm btn-secondary ml-2"
                                                        onClick={() => {
                                                            setProdIndex(4);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">10,000원</td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td>별도비용</td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap">
                                                    사고차 보험 견적 및 청구<br />
                                                    <button
                                                        className="btn btn-sm btn-secondary mt-2"
                                                        onClick={() => {
                                                            setProdIndex(5);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">80,000원</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap">
                                                    국토부 정비이력 전송 프로그램<br />
                                                    <button
                                                        className="btn btn-sm btn-secondary mt-2"
                                                        onClick={() => {
                                                            setProdIndex(6);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">24,000원</td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                            </tr>
                                            <tr className="text-center text-nowrap">
                                                <th scope="row" className="text-left">
                                                    클라우드 실시간 백업
                                                    <button
                                                        className="btn btn-sm btn-secondary ml-2"
                                                        onClick={() => {
                                                            setProdIndex(7);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">5,000원</td>
                                                <td></td>
                                                <td></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="row" className="text-left text-nowrap align-middle" rowSpan={2}>
                                                    모바일앱 (2대)
                                                    <button
                                                        className="btn btn-sm btn-secondary ml-2"
                                                        onClick={() => {
                                                            setProdIndex(8);
                                                            setModalShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td className="text-right">10,000원</td>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr className="text-center">
                                                <td className="text-right">15,000원</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>별도비용</td>
                                                <td><i className="fa-duotone fa-check" /></td>
                                            </tr>
                                            <tr className="text-center text-nowrap">
                                                <th scope="row" className="text-left" colSpan={2}>
                                                    방문 설치비
                                                    <button
                                                        className="btn btn-sm btn-secondary ml-2"
                                                        onClick={() => {
                                                            setModalInstallPriceShow(true);
                                                        }}
                                                    >
                                                        자세히
                                                    </button>
                                                </th>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                                <td>별도비용</td>
                                                <td style={{ backgroundColor: '#e1ecff' }}>별도비용</td>
                                                <td>별도비용</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-left text-nowrap bg-secondary text-white" colSpan={2}>
                                                    정상요금
                                                </th>
                                                <td className="text-right bg-secondary text-white text-strikethrough">59,000원</td>
                                                <td className="text-right bg-secondary text-white text-strikethrough">119,000원</td>
                                                <td className="text-right bg-secondary text-white text-strikethrough">74,000원</td>
                                                <td className="text-right bg-secondary text-white text-strikethrough">134,000원</td>
                                                <td className="text-right bg-secondary text-white text-strikethrough">164,000원</td>
                                                <td className="text-right bg-secondary text-white text-strikethrough">225,000원</td>
                                                <td className="text-right bg-secondary text-white text-strikethrough">300,000원</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-left text-nowrap bg-primary text-white" colSpan={2}>
                                                    할인요금
                                                </th>
                                                <td className="text-right bg-primary text-white">35,000원</td>
                                                <td className="text-right bg-primary text-white">40,000원</td>
                                                <td className="text-right bg-primary text-white">35,000원</td>
                                                <td className="text-right bg-primary text-white">40,000원</td>
                                                <td className="text-right bg-primary text-white">90,000원</td>
                                                <td className="text-right bg-primary text-white">100,000원</td>
                                                <td className="text-right bg-primary text-white">174,000원</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="p-0 m-0">* 모든 금액은 부가세가 별도로 적용됩니다.</div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 pt-9 pb-9" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="cscenter-operating-hours">
                                <p className="pb-2">비즈니스의 성장은 올바른 도구의 선택에서 시작됩니다.</p>
                                <p>귀사의 업무에 최적화된 솔루션을 제공하기 위해</p>
                                <p className="pb-2">대표전화, 이메일 또는 온라인 1:1 실시간 문의 채널에서 기다리고 있습니다.</p>
                                <span>비즈니스의 퍼즐, 인트라밴이 마지막 조각을 끼워드립니다.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pb-9">
                <div className="row">
                    <div className="col-12 mt-9">
                        <div className="question-info-container-2">
                            <div className="row">
                                <div className="col-lg-3 col-md-12 col-sm-12 text-center pt-3">
                                    <img src="../assets/question/sub04_logo.png" alt="" />
                                </div>
                                <div className="col-lg-9 col-md-12 col-sm-12 text-left">
                                    <i className="fa-duotone fa-phone-volume pr-3" />1522-3840
                                    <br />
                                    <i className="fa-duotone fa-envelope pr-3" />intravan@intravan.com
                                    <p className="pt-1" style={{ fontSize: "14px", color: "#212529" }}>
                                        우측 하단 1:1 문의하기를 이용하여 실시간 상담이 가능합니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalProdInfo
                handleClose={handleClose}
                modalShow={modalShow}
                prodIndex={prodIndex} />

            <ModalInstallPriceInfo
                handleClose={handleInstallPriceClose}
                modalShow={modalInstallPriceShow} />
        </>
    );
}

export default Question;
