import { useEffect, useState } from 'react';
import Header from '../comm/Header'
import { cloneObj } from '../../js/util'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor'

function Award() {
    const [boxAnimation1, setBoxAnimation1] = useState(null)
    const [boxAnimation2, setBoxAnimation2] = useState(null)
    const [boxAnimation3, setBoxAnimation3] = useState(null)
    const [boxAnimation4, setBoxAnimation4] = useState(null)
    const [boxAnimation5, setBoxAnimation5] = useState(null)
    const [boxAnimation6, setBoxAnimation6] = useState(null)

    const onVisibilityChange1 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation1('1')
        }
    }

    const onVisibilityChange2 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation2('1')
        }
    }

    const onVisibilityChange3 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation3('1')
        }
    }

    const onVisibilityChange4 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation4('1')
        }
    }

    const onVisibilityChange5 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation5('1')
        }
    }

    const onVisibilityChange6 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation6('1')
        }
    }

    const overlayStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
        width: 270,
        height: 342,
        lineHeight: 270,
        transition: 'all .25s ease',
    };

    const Title = (props) => (
        <div style={ overlayStyle }>
            <h1 className="text-white" style={{ 'lineHeight': '342px', 'cursor': 'pointer' }} onClick={() => props.handleShow(props.idx) }>
                <img src="../assets/award/btn_more01.png" alt="" />
            </h1>
        </div>
    );

    const [hover, setHover] = useState([false, false, false, false])
    const [show, setShow] = useState(false);
    const [awardImage, setAwardImage] = useState('../assets/award/img_reward01_o.png');

    const photoOverItem = [
        '../assets/award/img_reward01_o.png',
        '../assets/award/img_reward02_o.png',
        '../assets/award/img_reward03_o.png',
        '../assets/award/img_reward04_o.png'
    ]

    const handleClose = (idx) => {
        setShow(false)
    }
    
    const handleShow = (idx) => {
        setAwardImage(photoOverItem[idx])
        setShow(true)
    }

    const onEnter = (idx) => {
        let copiedObj = cloneObj(hover)
        for (let i in copiedObj) {
            copiedObj[i] = false
        }
        copiedObj[idx] = true
        setHover(copiedObj)
    }

    const onExit = (idx) => {
        let copiedObj = cloneObj(hover)
        copiedObj[idx] = false
        setHover(copiedObj)
    }

    const photoStyle = [
        {
            background: "url('../assets/award/img_reward01.png')",
            backgroundSize: "center",
            backgroundRepeat: 'no-repeat',
            width: 270,
            minHeight: 342,
            margin: '0 auto'
        },
        {
            background: "url('../assets/award/img_reward02.png')",
            backgroundSize: "center",
            backgroundRepeat: 'no-repeat',
            width: 270,
            minHeight: 342,
            margin: '0 auto'
        },
        {
            background: "url('../assets/award/img_reward03.png')",
            backgroundSize: "center",
            backgroundRepeat: 'no-repeat',
            width: 270,
            minHeight: 342,
            margin: '0 auto'
        },
        {
            background: "url('../assets/award/img_reward04.png')",
            backgroundSize: "center",
            backgroundRepeat: 'no-repeat',
            width: 270,
            minHeight: 342,
            margin: '0 auto'
        }
    ]

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: 'loadingChange', payload: true })

        setTimeout(() => {
            dispatch({ type: 'loadingChange', payload: false })
        }, 500)
    },
    // eslint-disable-next-line
    []);

    return (
        <>
            <section className="section intro">
                <Header page="sub" />

                <div className="container mt-7">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-white">인트라밴 발자취</h2>
                            <p className="text-white pt-3">회사소개 &gt; 인트라밴 발자취</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row pt-99">
                    <div className="col-12">
                        <h2>인트라밴의 수상내역!</h2>

                        <p className="pt-3">
                            정비업계 소통과 상생을 목표로 현재까지 함께하고 있는 <span className="text-bold">(주)인트라밴</span>은
                            현재에 머무르지 않고 오늘도 계속 발전해 가고 있습니다.
                        </p>
                    </div>
                </div>

                <div className="row pt-5 pb-99">
                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div
                            style={ photoStyle[0] }
                            onMouseEnter={ (e) => onEnter(0) }
                            onMouseLeave={ (e) => onExit(0) }
                        >
                            {hover[0] && <Title idx={0} handleShow={handleShow} /> }
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div
                            style={photoStyle[1]}
                            onMouseEnter={(e) => onEnter(1)}
                            onMouseLeave={(e) => onExit(1) }
                        >
                            {hover[1] && <Title idx={1} handleShow={handleShow} />}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div
                            style={photoStyle[2]}
                            onMouseEnter={ (e) => onEnter(2) }
                            onMouseLeave={ (e) => onExit(2) }
                        >
                            {hover[2] && <Title idx={2} handleShow={handleShow} />}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3">
                        <div
                            style={photoStyle[3]}
                            onMouseEnter={(e) => onEnter(3)}
                            onMouseLeave={(e) => onExit(3)}
                        >
                            {hover[3] && <Title idx={3} handleShow={ handleShow } />}
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 pt-99 pb-99" style={{ 'backgroundColor': '#f9f9f9' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>언론에 소개된 인트라밴</h2>
                        </div>
                    </div>

                    <VisibilitySensor
                        scrollCheck={true}
                        onChange={onVisibilityChange1}
                        delayedCall
                    >
                        <div 
                            className={`row pt-9 opacity-0 ${(boxAnimation1 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                        >
                            <div className="col-lg-6 col-md-6 col-sm-12 text-center award-boardcast-bg-l">
                                <img src="../assets/award/img_book01.png" alt="" className="pt-5 pb-5" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3 className="pt-5">사례로 배우는 e비즈니스III</h3>

                                <p className="pt-3">산업자원부, 전국경제인연합회 발행</p>
                                <p>2005. 01. 25</p>

                                <p className="pt-3">제 7장 ASP를 활용한 중소자영기업의 정보화 네트워크 구축 비즈메카 하이웨이 서비스를 이용한 자동차 정비업체 정보화 사례</p>
                            </div>
                        </div>
                    </VisibilitySensor>

                    <VisibilitySensor
                        scrollCheck={true}
                        onChange={onVisibilityChange2}
                        delayedCall
                    >
                        <div
                            className={`row pt-9 opacity-0 ${(boxAnimation2 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                        >
                            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                                <img src="../assets/award/img_book02.png" alt="" className="pt-5 pb-5" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 award-boardcast-bg-r">
                                <h3 className="pt-5">전유성과 함께하는 소기업 정보화</h3>

                                <p className="pt-3">정보통신부, 중소기업청, 한국전산원 발행</p>
                                <p>2003. 12. 13</p>

                                <p className="pt-3">제 11장, 5년째 사용하고 있지만 여전히 좋아요 - 애니카랜드 편(62p)</p>
                                <p>제 12장, 정비 통장에 고객사랑 담아드려요! - 모터풀 편(71p)</p>
                            </div>
                        </div>
                    </VisibilitySensor>

                    <VisibilitySensor
                        scrollCheck={true}
                        onChange={onVisibilityChange3}
                        delayedCall
                    >
                        <div
                            className={`row pt-9 opacity-0 ${(boxAnimation3 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                        >
                            <div className="col-lg-6 col-md-6 col-sm-12 text-center award-boardcast-bg-l">
                                <img src="../assets/award/img_TV.png" alt="" className="pt-5 pb-5" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3 className="pt-5">KBS 경제전망대 방영</h3>

                                <p className="pt-3">2004. 10. 23 - 23시</p>

                                <p className="pt-3">소기업 네트워크 정보화 산업 (자동차 정비 업종 마케팅 관련 보도)</p>
                            </div>
                        </div>
                    </VisibilitySensor>

                    <VisibilitySensor
                        scrollCheck={true}
                        onChange={onVisibilityChange4}
                        delayedCall
                    >
                        <div
                            className={`row pt-9 opacity-0 ${(boxAnimation4 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                        >
                            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                                <img src="../assets/award/img_news.png" alt="" className="pt-5 pb-5" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 award-boardcast-bg-r">
                                <h3 className="pt-5">신문사 보도</h3>

                                <p className="pt-3">매일경제 신문</p>
                                <p>2005. 01. 25</p>

                                <p className="pt-3">중소기업과 대기업과의 마케팅 결합을 통한 자동차 정비 관련 솔루션 저변 확대 및 시장 장악력 활성화 성공사례 보도</p>
                            </div>
                        </div>
                    </VisibilitySensor>
                </div>
            </div>

            <div className="container pt-99 pb-99">
                <div className="row">
                    <div className="col-12">
                        <h2>과거 주요서비스</h2>

                        <p className="pt-3">
                            현재는 서비스가 중단되었지만 인트라밴의 발전 뒤에는 고객분들이 사랑해 주셨던 <span className="text-bold">인기 서비스</span>가 있었습니다.
                        </p>
                    </div>
                </div>

                <VisibilitySensor
                    scrollCheck={true}
                    onChange={onVisibilityChange5}
                    delayedCall
                >
                    <div
                        className={`row pt-9 opacity-0 ${(boxAnimation5 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                    >
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center award-boardcast-bg-l">
                            <img src="../assets/award/highway_call.png" alt="" className="pt-5 pb-5" />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3 className="pt-5">하이웨이콜</h3>

                            <p className="pt-3">실시간 전화 알림 서비스</p>
                            <p>2007 ~ 2021</p>

                            <p className="pt-3">전화벨이 울리면 고객 정보 및 수리이력 등이 자동으로 표시되어 사전에 고객 요구사항을 파악하여 신속하게 처리할 수 있게 도와주는 서비스</p>
                        </div>
                    </div>
                </VisibilitySensor>

                <VisibilitySensor
                    scrollCheck={true}
                    onChange={onVisibilityChange6}
                    delayedCall
                >
                    <div
                        className={`row pt-9 opacity-0 ${(boxAnimation6 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                    >
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <img src="../assets/award/bank_book.png" alt="" className="pt-5 pb-5" />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 award-boardcast-bg-r">
                            <h3 className="pt-5">차계부통장</h3>

                            <p className="pt-3">차 수리내용 인쇄 발급 서비스</p>
                            <p>1998 ~ 현재</p>

                            <p className="pt-3">은행식 통장처럼 고객 차량의 정비 내용을 인쇄·발급하는 서비스</p>
                            <p>기존 사용자들은 사용 중이나 현재 단말기 단종으로 신규 가입 불가</p>
                        </div>
                    </div>
                </VisibilitySensor>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Body className="m-0 p-0">
                    <img src={awardImage} alt="" />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Award