import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Header from "../comm/Header";

function Direction() {
  const dispatch = useDispatch();
  const [bottomTxtAnimation01, setBottomTxtAnimation01] = useState(null);

  const onVisibilityChangeText01 = (isVisible) => {
    if (isVisible) {
      setBottomTxtAnimation01("1");
    }
  };

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="section intro">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">찾아오시는 길</h2>
              <p className="text-white pt-3">회사소개 &gt; 찾아오시는 길</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container pt-99 pb-99">
        <div className="row">
          <div className="col-12">
            <h2>인트라밴 오시는 길</h2>

            <p className="pt-3">
              대중교통으로도 이용이 편한 인트라밴의 사무실을 안내해 드립니다.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 pt-3">
            <a
              href="https://map.naver.com/v5/search/%EC%9D%B8%ED%8A%B8%EB%9D%BC%EB%B0%B4/place/158875265?c=14160061.2778927,4517277.2413696,15,0,0,0,dh"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="../assets/img_submap.png"
                alt="클릭시 네이버 지도 이동"
                className="client-thumb"
              />
            </a>
          </div>

          <div className="col-12 pt-3"></div>
        </div>

        <div className="row pt-3">
          <div className="col-lg-8 col-md-8 col-sm-12 mt-5">
            <ul>
              <li>
                <h5>인트라밴 본사</h5>
              </li>
              <li className="pt-3">
                <table className="direction-addr-table">
                  <tbody>
                    <tr>
                      <td>주소</td>
                      <td>
                        우[12925]
                        <br />
                        경기도 하남시 미사대로 520 현대지식산업센터 한강미사 2차
                        C동 713호
                        <br />
                        경기도 하남시 덕풍동 831-1, 현대지식산업센터 한강미사
                        2차 C동 713호
                      </td>
                    </tr>

                    <tr>
                      <td>대표전화</td>
                      <td>1522-3840</td>
                    </tr>

                    <tr>
                      <td>팩스</td>
                      <td>031-8018-4765</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
            <h5>고객센터</h5>

            <ul className="direction-addr-list pt-3">
              <li>교육</li>
              <li>A/S</li>
              <li>상담</li>
              <li>문의</li>
            </ul>

            <br />

            <h1 className="pt-2 pb-3 direction-addr-phone">1522-3840</h1>

            <p className="direction-time">
              평&nbsp;&nbsp;&nbsp;일 : 오전 9시 ~ 오후 6시
              <br />
              토/일요일, 공휴일 휴무
            </p>
          </div>
        </div>
      </div>

      <div className="w-100 pt-99 pb-99 direction-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5>오시는 길 (지하철)</h5>

              <VisibilitySensor
                scrollCheck={true}
                onChange={onVisibilityChangeText01}
                delayedCall
              >
                <ul
                  className={`pt-2 direction-descr opacity-0 ${
                    bottomTxtAnimation01 === "1"
                      ? "animated animatedFadeInUp fadeInUp"
                      : ""
                  }`}
                >
                  <li>
                    <span className="sub-title-descr1">①</span> 5호선 하남풍산역
                    1번 출구로 나와서, 이마트(e-mart) 앞을 지나고 하남농협을
                    지나 사거리 신호를 건넙니다.
                  </li>
                  <li>
                    <span className="sub-title-descr1">②</span> 직진하여 가는
                    방향에 보이는 큰 건물은 한강 미사 1차입니다. (대략 풍산역
                    1번 출구에서 약 380미터 지점)
                  </li>
                  <li>
                    <span className="sub-title-descr1">③</span> 한강 미사 1차를
                    보고 왼쪽 방향(한강 미사 2차)으로 쭉 내려가서 약 200~250미터
                    가다가, 건물 안쪽으로 들어섭니다.
                  </li>
                  <li>
                    <span className="sub-title-descr1">④</span> 한강 미사 2차는
                    A동, B동, C동, D동으로 나누어 있는데 들어가는 방향은 B동
                    혹은 D동입니다. C동은 반대편 쪽 건물입니다.
                  </li>
                  <li>
                    <span className="sub-title-descr1">⑤</span> 인트라밴은 C동
                    713호입니다. C동을 찾아서 엘리베이터를 이용하여 7층 13호로
                    오시면 됩니다. (C동 2층에는 팔당 냉면이 있습니다.)
                  </li>
                </ul>
              </VisibilitySensor>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-99 pb-99">
        <div className="row">
          <div className="col-12 text-center">
            <h2>
              <i className="fab fa-youtube history-year" />
              &nbsp;하이웨이 TV에서 제공하는 영상보고 따라오기
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center video">
            <div className="video-container">
              <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/OjjeorD-RT4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Direction;
