import React from "react";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LabelSeries,
  DiscreteColorLegend,
  LineSeries,
} from "react-vis";
import "../../../node_modules/react-vis/dist/style.css";
import { AutoSizer } from "react-virtualized";

function ComponentToPrint(props) {
  const { printRef, chartData, yyyymm, cominfo, addService } = props;
  const labelStyle = { fontSize: "0.9rem" };
  const chartHeight = 430;
  const chartHeight2 = 500;

  function getMaxY(data) {
    if (data.length !== 0) {
      let maxValue = data.reduce(function (max, p) {
        return p.y > max ? p.y : max;
      }, data[0].y);

      return maxValue === 0 ? 10 : maxValue;
    }
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <div className="container" ref={printRef}>
        <div className="row">
          <div className="col-12 report-info">
            <div className="card bg-report">
              <div className="div1 p-4 p-md-5 ">
                <h2 className="text-white">
                  {cominfo.comname || ""} ({cominfo.comcode || ""})
                </h2>
                <h5 className="pt-3 text-white">
                  <i className="far fa-calendar-alt"></i>
                  &nbsp;&nbsp;{yyyymm || ""}
                </h5>
                <h5 className="pt-2 text-white">
                  <i className="fas fa-user"></i>
                  &nbsp;&nbsp;{cominfo.boss || ""}
                </h5>
                <h5 className="pt-2 text-white">
                  <i className="fas fa-building"></i>
                  &nbsp;&nbsp;{cominfo.idno || ""}
                </h5>
                <h5 className="pt-2 text-white">
                  <i className="fas fa-address-book"></i>
                  &nbsp;&nbsp;{cominfo.address1} {cominfo.address2}
                </h5>
                <h5 className="pt-2 text-white">
                  <i className="fas fa-phone"></i>
                  &nbsp;&nbsp;{cominfo.tel0 || ""}){cominfo.tel1 || ""}-
                  {cominfo.tel2 || ""}
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 stats-box">
            <h3>입고대비 활용도</h3>
            <AutoSizer disableHeight className="pt-5">
              {({ width }) => (
                <XYPlot
                  xType="ordinal"
                  width={width}
                  height={chartHeight}
                  yDomain={[0, parseInt(getMaxY(chartData.data01) * 1.2)]}
                  margin={{ left: 100 }}
                  stackBy="y"
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis style={labelStyle} />
                  <YAxis style={labelStyle} />
                  <VerticalBarSeries
                    data={chartData.data01}
                    color={"#1869d6"}
                  />
                  <VerticalBarSeries
                    data={chartData.data01_reverse}
                    color={"#4797FF"}
                  />
                  <LabelSeries
                    data={chartData.data01.map((obj) => {
                      return {
                        ...obj,
                        label: numberWithCommas(obj.y.toString()),
                      };
                    })}
                    labelAnchorX="middle"
                  />
                  <LabelSeries
                    data={chartData.data01_reverse.map((obj) => {
                      return {
                        ...obj,
                        label:
                          obj.y > 0 ? numberWithCommas(obj.y.toString()) : "",
                      };
                    })}
                    labelAnchorX="middle"
                    labelAnchorY="text-after-edge"
                  />
                </XYPlot>
              )}
            </AutoSizer>

            <DiscreteColorLegend
              items={["사용", "미사용"]}
              colors={["#1869d6", "#4797FF"]}
              orientation="horizontal"
              style={{ paddingLeft: "50px" }}
            />
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-12 stats-box">
            <h3>고객관리 활용도</h3>
            <AutoSizer disableHeight className="pt-5">
              {({ width }) => (
                <XYPlot
                  xType="ordinal"
                  width={width}
                  height={chartHeight}
                  yDomain={[0, parseInt(getMaxY(chartData.data02) * 1.2)]}
                  margin={{ left: 100 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis style={labelStyle} />
                  <YAxis style={labelStyle} />
                  <VerticalBarSeries
                    data={chartData.data02 || null}
                    color={"#da1c40"}
                  />
                  <LabelSeries
                    data={chartData.data02.map((obj) => {
                      return {
                        ...obj,
                        label: numberWithCommas(obj.y.toString()),
                      };
                    })}
                    labelAnchorX="middle"
                    labelAnchorY="text-after-edge"
                  />
                </XYPlot>
              )}
            </AutoSizer>
          </div>
        </div>
        <div className="row pt-5" style={{ pageBreakBefore: "always" }}>
          <div className="col-12 stats-box">
            <h3>고객 충성도</h3>
            <AutoSizer disableHeight className="pt-5">
              {({ width }) => (
                <XYPlot
                  xType="ordinal"
                  width={width}
                  height={chartHeight2}
                  yDomain={[0, parseInt(getMaxY(chartData.data03) * 1.2)]}
                  margin={{ left: 100 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis style={labelStyle} />
                  <YAxis style={labelStyle} />
                  <VerticalBarSeries
                    data={chartData.data03 || null}
                    color={"#fdbd53"}
                  />
                  <LabelSeries
                    data={chartData.data03.map((obj) => {
                      return {
                        ...obj,
                        label: numberWithCommas(obj.y.toString()),
                      };
                    })}
                    labelAnchorX="middle"
                    labelAnchorY="text-after-edge"
                  />
                </XYPlot>
              )}
            </AutoSizer>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-12 stats-box">
            <h3>데이터 수집</h3>
            <AutoSizer disableHeight className="pt-5">
              {({ width }) => (
                <XYPlot
                  xType="ordinal"
                  width={width}
                  height={chartHeight2}
                  yDomain={[0, parseInt(getMaxY(chartData.data04) * 1.2)]}
                  margin={{ left: 100 }}
                  stackBy="y"
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis style={labelStyle} />
                  <YAxis style={labelStyle} />
                  <VerticalBarSeries
                    data={chartData.data04}
                    color={"#28ad9f"}
                  />
                  <VerticalBarSeries
                    data={chartData.data04_reverse}
                    color={"#4DE8D9"}
                  />
                  <LabelSeries
                    data={chartData.data04.map((obj) => {
                      return {
                        ...obj,
                        label: numberWithCommas(obj.y.toString()),
                      };
                    })}
                    labelAnchorX="middle"
                  />
                  <LabelSeries
                    data={chartData.data04_reverse.map((obj) => {
                      return {
                        ...obj,
                        label:
                          obj.y > 0 ? numberWithCommas(obj.y.toString()) : "",
                      };
                    })}
                    labelAnchorX="middle"
                    labelAnchorY="text-after-edge"
                  />
                </XYPlot>
              )}
            </AutoSizer>

            <DiscreteColorLegend
              items={["수집", "미수집"]}
              colors={["#28ad9f", "#4DE8D9"]}
              orientation="horizontal"
              style={{ paddingLeft: "50px" }}
            />
          </div>
        </div>

        <div className="row pt-5" style={{ pageBreakBefore: "always" }}>
          <div className="col-12 stats-box text-center">
            <h3>이노몰 구매/방문수</h3>
            <ul className="pt-5 row">
              <li className="float-left col-4">
                <h5>
                  구매액 : {numberWithCommas(chartData.inno_order_total)}원
                </h5>
              </li>
              <li className="float-left col-4">
                <h5>구매건 : {numberWithCommas(chartData.inno_order_cnt)}건</h5>
              </li>
              <li className="float-left col-4">
                <h5>방문수 : {numberWithCommas(chartData.inno_visit_cnt)}회</h5>
              </li>
            </ul>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-12 stats-box text-center">
            <h3>이노몰 월별매출</h3>
            <AutoSizer disableHeight className="pt-5">
              {({ width }) => (
                <XYPlot
                  xType="ordinal"
                  width={width}
                  height={300}
                  yDomain={[0, parseInt(getMaxY(chartData.data05) * 1.2)]}
                  margin={{ left: 100 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis style={labelStyle} />
                  <YAxis style={labelStyle} />
                  <LineSeries data={chartData.data05} />
                  <LabelSeries
                    data={chartData.data05.map((obj) => {
                      return {
                        ...obj,
                        label: numberWithCommas(obj.y.toString()),
                      };
                    })}
                    labelAnchorX="middle"
                    labelAnchorY="text-after-edge"
                  />
                </XYPlot>
              )}
            </AutoSizer>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-12">
            <h3>의견</h3>
            <h5
              className="pt-3 font-grey comment"
              dangerouslySetInnerHTML={{
                __html: chartData.dataMessage || null,
              }}
              style={{ lineHeight: "200%" }}
            ></h5>
          </div>
        </div>

        <div className="row pt-5 pb-9">
          <div className="col-12">
            <h3>추천 부가서비스</h3>
          </div>
          {addService.map((item, idx) => {
            const prgcode = item.prgcode;
            const prgname = item.prgname;
            const link = item.serviceurl;
            let descr = "";

            if (prgcode === "516") {
              //네오탭
              descr =
                "네오 하이웨이와 실시간 연동이 가능한 모바일 고객 관리 솔루션 네오탭";
            } else if (prgcode === "520") {
              //auto8 모바일
              descr =
                "입고부터 출고까지 정비공장의 전반적인 업무를 도와주는 모바일 차량관리 앱";
            } else if (prgcode === "523") {
              //autoX 모바일
              descr =
                "입고부터 출고까지 정비공장의 전반적인 업무를 도와주는 모바일 차량관리 앱";
            } else if (prgcode === "518") {
              //080
              descr =
                "해피콜, 검사안내 등 광고 문자 보낼 때<br />쉽고 편한 080 무료 수신 거부 서비스";
            } else if (prgcode === "525") {
              //원부조회
              descr =
                "고객 차량의 모든 것을 관리하기 위한 필수정보를 제공 (검사만료일, 차대번호 등)";
            } else if (prgcode === "999") {
              //이노몰
              descr =
                "인트라밴 솔루션 사용자라면 누구나 무료로 이용이 가능한 독점 계약 폐쇄몰";
            }

            return (
              <div className="col-4 pt-4" key={idx}>
                <div className="card mb-3">
                  <div className="card-header bg-primary text-white">
                    {prgname}
                  </div>
                  <div
                    className="card-body text-right"
                    style={{ minHeight: "160px", maxHeight: "200px" }}
                  >
                    <p
                      className="card-text text-left"
                      dangerouslySetInnerHTML={{
                        __html: descr || null,
                      }}
                    ></p>
                    <br />

                    <a
                      href={link}
                      className="btn-blue"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {prgcode === "999" ? "바로이용하기" : "자세히보기"}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ComponentToPrint;
