import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../comm/Header";
import PEO from "./PEO"; //프리미엄네오 상품소개
import Neo from "./Neo"; //네오하이웨이 상품소개
import queryString from "query-string";
import Tabs from "./Tabs";
import Tab from "./Tab";

function Prod03({ match, location, history }) {
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const tabIndex = Number(query.tabIndex) || 0;
  const [subTitle, setSubTitle] = useState();

  //탭1 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01, setBoxAnimation01] = useState(null);
  const [boxAnimation02, setBoxAnimation02] = useState(null);
  const [boxAnimation03, setBoxAnimation03] = useState(null);
  const [boxAnimation04, setBoxAnimation04] = useState(null);
  const [boxAnimation05, setBoxAnimation05] = useState(null);
  const [boxAnimation06, setBoxAnimation06] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [boxAnimation07, setBoxAnimation07] = useState(null);
  const [boxAnimation08, setBoxAnimation08] = useState(null);
  const [boxAnimation09, setBoxAnimation09] = useState(null);
  const [boxAnimation10, setBoxAnimation10] = useState(null);
  const [boxAnimation11, setBoxAnimation11] = useState(null);

  const onVisibilityBox01Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01("1");
    }
  };

  const onVisibilityBox02Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02("1");
    }
  };

  const onVisibilityBox03Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03("1");
    }
  };

  const onVisibilityBox04Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04("1");
    }
  };

  const onVisibilityBox05Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05("1");
    }
  };

  const onVisibilityBox06Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation06("1");
    }
  };

  const onVisibilityBox07Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation07("1");
    }
  };

  const onVisibilityBox08Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation08("1");
    }
  };

  const onVisibilityBox09Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation09("1");
    }
  };

  const onVisibilityBox10Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation10("1");
    }
  };

  const onVisibilityBox11Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation11("1");
    }
  };
  //탭1 CSS 관련 STATE 및 Function 끝

  //탭2 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_1, setBoxAnimation01_1] = useState(null);
  const [boxAnimation02_1, setBoxAnimation02_1] = useState(null);
  const [boxAnimation03_1, setBoxAnimation03_1] = useState(null);
  const [boxAnimation04_1, setBoxAnimation04_1] = useState(null);

  const onVisibilityBox01_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_1("1");
    }
  };

  const onVisibilityBox02_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_1("1");
    }
  };

  const onVisibilityBox03_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_1("1");
    }
  };

  const onVisibilityBox04_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_1("1");
    }
  };
  //탭2 CSS 관련 STATE 및 Function 끝

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="section prod">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">{subTitle}</h2>
              <p className="text-white pt-3">솔루션 비즈니스 &gt; {subTitle}</p>
            </div>
          </div>
        </div>
      </section>

      <div className="tabs pt-99 pb-99">
        <Tabs index={tabIndex} setSubTitle={setSubTitle}>
          <Tab label="프리미엄네오">
            <PEO
              boxAnimation01={boxAnimation01}
              boxAnimation02={boxAnimation02}
              boxAnimation03={boxAnimation03}
              boxAnimation04={boxAnimation04}
              boxAnimation05={boxAnimation05}
              boxAnimation06={boxAnimation06}
              boxAnimation07={boxAnimation07}
              boxAnimation08={boxAnimation08}
              boxAnimation09={boxAnimation09}
              boxAnimation10={boxAnimation10}
              boxAnimation11={boxAnimation11}
              onVisibilityBox01Change={onVisibilityBox01Change}
              onVisibilityBox02Change={onVisibilityBox02Change}
              onVisibilityBox03Change={onVisibilityBox03Change}
              onVisibilityBox04Change={onVisibilityBox04Change}
              onVisibilityBox05Change={onVisibilityBox05Change}
              onVisibilityBox06Change={onVisibilityBox06Change}
              onVisibilityBox07Change={onVisibilityBox07Change}
              onVisibilityBox08Change={onVisibilityBox08Change}
              onVisibilityBox09Change={onVisibilityBox09Change}
              onVisibilityBox10Change={onVisibilityBox10Change}
              onVisibilityBox11Change={onVisibilityBox11Change}
            />
          </Tab>
          <Tab label="네오하이웨이">
            <Neo
              boxAnimation01={boxAnimation01_1}
              boxAnimation02={boxAnimation02_1}
              boxAnimation03={boxAnimation03_1}
              boxAnimation04={boxAnimation04_1}
              onVisibilityBox01Change={onVisibilityBox01_1Change}
              onVisibilityBox02Change={onVisibilityBox02_1Change}
              onVisibilityBox03Change={onVisibilityBox03_1Change}
              onVisibilityBox04Change={onVisibilityBox04_1Change}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default Prod03;
