import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Header from "../comm/Header";
import VisibilitySensor from "react-visibility-sensor";

function CsCenter() {
    const dispatch = useDispatch();
    const [midBoxAnimation, setMidBoxAnimation] = useState(null);

    const onVisibilityChangeMid = (isVisible) => {
        if (isVisible) {
            setMidBoxAnimation("1");
        }
    };

    useEffect(
        () => {
            dispatch({ type: "loadingChange", payload: true });

            setTimeout(() => {
                dispatch({ type: "loadingChange", payload: false });
            }, 500);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            <section className="section cscenter">
                <Header page="sub" />

                <div className="container mt-7">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-white">고객센터</h2>
                            <p className="text-white pt-3">고객센터 &gt; 고객센터</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row pt-99">
                    <div className="col-12">
                        <h2>인트라밴 솔루션에 궁금한 점이 있으세요?</h2>
                        <p className="pt-3">
                            인트라밴은 CS 전문 인력을 배치하여 최선의 도움을 드리기 위해
                            노력합니다.
                            <br />
                            우측 하단{" "}
                            <span style={{ color: "#000", fontWeight: "bold" }}>
                                실시간 온라인 채팅기능
                            </span>
                            을 통한 상담뿐 아니라{" "}
                            <span style={{ color: "#1fd771", fontWeight: "bold" }}>
                                네이버 카페
                            </span>
                            ,{" "}
                            <span style={{ color: "#1fd771", fontWeight: "bold" }}>
                                블로그
                            </span>
                            ,{" "}
                            <span style={{ color: "#d4bf00", fontWeight: "bold" }}>
                                카카오
                            </span>{" "}
                            및{" "}
                            <span style={{ color: "#ff0000", fontWeight: "bold" }}>
                                유튜브
                            </span>{" "}
                            채널까지 다양하게 운영중이니 많은 관심 부탁드립니다.
                        </p>
                    </div>
                </div>
                <div className="row pb-9 pt-5">
                    <div className="col-lg-3 col-md-6 col-sm-12 pt-1">
                        <a
                            className="btn-naver"
                            href="https://cafe.naver.com/blue3rlof"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="./assets/c.png" alt="네이버카페" />
                            하이웨이 커뮤니티 카페
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pt-1">
                        <a
                            className="btn-blog"
                            href="https://blog.naver.com/kbk0437"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="./assets/n.png" alt="네이버블로그" />
                            하이웨이 블로그
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pt-1">
                        <a
                            className="btn-kakao"
                            href="https://pf.kakao.com/_wLHdT"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="./assets/k.png" alt="카카오채널" />
                            인트라밴 카카오 채널
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pt-1">
                        <a
                            className="btn-youtube"
                            href="https://www.youtube.com/channel/UCDzFFV5zWcuXAP1RK-LQ7UA"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="./assets/y.png" alt="YouTube" />
                            하이웨이TV 유튜브 채널
                        </a>
                    </div>
                </div>
            </div>

            <div className="w-100 pt-9 pb-999" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="cscenter-operating-hours">
                                신속한 응대를 위해 다양한 문의 채널을 운영 중입니다.
                                <br />
                                응대 가능 시간은 <span>평일 09:00 ~ 18:00</span>이고 <br />그 외
                                토/일요일 및 공휴일은 휴무입니다.
                            </div>
                            <div className="cscenter-operating-hours pt-4">
                                <span className="h3">주말 특별 서비스</span>
                                <p className="h5">(토요일 09 ~ 12시 A/S 예약제 실시)</p>
                                <p className="pt-2">
                                    토요일에 불가피하게 A/S가 필요 할 시에는<br />
                                    위쪽의 <span className="font-weight-bold">“하이웨이 커뮤니티 카페”</span>에서 회원가입을 하고<br />
                                    A/S (컴퓨터 교체, 포맷 등으로 인한) 예약 접수를 해주시면<br />
                                    <span className="font-weight-bold">토요일 09시 ~ 12시 사이</span>에 순차적 처리해드립니다.
                                </p>
                            </div>
                            <img
                                src="../assets/img_sub03_bg.png"
                                alt=""
                                className="cscenter-operating-img client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <section className="features2">
                <div className="container">
                    <div className="row">
                        <div className="mb-5 mb-lg-0 col-lg-12 text-center features-circle">
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={onVisibilityChangeMid}
                                delayedCall
                            >
                                <img
                                    src="../assets/img_sub03_callcenter.png"
                                    className={`opacity-0 ${midBoxAnimation === "1"
                                        ? "animated animatedFadeInUp fadeInUp"
                                        : ""
                                        }`}
                                    alt=""
                                />
                            </VisibilitySensor>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row pt-4 pb-99">
                    <div className="col-12 mt-9">
                        <div className="question-info-container-2">
                            <div className="row">
                                <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                                    <img src="../assets/question/sub04_logo.png" alt="" />
                                </div>
                                <div className="col-lg-9 col-md-12 col-sm-12 text-left">
                                    대표전화 : 1522-3840
                                    <br />
                                    대표메일 : intravan@intravan.com
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-5 text-center">
                        <a href="https://939.co.kr/" target="_blank" rel="noreferrer">
                            <img src="../assets/ico_sub03_btn01.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CsCenter;
