import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProgramRecom from "./comm/ProgramRecom";

function VerticalCarousel({ setShowModal }) {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const [carouselIndex, setCarouselIndex] = useState(12)

    useEffect(() => {
        setCarouselIndex(12);
    }, []);

    return (
        <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
                <h3>맞춤 솔루션을 소개합니다.</h3>

                <div className={(carouselIndex === 11 || carouselIndex === 5) ? 'product-descr p-active' : 'product-descr'}>
                    <p className="mt-4 mb-4-5">
                        <span className="solution-title">오토X</span>는 1, 2급 자동차 정비공장을 위한 종합관리 프로그램으로, 광범위한 활용성과 최고의 운영 체계를 자랑합니다.
                    </p>

                    <ProgramRecom setShowModal={setShowModal} />
                </div>

                <div className={(carouselIndex === 12 || carouselIndex === 6 || carouselIndex === 0) ? 'product-descr p-active' : 'product-descr'}>
                    <p className="mt-4 mb-4-5">
                        3급 전문 정비를 위한 <span className="solution-title">네오 하이웨이</span>는 정비업계에서 높은 평가를 받는 종합관리 솔루션입니다.
                    </p>

                    <ProgramRecom setShowModal={setShowModal} />
                </div>

                <div className={(carouselIndex === 7 || carouselIndex === 1) ? 'product-descr p-active' : 'product-descr'}>
                    <p className="mt-4 mb-4-5">
                        정확한 견적과 보험 청구를 위해 국토부 공식 데이터를 활용한 <span className="solution-title">오토디엘</span>을 제공합니다.
                    </p>

                    <ProgramRecom setShowModal={setShowModal} />
                </div>

                <div className={(carouselIndex === 8 || carouselIndex === 2) ? 'product-descr p-active' : 'product-descr'}>
                    <p className="mt-4 mb-4-5">
                        <span className="solution-title">네오 하이웨이 체인점</span> 관리 기능은 본사와 지점 간 업무를 단순화하고 효율화하는 데 중점을 두고 개발되었습니다.
                    </p>

                    <ProgramRecom setShowModal={setShowModal} />
                </div>

                <div className={(carouselIndex === 9 || carouselIndex === 3) ? 'product-descr p-active' : 'product-descr'}>
                    <p className="mt-4 mb-4-5">
                        인트라밴의 대부분 프로그램은 <span className="solution-title">모바일 앱</span>으로도 이용 가능하여, 현장에서의 신속한 고객 대응을 가능하게 합니다.
                    </p>

                    <ProgramRecom setShowModal={setShowModal} />
                </div>

                <div className={(carouselIndex === 10 || carouselIndex === 4) ? 'product-descr p-active' : 'product-descr'}>
                    <p className="mt-4 mb-4-5">
                        <span className="solution-title">오토포스</span>는 단체와 공급사, 조합원 간의 원활한 3자 거래를 위한 온라인 전표 시스템을 제공합니다.
                    </p>

                    <ProgramRecom setShowModal={setShowModal} />
                </div>
            </div>

            <div className="col-sm-12 col-lg-8 col-md-8 mt-4 carousel-reponsive">
                <Carousel
                    responsive={responsive}
                    draggable={true}
                    autoPlay={true}
                    keyBoardControl={false}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    infinite={true}
                    className="vertical-carousel"
                    autoPlaySpeed={5000}
                    beforeChange={(e) => {
                        setCarouselIndex(e)
                    }}
                >
                    <div
                        className="carousel-item-wrapper"
                        onClick={(e) => document.location.href = '/Prod01'}
                    >
                        <div className={carouselIndex === 11 || carouselIndex === 5 ? 'active' : ''}>
                            <p>업계 점유율 1위의 정비공장용 고객관리 솔루션을 찾고 계시나요?</p>
                        </div>
                        <img src="./assets/solution-logo/autox.png" alt="" />
                        <p className="carousel-item-title">정비공장(1,2급)용</p>
                    </div>

                    <div
                        className="carousel-item-wrapper"
                        onClick={(e) => document.location.href = '/Prod03'}
                    >
                        <div className={(carouselIndex === 12 || carouselIndex === 6 || carouselIndex === 0) ? 'active' : ''}>
                            <p>업계 점유율 1위의 카센터용 고객관리 솔루션을 찾고 계시나요?</p>
                        </div>
                        <img src="./assets/solution-logo/neo_.png" alt="" />
                        <p className="carousel-item-title">전문정비(3급)용 </p>
                    </div>

                    <div
                        className="carousel-item-wrapper"
                        onClick={(e) => document.location.href = '/Prod02'}
                    >
                        <div className={(carouselIndex === 7 || carouselIndex === 1) ? 'active' : ''}>
                            <p>견적 작성에 특화된 전문 솔루션을 찾고 계시나요?</p>
                        </div>
                        <img src="./assets/solution-logo/adl.png" alt="" />
                        <p className="carousel-item-title">견적관리</p>
                    </div>

                    <div
                        className="carousel-item-wrapper"
                        onClick={(e) => document.location.href = '/Prod04'}
                    >
                        <div className={(carouselIndex === 8 || carouselIndex === 2) ? 'active' : ''}>
                            <p>온라인으로 발주하고 가맹점 재고를 알고 싶으신가요?</p>
                        </div>
                        <img src="./assets/solution-logo/chain.png" alt="" />
                        <p className="carousel-item-title">체인점용</p>
                    </div>

                    <div
                        className="carousel-item-wrapper"
                        onClick={(e) => document.location.href = '/Prod05'}
                    >
                        <div className={(carouselIndex === 9 || carouselIndex === 3) ? 'active' : ''}>
                            <p>현장에서 막힘없는 고객서비스를 하고 싶으신가요?</p>
                        </div>
                        <img src="./assets/solution-logo/mobile_app.png" alt="" />
                        <p className="carousel-item-title">모바일앱</p>
                    </div>

                    <div
                        className="carousel-item-wrapper"
                        onClick={(e) => document.location.href = '/Prod07'}
                    >
                        <div className={(carouselIndex === 10 || carouselIndex === 4) ? 'active' : ''}>
                            <p>공급사와 조합원 간의 물류 시스템을 운영하고 계십니까?</p>
                        </div>
                        <img src="./assets/solution-logo/autopos.png" alt="" />
                        <p className="carousel-item-title">오토포스</p>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}

export default VerticalCarousel