function History2011() {
    return (
        <div className="mt-99 history-wrap">
            <div className="history-contents l">
                <h1 className="history-year l">2024</h1>

                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td>04月</td>
                            <td>
                                전문정비업용 <span>수입차 소모성부품 작업시간</span> 데이터베이스 구축<br />
                                - 벤츠, BMW , 아우디 , 렉서스 등
                            </td>
                        </tr>
                        <tr>
                            <td>03月</td>
                            <td>2024년형 모바일용 차계부 <span>네오북</span> 내차이력 서비스</td>
                        </tr>
                        <tr>
                            <td>02月</td>
                            <td>
                                차량 파손 이미지 데이터를 활용한 <span>AI 자동수리견적</span>에 관련<br />
                                “(주)씨즈데이타”와의 연구 협약
                            </td>
                        </tr>
                        <tr>
                            <td>02月</td>
                            <td>정비업소, 차량소유주를 위한 플랫폼 <span>정비맛집</span> 리뉴얼</td>
                        </tr>
                        <tr>
                            <td>02月</td>
                            <td>아너스 하이웨이 전용 모바일 앱 <span>아너스탭</span> 개발 발표</td>
                        </tr>
                        <tr>
                            <td>01月</td>
                            <td>
                                네오 하이웨이 전용 모바일 앱 <span>네오탭 2024</span> 발표
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents r">
                <h1 className="history-year r">2023</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>12月</td>
                            <td>
                                한국지엠 전국정비사업자연합회 아너스 하이웨이 서비스 사업 협정
                            </td>
                        </tr>
                        <tr>
                            <td>10月</td>
                            <td>수입차 전문정비 솔루션 <span>아너스 하이웨이</span> 출시</td>
                        </tr>
                        <tr>
                            <td>08月</td>
                            <td>SK네트웍스 자동차 정비이력전송시스템 연동 개발 계약</td>
                        </tr>
                        <tr>
                            <td>05月</td>
                            <td>비대면 서비스 바우처 사업선정</td>
                        </tr>
                        <tr>
                            <td>05月</td>
                            <td>네오북 내차이력조회 서비스 출시</td>
                        </tr>
                        <tr>
                            <td>05月</td>
                            <td>마이데이터 종합기반 조성사업 협약_참여기관 선정</td>
                        </tr>
                        <tr>
                            <td>04月</td>
                            <td>2023년 스마트상점 기술보급 사업(기술공급 기업선정)</td>
                        </tr>
                        <tr>
                            <td>04月</td>
                            <td>“리딩포인트(주)” 유지보수계약</td>
                        </tr>
                        <tr>
                            <td>03月</td>
                            <td>
                                대국민 편의 향상을 위한 정비이력 정보의 효과적 제공에 대한
                                다자계약
                            </td>
                        </tr>
                        <tr>
                            <td>02月</td>
                            <td>“TIS Re(주)” 업무협약</td>
                        </tr>
                        <tr>
                            <td>01月</td>
                            <td>
                                “오토월드” 자동차 성능상태점검정보조회 API 서비스 이용계약
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents l">
                <h1 className="history-year l">2022</h1>

                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td>12月</td>
                            <td>프리미엄 디지털 마케팅 플랫폼 "정비맛집" 오픈</td>
                        </tr>
                        <tr>
                            <td>10月</td>
                            <td>수입차 차량식별정보 및 규격정보 검색 서비스 개시</td>
                        </tr>
                        <tr>
                            <td>10月</td>
                            <td>수입차 부품견적 및 온라인 구매 연동 서비스</td>
                        </tr>
                        <tr>
                            <td>10月</td>
                            <td>SK렌터카, 오토X 프로그램 기능 추가 개발 계약</td>
                        </tr>
                        <tr>
                            <td>09月</td>
                            <td>오토X, 개인정보동의 연동형 모바일 신 사인패드 개발</td>
                        </tr>
                        <tr>
                            <td>08月</td>
                            <td>
                                자동차 차종별 제원정보 연동형 정비 및 부품 견적 자동산출 시스템
                                특허출원
                            </td>
                        </tr>
                        <tr>
                            <td>05月</td>
                            <td>GS엠비즈, 자동차 정비이력 전송대행 관련 업무제휴</td>
                        </tr>
                        <tr>
                            <td>04月</td>
                            <td>차량식별 및 차량규격 정보 검색 솔루션 서비스 개시</td>
                        </tr>
                        <tr>
                            <td>01月</td>
                            <td>인트라밴 홈페이지 리뉴얼 오픈</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents r">
                <h1 className="history-year r">2021</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>8月</td>
                            <td>강원도검사정비사업조합 “오토포스” 및 홈페이지 수주</td>
                        </tr>
                        <tr>
                            <td>4月</td>
                            <td>사무실 이전 (하남시 미사)</td>
                        </tr>
                        <tr>
                            <td>2月</td>
                            <td>전문정비업용 프리미엄네오 솔루션 출시</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents l">
                <h1 className="history-year l">2020</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>12月</td>
                            <td>대전자동차검사정비사업조합 견적관리시스템 “AES" 공급계약</td>
                        </tr>
                        <tr>
                            <td>08月</td>
                            <td>
                                SK네트웍스 수입차 부품 주문 모바일 시스템 '카펫' 서비스 연동
                                시작
                            </td>
                        </tr>
                        <tr>
                            <td>07月</td>
                            <td>NEW자동차종합정비공장용 종합관리프로그램 “오토X(10)" 출시</td>
                        </tr>
                        <tr>
                            <td>05月</td>
                            <td>기존 데이터팀을 국내 최강 “빅데이터 개발팀”으로 확장 개편</td>
                        </tr>
                        <tr>
                            <td>03月</td>
                            <td>
                                서울검사정비사업조합 조합원 전용 'GES 소통 플랫폼' 서비스 오픈
                                <br />
                                서울지방국세청장 모범납세자 표창
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents r">
                <h1 className="history-year r">2019</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>11月</td>
                            <td>
                                SK네트웍스 수입차 부품 주문 모바일 시스템 구축을 위한 MOU 체결
                            </td>
                        </tr>

                        <tr>
                            <td>09月</td>
                            <td>정비 전산협의회 전용 자동차 부품 용품몰 "이노몰" 오픈</td>
                        </tr>

                        <tr>
                            <td>06月</td>
                            <td>정비소를 통한 다이렉트 보험 추천 서비스 "굿카비즈" 오픈</td>
                        </tr>

                        <tr>
                            <td>03月</td>
                            <td>자동차 원부 조회 서비스 오픈</td>
                        </tr>

                        <tr>
                            <td>02月</td>
                            <td>전문정비업용 수리비견적시스템 “오토디엘” 출시</td>
                        </tr>

                        <tr>
                            <td>01月</td>
                            <td>
                                넥센타이어 레귤러체인점 전용 앱 "넥센타이어 개인 정보 동의" 출시
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents l">
                <h1 className="history-year l">2018</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>10月</td>
                            <td>자동차 정비 프로그램사 협의회 가입</td>
                        </tr>

                        <tr>
                            <td>07月</td>
                            <td>
                                전국자동차검사정비사업조합연합회 사고차견적관리시스템 “KAIMA
                                견적관리” 출시
                            </td>
                        </tr>

                        <tr>
                            <td>05月</td>
                            <td>안전과 환경을 생각한 견적 관리 앱 "GES" 출시</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents r">
                <h1 className="history-year r">2017</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>09月</td>
                            <td>차량관리 앱 "카다이어리2" 리뉴얼 오픈</td>
                        </tr>

                        <tr>
                            <td>02月</td>
                            <td>분리결제시스템 및 세무주치의 서비스 출시</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents l">
                <h1 className="history-year l">2016</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>05月</td>
                            <td>차량관리 앱 "카다이어리" 출시</td>
                        </tr>

                        <tr>
                            <td>01月</td>
                            <td>
                                PC에서 조회하던 정보를 모바일과 태블릿에서도 가능한 "하이웨이콜"
                                출시
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents r">
                <h1 className="history-year r">2015</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>10月</td>
                            <td>모바일 차량관리 시스템 "비즈탭" 앱 출시</td>
                        </tr>

                        <tr>
                            <td>06月</td>
                            <td>모바일 차량관리 시스템 앱 "네오탭(WEB용)" 출시</td>
                        </tr>

                        <tr>
                            <td>04月</td>
                            <td>
                                업무용 PC와 실시간 연동되는 앱 "오토8" 출시
                                <br />
                                정부기관 차량관리 고도화 시스템 구축 사업 수주
                            </td>
                        </tr>

                        <tr>
                            <td>02月</td>
                            <td>
                                엑셀레이터 조작 장치 "엑셀런" 특허 등록 (특허 제10-141425호)
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents l">
                <h1 className="history-year l">2014</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>11月</td>
                            <td>
                                (주)케이티, "하이웨이 서비스 삼성화재 보상업무 연동 서비스" 구축
                                계약
                            </td>
                        </tr>

                        <tr>
                            <td>07月</td>
                            <td>(주)케이티, 하이웨이 모바일 서비스 출시</td>
                        </tr>

                        <tr>
                            <td>04月</td>
                            <td>새인천 조합원 앱 "새인천 조합 개인 정보 동의" 서비스 오픈</td>
                        </tr>

                        <tr>
                            <td>03月</td>
                            <td>
                                "대전시자동차전문정비업협동조합 물류시스템" 수주
                                <br />
                                카컴 조합원 앱 "카컴 개인정보동의" 서비스 오픈
                            </td>
                        </tr>

                        <tr>
                            <td>02月</td>
                            <td>네오하이웨이와 실시간 연동 앱 "네오탭" 출시</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents r">
                <h1 className="history-year r">2013</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>12月</td>
                            <td>
                                한국자동차전문정비사업조합연합회, "네오하이웨이" 공식 지정
                                프로그램 공급 및 사용계약
                            </td>
                        </tr>

                        <tr>
                            <td>07月</td>
                            <td>
                                사인패드 없이 개인정보 활용 동의 고객의 사인을 저장하는 "네오
                                개인 정보 동의" 출시
                            </td>
                        </tr>

                        <tr>
                            <td>05月</td>
                            <td>다자간 거래 관리 앱 "오토포스" 출시</td>
                        </tr>

                        <tr>
                            <td>01月</td>
                            <td>
                                차계부 온라인 앱 "스마트 하이웨이(차계부-네오, 카포스, 카컴)"
                                출시
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents l">
                <h1 className="history-year l">2012</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>11月</td>
                            <td>
                                전국자동차전문정비사업조합연합회, "네오하이웨이"
                                공식지정프로그램 공급 및 사용계약
                            </td>
                        </tr>

                        <tr>
                            <td>02月</td>
                            <td>한국로버트보쉬기술(주) 홈페이지 및 BCS 프로그램 공급 계약</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="history-contents r">
                <h1 className="history-year r">2011</h1>

                <table>
                    <tbody>
                        <tr>
                            <td>06月</td>
                            <td>
                                보험개발원, WEB/MOBILE 자동차 수리비 견적 시스템 구축 사업 입찰
                                수주
                            </td>
                        </tr>

                        <tr>
                            <td>04月</td>
                            <td>한솔인티큐브(주) 동부화재 프로미월드 정비시스템 구축 수주</td>
                        </tr>

                        <tr>
                            <td>02月</td>
                            <td>
                                애니카자동차손해사정(주) 부품 및 공임 데이터 공급/연간 수주
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default History2011;
