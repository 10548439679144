import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../comm/Header";
import NeoChain from "./NeoChain"; //네오 체인점용 상품소개

function Prod04() {
  const dispatch = useDispatch();

  //탭1 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01, setBoxAnimation01] = useState(null);
  const [boxAnimation02, setBoxAnimation02] = useState(null);
  const [boxAnimation03, setBoxAnimation03] = useState(null);
  const [boxAnimation04, setBoxAnimation04] = useState(null);

  const onVisibilityBox01Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01("1");
    }
  };

  const onVisibilityBox02Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02("1");
    }
  };

  const onVisibilityBox03Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03("1");
    }
  };

  const onVisibilityBox04Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04("1");
    }
  };
  //탭1 CSS 관련 STATE 및 Function 끝

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="section prod">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">솔루션 비즈니스</h2>
              <p className="text-white pt-3">
                솔루션 비즈니스 &gt; 네오하이웨이 체인점
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="tabs pt-99 pb-99">
        <Tabs>
          <Tab label="네오하이웨이 체인점">
            <NeoChain
              boxAnimation01={boxAnimation01}
              boxAnimation02={boxAnimation02}
              boxAnimation03={boxAnimation03}
              boxAnimation04={boxAnimation04}
              onVisibilityBox01Change={onVisibilityBox01Change}
              onVisibilityBox02Change={onVisibilityBox02Change}
              onVisibilityBox03Change={onVisibilityBox03Change}
              onVisibilityBox04Change={onVisibilityBox04Change}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

class Tabs extends React.Component {
  state = {
    activeTab: this.props.children.props.label,
  };
  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };
  render() {
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, (child) => {
          buttons.push(child.props.label);
          if (child.props.label === this.state.activeTab)
            content = child.props.children;
        })}

        <TabButtons
          activeTab={this.state.activeTab}
          buttons={buttons}
          changeTab={this.changeTab}
        />
        <div className="tab-content">{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className="tab-buttons">
      {buttons.map((button, idx) => {
        return (
          <button
            key={idx}
            className={
              button === activeTab ? "tab-button active" : "tab-button"
            }
            onClick={() => changeTab(button)}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

const Tab = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Prod04;
