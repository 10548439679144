export const menu = [
    {
        seq: 0,
        title: "플랫폼 비즈니스",
        sub: [
            {
                subeq: 0,
                subTitle: "정비맛집",
                badge: "",
                url: "/Prod07?tabIndex=0",
                new: "",
            },
            /* {
                subeq: 1,
                subTitle: "소통앱",
                badge: "",
                url: "/Prod07?tabIndex=1",
                new: "",
            }, */
            {
                subeq: 1,
                subTitle: "오토포스",
                badge: "협단체물류",
                url: "/Prod07?tabIndex=1",
                new: "",
            },
        ],
    },
    {
        seq: 1,
        title: "솔루션 비즈니스",
        sub: [
            {
                subeq: 3,
                subTitle: "아너스 하이웨이",
                badge: "1급 / 전문정비업",
                url: "/Prod01?tabIndex=3",
                new: "1",
            },
            {
                subeq: 0,
                subTitle: "오토X",
                badge: "1급",
                url: "/Prod01?tabIndex=0",
                new: "1",
            },
            {
                subeq: 1,
                subTitle: "오토7",
                badge: "1급",
                url: "/Prod01?tabIndex=1",
                new: "",
            },
            {
                subeq: 2,
                subTitle: "GES",
                badge: "1급",
                url: "/Prod02?tabIndex=0",
                new: "",
            },
            {
                subeq: 3,
                subTitle: "오토디엘",
                badge: "1급",
                url: "/Prod02?tabIndex=1",
                new: "",
            },
            {
                subeq: 4,
                subTitle: "프리미엄네오",
                badge: "전문정비업",
                url: "/Prod03?tabIndex=0",
                new: "1",
            },
            {
                subeq: 5,
                subTitle: "네오하이웨이",
                badge: "전문정비업",
                url: "/Prod03?tabIndex=1",
                new: "",
            },
            {
                subeq: 6,
                subTitle: "오토디엘",
                badge: "전문정비업",
                url: "/Prod02?tabIndex=2",
                new: "",
            },
            {
                subeq: 7,
                subTitle: "네오하이웨이 체인점",
                badge: "프렌차이즈",
                url: "/Prod04",
                new: "",
            },
            {
                subeq: 8,
                subTitle: "검사관리시스템",
                badge: "검사장",
                url: "/Prod01?tabIndex=2",
                new: "1",
            },
        ],
    },
    {
        seq: 2,
        title: "모바일 서비스",
        sub: [
            {
                subeq: 0,
                subTitle: "오토X M",
                badge: "1급",
                url: "/Prod05?tabIndex=0",
                new: "",
            },
            {
                subeq: 1,
                subTitle: "네오탭",
                badge: "전문정비업",
                url: "/Prod05?tabIndex=1",
                new: "",
            },
            {
                subeq: 2,
                subTitle: "오토포스 M",
                badge: "협단체물류",
                url: "/Prod05?tabIndex=2",
                new: "",
            },
        ],
    },
    {
        seq: 3,
        title: "프리미엄 서비스",
        sub: [
            {
                subeq: 0,
                subTitle: "네오셋 부품",
                badge: "",
                url: "/Prod06?tabIndex=0",
            },
            {
                subeq: 1,
                subTitle: "수입차부품견적",
                badge: "",
                url: "/Prod06?tabIndex=1",
                new: "",
            },
            {
                subeq: 2,
                subTitle: "차량식별정보",
                badge: "",
                url: "/Prod06?tabIndex=2",
                new: "",
            },
            {
                subeq: 3,
                subTitle: "네오샷",
                badge: "",
                url: "/Prod06?tabIndex=3",
                new: "",
            },
            { subeq: 4, subTitle: "원부조회", badge: "", url: "/Prod06?tabIndex=4" },
            {
                subeq: 5,
                subTitle: "카다이어리",
                badge: "",
                url: "/Prod06?tabIndex=5",
            },
            {
                subeq: 6,
                subTitle: "네오북",
                badge: "",
                url: "/Prod06?tabIndex=6",
            },
            {
                subeq: 7,
                subTitle: "네오스캔",
                badge: "",
                url: "/Prod06?tabIndex=7",
            },
            //   {
            //     subeq: 5,
            //     subTitle: "080수신거부",
            //     badge: "",
            //     url: "/Prod06?tabIndex=5",
            //     new: "",
            //   },
            //   { subeq: 6, subTitle: "알림톡", badge: "", url: "/Prod06?tabIndex=6" },
            //   { subeq: 7, subTitle: "이노몰", badge: "", url: "/Prod06?tabIndex=7" },
        ],
    },
    {
        seq: 4,
        title: "제품문의",
        sub: [{ subeq: 0, subTitle: "제품문의", badge: "", url: "", new: "" }],
    },
    {
        seq: 5,
        title: "고객센터",
        sub: [
            {
                subeq: 0,
                subTitle: "전국 서비스망 네트워크",
                badge: "",
                url: "",
                new: "",
            },
            {
                subeq: 1,
                subTitle: "FAQ (자주하는 질문)",
                badge: "",
                url: "",
                new: "",
            },
            { subeq: 2, subTitle: "업계이슈", badge: "", url: "", new: "" },
            { subeq: 3, subTitle: "신규기능소개", badge: "", url: "", new: "" },
            { subeq: 4, subTitle: "온라인장부", badge: "", url: "", new: "" },
        ],
    },
    {
        seq: 6,
        title: "회사소개",
        sub: [
            { subeq: 0, subTitle: "회사소개", badge: "", url: "", new: "" },
            { subeq: 1, subTitle: "회사연혁", badge: "", url: "", new: "" },
            { subeq: 2, subTitle: "공지사항", badge: "", url: "", new: "" },
            { subeq: 3, subTitle: "인트라밴 발자취", badge: "", url: "", new: "" },
            { subeq: 4, subTitle: "찾아 오시는 길", badge: "", url: "", new: "" },
            { subeq: 5, subTitle: "업무제휴", badge: "", url: "", new: "" },
        ],
    },
];
