import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../comm/Header";
import Carinfo from "./Carinfo"; //원부조회 상품소개
// import Deny080 from "./Deny080"; //080수신거부 상품소개
import NeoSet from "./NeoSet"; //네오세트 상품소개
import queryString from "query-string";
import Tabs from "./Tabs";
import Tab from "./Tab";
import ImportedCar from "./ImportedCar";
import Identify from "./Identify";
import AiCarno from "./AiCarno";
import Cardiary from "./Cardiary";
import NeoBook from "./NeoBook";
import NeoScan from "./NeoScan";

function Prod06({ match, location, history }) {
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const tabIndex = Number(query.tabIndex) || 0;
  const [subTitle, setSubTitle] = useState();

  //원부조회 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_1, setBoxAnimation01_1] = useState(null);
  const [boxAnimation02_1, setBoxAnimation02_1] = useState(null);
  const [boxAnimation03_1, setBoxAnimation03_1] = useState(null);
  const [boxAnimation04_1, setBoxAnimation04_1] = useState(null);

  const onVisibilityBox01_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_1("1");
    }
  };

  const onVisibilityBox02_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_1("1");
    }
  };

  const onVisibilityBox03_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_1("1");
    }
  };

  const onVisibilityBox04_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_1("1");
    }
  };
  //원부조회 CSS 관련 STATE 및 Function 끝

  //카다이어리 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_2, setBoxAnimation01_2] = useState(null);
  const [boxAnimation02_2, setBoxAnimation02_2] = useState(null);
  const [boxAnimation03_2, setBoxAnimation03_2] = useState(null);
  const [boxAnimation04_2, setBoxAnimation04_2] = useState(null);
  const [boxAnimation05_2, setBoxAnimation05_2] = useState(null);

  const onVisibilityBox01_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_2("1");
    }
  };

  const onVisibilityBox02_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_2("1");
    }
  };

  const onVisibilityBox03_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_2("1");
    }
  };

  const onVisibilityBox04_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_2("1");
    }
  };

  const onVisibilityBox05_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05_2("1");
    }
  };
  //카다이어리 CSS 관련 STATE 및 Function 끝

  //네오셋 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_3, setBoxAnimation01_3] = useState(null);
  const [boxAnimation02_3, setBoxAnimation02_3] = useState(null);
  const [boxAnimation03_3, setBoxAnimation03_3] = useState(null);
  const [boxAnimation04_3, setBoxAnimation04_3] = useState(null);

  const onVisibilityBox01_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_3("1");
    }
  };

  const onVisibilityBox02_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_3("1");
    }
  };

  const onVisibilityBox03_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_3("1");
    }
  };

  const onVisibilityBox04_3Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_3("1");
    }
  };
  //네오셋 CSS 관련 STATE 및 Function 끝

  //수입차부품견적 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_4, setBoxAnimation01_4] = useState(null);
  const [boxAnimation02_4, setBoxAnimation02_4] = useState(null);
  const [boxAnimation03_4, setBoxAnimation03_4] = useState(null);
  const [boxAnimation04_4, setBoxAnimation04_4] = useState(null);
  const [boxAnimation05_4, setBoxAnimation05_4] = useState(null);

  const onVisibilityBox01_4Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_4("1");
    }
  };

  const onVisibilityBox02_4Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_4("1");
    }
  };

  const onVisibilityBox03_4Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_4("1");
    }
  };

  const onVisibilityBox04_4Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_4("1");
    }
  };

  const onVisibilityBox05_4Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05_4("1");
    }
  };
  //수입차부품견적 CSS 관련 STATE 및 Function 끝

  //차량식별정보 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_5, setBoxAnimation01_5] = useState(null);
  const [boxAnimation02_5, setBoxAnimation02_5] = useState(null);
  const [boxAnimation03_5, setBoxAnimation03_5] = useState(null);
  const [boxAnimation04_5, setBoxAnimation04_5] = useState(null);

  const onVisibilityBox01_5Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_5("1");
    }
  };

  const onVisibilityBox02_5Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_5("1");
    }
  };

  const onVisibilityBox03_5Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_5("1");
    }
  };

  const onVisibilityBox04_5Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_5("1");
    }
  };
  //차량식별정보 CSS 관련 STATE 및 Function 끝

  //네오샷 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_6, setBoxAnimation01_6] = useState(null);
  const [boxAnimation02_6, setBoxAnimation02_6] = useState(null);
  const [boxAnimation03_6, setBoxAnimation03_6] = useState(null);

  const onVisibilityBox01_6Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_6("1");
    }
  };

  const onVisibilityBox02_6Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_6("1");
    }
  };

  const onVisibilityBox03_6Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_6("1");
    }
  };
  //네오샷 CSS 관련 STATE 및 Function 끝

  //네오북 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_7, setBoxAnimation01_7] = useState(null);
  const [boxAnimation02_7, setBoxAnimation02_7] = useState(null);
  const [boxAnimation03_7, setBoxAnimation03_7] = useState(null);
  const [boxAnimation04_7, setBoxAnimation04_7] = useState(null);
  const [boxAnimation05_7, setBoxAnimation05_7] = useState(null);
  const [boxAnimation06_7, setBoxAnimation06_7] = useState(null);

  const onVisibilityBox01_7Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_7("1");
    }
  };

  const onVisibilityBox02_7Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_7("1");
    }
  };

  const onVisibilityBox03_7Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_7("1");
    }
  };

  const onVisibilityBox04_7Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_7("1");
    }
  };

  const onVisibilityBox05_7Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05_7("1");
    }
  };

  const onVisibilityBox06_7Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation06_7("1");
    }
  };
  //네오북 CSS 관련 STATE 및 Function 끝

  //네오스캔 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_8, setBoxAnimation01_8] = useState(null);
  const [boxAnimation02_8, setBoxAnimation02_8] = useState(null);
  const [boxAnimation03_8, setBoxAnimation03_8] = useState(null);
  const [boxAnimation04_8, setBoxAnimation04_8] = useState(null);
  const [boxAnimation05_8, setBoxAnimation05_8] = useState(null);

  const onVisibilityBox01_8Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_8("1");
    }
  };

  const onVisibilityBox02_8Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_8("1");
    }
  };

  const onVisibilityBox03_8Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_8("1");
    }
  };

  const onVisibilityBox04_8Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_8("1");
    }
  };

  const onVisibilityBox05_8Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05_8("1");
    }
  };
  //네오스캔 CSS 관련 STATE 및 Function 끝

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="section prod">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">{subTitle}</h2>
              <p className="text-white pt-3">프리미엄 서비스 &gt; {subTitle}</p>
            </div>
          </div>
        </div>
      </section>

      <div className="tabs pt-99 pb-99">
        <Tabs index={tabIndex} setSubTitle={setSubTitle}>
          <Tab label="네오셋 부품">
            <NeoSet
              boxAnimation01={boxAnimation01_3}
              boxAnimation02={boxAnimation02_3}
              boxAnimation03={boxAnimation03_3}
              boxAnimation04={boxAnimation04_3}
              onVisibilityBox01Change={onVisibilityBox01_3Change}
              onVisibilityBox02Change={onVisibilityBox02_3Change}
              onVisibilityBox03Change={onVisibilityBox03_3Change}
              onVisibilityBox04Change={onVisibilityBox04_3Change}
            />
          </Tab>
          <Tab label="수입차부품견적">
            <ImportedCar
              boxAnimation01={boxAnimation01_4}
              boxAnimation02={boxAnimation02_4}
              boxAnimation03={boxAnimation03_4}
              boxAnimation04={boxAnimation04_4}
              boxAnimation05={boxAnimation05_4}
              onVisibilityBox01Change={onVisibilityBox01_4Change}
              onVisibilityBox02Change={onVisibilityBox02_4Change}
              onVisibilityBox03Change={onVisibilityBox03_4Change}
              onVisibilityBox04Change={onVisibilityBox04_4Change}
              onVisibilityBox05Change={onVisibilityBox05_4Change}
            />
          </Tab>
          <Tab label="차량식별정보">
            <Identify
              boxAnimation01={boxAnimation01_5}
              boxAnimation02={boxAnimation02_5}
              boxAnimation03={boxAnimation03_5}
              boxAnimation04={boxAnimation04_5}
              onVisibilityBox01Change={onVisibilityBox01_5Change}
              onVisibilityBox02Change={onVisibilityBox02_5Change}
              onVisibilityBox03Change={onVisibilityBox03_5Change}
              onVisibilityBox04Change={onVisibilityBox04_5Change}
            />
          </Tab>
          <Tab label="네오샷">
            <AiCarno
              boxAnimation01={boxAnimation01_6}
              boxAnimation02={boxAnimation02_6}
              boxAnimation03={boxAnimation03_6}
              onVisibilityBox01Change={onVisibilityBox01_6Change}
              onVisibilityBox02Change={onVisibilityBox02_6Change}
              onVisibilityBox03Change={onVisibilityBox03_6Change}
            />
          </Tab>
          <Tab label="원부조회">
            <Carinfo
              boxAnimation01={boxAnimation01_1}
              boxAnimation02={boxAnimation02_1}
              boxAnimation03={boxAnimation03_1}
              boxAnimation04={boxAnimation04_1}
              onVisibilityBox01Change={onVisibilityBox01_1Change}
              onVisibilityBox02Change={onVisibilityBox02_1Change}
              onVisibilityBox03Change={onVisibilityBox03_1Change}
              onVisibilityBox04Change={onVisibilityBox04_1Change}
            />
          </Tab>
          <Tab label="카다이어리">
            <Cardiary
              boxAnimation01={boxAnimation01_2}
              boxAnimation02={boxAnimation02_2}
              boxAnimation03={boxAnimation03_2}
              boxAnimation04={boxAnimation04_2}
              boxAnimation05={boxAnimation05_2}
              onVisibilityBox01Change={onVisibilityBox01_2Change}
              onVisibilityBox02Change={onVisibilityBox02_2Change}
              onVisibilityBox03Change={onVisibilityBox03_2Change}
              onVisibilityBox04Change={onVisibilityBox04_2Change}
              onVisibilityBox05Change={onVisibilityBox05_2Change}
            />
          </Tab>
          <Tab label="네오북">
            <NeoBook
              boxAnimation01={boxAnimation01_7}
              boxAnimation02={boxAnimation02_7}
              boxAnimation03={boxAnimation03_7}
              boxAnimation04={boxAnimation04_7}
              boxAnimation05={boxAnimation05_7}
              boxAnimation06={boxAnimation06_7}
              onVisibilityBox01Change={onVisibilityBox01_7Change}
              onVisibilityBox02Change={onVisibilityBox02_7Change}
              onVisibilityBox03Change={onVisibilityBox03_7Change}
              onVisibilityBox04Change={onVisibilityBox04_7Change}
              onVisibilityBox05Change={onVisibilityBox05_7Change}
              onVisibilityBox06Change={onVisibilityBox06_7Change}
            />
          </Tab>
          <Tab label="네오스캔">
            <NeoScan
              boxAnimation01={boxAnimation01_8}
              boxAnimation02={boxAnimation02_8}
              boxAnimation03={boxAnimation03_8}
              boxAnimation04={boxAnimation04_8}
              boxAnimation05={boxAnimation05_8}
              onVisibilityBox01Change={onVisibilityBox01_8Change}
              onVisibilityBox02Change={onVisibilityBox02_8Change}
              onVisibilityBox03Change={onVisibilityBox03_8Change}
              onVisibilityBox04Change={onVisibilityBox04_8Change}
              onVisibilityBox05Change={onVisibilityBox05_8Change}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default Prod06;
