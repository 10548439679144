import { useEffect, useState } from "react";
import VisibilitySensor from 'react-visibility-sensor'

function Partner(props) {
    const [bannerOpacity, setBannerOpacity] = useState(null)

    const onVisibilityChangePartner = (isVisible) => {
        if (isVisible) {
            setBannerOpacity('1')
        }
    }

    useEffect(() => {
        if (props.kind === 'sub') {
            setBannerOpacity('1')
        }
    },
    // eslint-disable-next-line
    []);

    return (
        <div className="row clients-logo pt-4">
            <VisibilitySensor
                scrollCheck={true}
                onChange={onVisibilityChangePartner}
                delayedCall
            >
                <div className="col-lg-3 col-sm-12 col-md-6">
                    <div className="client-thumb">
                        <img src="./assets/partner/img_adb_o.png"
                            className={`img-fluid2 partner-banner1 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                            // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_adb_o.png"}
                            // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_adb.png"}
                            alt="autodatabank" />
                    </div>
                </div>
            </VisibilitySensor>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_bosch_o.png"
                        className={`img-fluid2 partner-banner3 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_bosch_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_bosch.png"}
                        alt="bosch" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_carzen_o.png"
                        className={`img-fluid2 partner-banner2 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_carzen_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_carzen.png"}
                        alt="carzen" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_db_o.png"
                        className={`img-fluid2 partner-banner5 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_db_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_db.png"}
                        alt="db" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_didim_o.png"
                        className={`img-fluid2 partner-banner4 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_didim_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_didim.png"}
                        alt="didim" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_hyosung_o.png"
                        className={`img-fluid2 partner-banner5 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_hyosung_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_hyosung.png"}
                        alt="hyosung" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_inno_o.png"
                        className={`img-fluid2 partner-banner1 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_inno_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_inno.png"}
                        alt="inno" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_kt_o.png"
                        className={`img-fluid2 partner-banner3 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_kt_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_kt.png"}
                        alt="kt" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_ptzone_o.png"
                        className={`img-fluid2 partner-banner2 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_ptzone_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_ptzone.png"}
                        alt="ptzone" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_samjung_o.png"
                        className={`img-fluid2 partner-banner4 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_samjung_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_samjung.png"}
                        alt="samjung" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_taxi_o.png"
                        className={`img-fluid2 partner-banner3 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_taxi_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_taxi.png"}
                        alt="taxi" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/img_cartech_o.png"
                        className={`img-fluid2 partner-banner5 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/img_cartech_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/img_cartech.png"}
                        alt="cartech" />
                </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-6">
                <div className="client-thumb">
                    <img src="./assets/partner/carpet_o.png"
                        className={`img-fluid2 partner-banner1 ${(bannerOpacity === '1') ? 'b-active' : ''}`}
                        // onMouseOver={e => e.currentTarget.src = "./assets/partner/carpet_o.png"}
                        // onMouseOut={e => e.currentTarget.src = "./assets/partner/carpet.png"}
                        alt="carpat" />
                </div>
            </div>
        </div>
    )
}

export default Partner