import { Link } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

function AutoXMob(props) {
    const prodInfos = [
        {
            'title': '상세한 정보 조회',
            'data': '차량의 입출고 현황, 보험 접수 사항, 과실 비율 등 중요 정보를 조회할 수 있어, 언제 어디서나 빠르게 상황에 대응할 수 있습니다.'
        },
        {
            'title': '직관적 업무 지시',
            'data': '사진을 활용해 업무 지시서를 쉽게 작성하고, 수리 과정별로 사진을 촬영하여 기록할 수 있습니다. 이는 작업의 투명성을 높이고 고객 신뢰를 구축합니다.'
        },
        {
            'title': '견적 작성의 신속성',
            'data': '차량 수리에 필요한 견적을 현장에서 바로 작성할 수 있으며, 세부 부위별 수가 정보와 함께 도장, 케미컬 작업에 대한 데이터도 제공됩니다. 이는 PC 솔루션과 동등한 수준의 견적 작성을 가능하게 합니다.'
        }
    ]

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>어떤 환경에서도 정비 공장의 모든 업무가 가능</h2>
                            <p className="pt-4 product-sub-descr1">
                                입고부터 출고까지 정비 공장의 전반적인 업무를 도와주는 <span className="pt-3 autoxmob-color">모바일 차량관리 앱 오토X</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img src="./assets/prod/autox_m_bg01.png" alt="오토X " className="client-thumb" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/autox-mob1.png" alt="" />
                                                    </li>
                                                    <li>
                                                        안정성과 접근성의 조화
                                                    </li>
                                                    <li className="autoxmob">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        KT 클라우드에 안전하게 보관되는 데이터는 언제든지 문제 발생 시 신속하게 복구할 수 있습니다.<br />이는 24시간 지속되는 모니터링과 관리를 통해 보안성을 보장합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autox-mob2.png" alt="" />
                                                </li>
                                                <li>
                                                    현장 중심의 모바일 앱
                                                </li>
                                                <li className="autoxmob">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    급박한 상황에서도 사무실로 돌아갈 필요 없이 즉각적으로 모든 문제에 대응할 수 있습니다.<br />태블릿 PC를 활용한 실시간 상담은 업무의 유연성을 극대화합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autox-mob3.png" alt="" />
                                                </li>
                                                <li>
                                                    포괄적 업무 처리
                                                </li>
                                                <li className="autoxmob">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    입고부터 출고까지, 오토X 모바일은 PC 솔루션에서 가능한 조회, 수리 이력 관리, 선견적 작성 등의 다양한 기능을 모바일 환경에서도 완벽하게 지원합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div
                            className={`col-12 text-center opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                        >
                            <img src="../assets/prod/img_autox_m_map.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper autoxmob">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">오토X 모바일로 업무의 경계를 허물다!</span><br />
                                <span className="product-copyright-sub text-white">언제 어디서나 완벽한 차량 관리가 가능한 전문 솔루션으로, </span><br />
                                <span className="font-weight-bold">효율적인 경영을 </span>실현하세요.<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="autoxmob-color">오토X 모바일</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${(props.boxAnimation02 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autoxmob-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                <img src="../assets/prod/img_autox_m_m01.png" alt={prodInfos[0].title} />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autoxmob-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation03 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                    <img src="../assets/prod/img_autox_m_m02.png" alt={prodInfos[1].title} />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti autoxmob-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti autoxmob-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${(props.boxAnimation04 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autoxmob-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                <img src="../assets/prod/img_autox_m_m03.png" alt={prodInfos[2].title} />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autoxmob-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod01">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-desktop" />
                                    <p>
                                        오토X
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AutoXMob