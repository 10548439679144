import axios from "axios";

// eslint-disable-next-line
//const apiUrl = "http://unionservice.goldauto.co.kr/Controllers/IVService.aspx";
//const apiEstUrl =
//  "http://unionservice.goldauto.co.kr/Controllers/ESTPService.aspx";

// eslint-disable-next-line
const apiUrl = "https://unionservice.intravan.co.kr/Controllers/IVService.aspx";
const apiEstUrl =
  "https://unionservice.intravan.co.kr/Controllers/ESTPService.aspx";

const axiosConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

async function reqData(jsonData) {
  const res = await axios.post(apiUrl, JSON.stringify(jsonData), axiosConfig);
  return res;
}

async function reqDataEst(jsonData) {
  const res = await axios.post(
    apiEstUrl,
    JSON.stringify(jsonData),
    axiosConfig
  );
  return res;
}

export { reqData, reqDataEst };
