import Header from "../comm/Header";
import FaqMenu from "./FaqMenu";
import Accordion from "../comm/Accordion";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { reqData } from "../../js/server";
import VisibilitySensor from "react-visibility-sensor";
import { cloneObj, fnNotLogin } from "../../js/util";

function Faq({ match, location, history }) {
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const [keyword, setKeyword] = useState("");
  const [kind, setKind] = useState(query.kind || "");
  const comcode = query.comcode;
  const [orgPost, setOrgPost] = useState([]);
  const [posts, setPosts] = useState([]);
  const [jsonLength, setJsonLength] = useState(0);
  let isWorking = false;

  const fetchData = () => {
    const jsonData = {
      servicename: "FAQ",
      kind: kind,
      keyword: keyword,
    };

    dispatch({ type: "loadingChange", payload: true });
    reqData(jsonData)
      .then((res) => {
        if (res.data.result === "OK") {
          setJsonLength(res.data.jsonData.length);
          setOrgPost(res.data.jsonData);

          if (res.data.jsonData.length > 20) {
            setPosts(res.data.jsonData.slice(0, 20));
          } else {
            setPosts(res.data.jsonData);
          }
        } else {
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: "loadingChange", payload: false });
        }, 500);
      });
  };

  const onVisibilityChangeFaqPaging = (isVisible) => {
    if (isVisible) {
      const postsLength = posts.length;
      if (postsLength <= jsonLength) {
        if (!isWorking) {
          dispatch({ type: "loadingChange", payload: true });

          isWorking = true;

          let Obj = cloneObj(posts);

          for (let i = postsLength; i < postsLength + 10; i++) {
            if (i >= jsonLength) {
              break;
            }

            Obj.push(orgPost[i]);
            setPosts(Obj);
          }

          setTimeout(() => {
            dispatch({ type: "loadingChange", payload: false });
          }, 500);
        }
      }

      isWorking = false;
    }
  };

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      if (comcode) {
        localStorage.setItem("comcode", comcode);
      }

      fetchData();
      // setTimeout(() => {
      //     dispatch({ type: 'loadingChange', payload: false })
      // }, 500)
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (localStorage.getItem("comcode")) {
        const elems = document.querySelectorAll(".accordion");
        const elemsIcon = document.querySelectorAll(".accordion__icon");
        const elemsContent = document.querySelectorAll(".accordion__content");

        elems.forEach((elem) => elem.classList.remove("active"));
        elemsIcon.forEach((elem) => elem.classList.remove("rotate"));
        elemsContent.forEach((elem) => (elem.style.maxHeight = 0));

        fetchData();
      } else {
        alert("인트라밴 솔루션 고객만 접근이 가능한 메뉴입니다.");
        fnNotLogin();
      }
    },
    // eslint-disable-next-line
    [kind]
  );

  return (
    <>
      <section className="section cscenter">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">FAQ (자주하는 질문)</h2>
              <p className="text-white pt-3">
                고객센터 &gt; FAQ (자주하는 질문)
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row pt-99">
          <div className="col-12">
            <h2>인트라밴 솔루션 사용에 궁금한 점이 있으세요?</h2>

            <p className="pt-3">
              고객센터로 접수되는 문제들 중 고객분들께서 가장 많이 질문해 주시는
              내용을 정리하여 제공하고 있습니다.
            </p>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-10 mx-auto">
            <FaqMenu kind={kind} setKind={setKind} />
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-12 text-center">
            <div className="search-bar">
              <div className="input-group">
                <input
                  className="form-control transparent-input"
                  type="text"
                  name="keyword"
                  placeholder="궁금한 내용을 입력해 주세요."
                  maxLength="20"
                  value={keyword || ""}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={fetchData}
                  >
                    <i className="fas fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-5 pb-99">
          <div className="col-12 accd-wrap">
            {posts.length > 0 ? (
              posts.map((post, i) => {
                return (
                  <Accordion
                    key={i}
                    title={post.title}
                    content={post.contents}
                    kind="faq"
                  />
                );
              })
            ) : (
              <div style={{ padding: 20 }}>결과가 없습니다.</div>
            )}
          </div>
        </div>

        <VisibilitySensor
          scrollCheck={true}
          onChange={onVisibilityChangeFaqPaging}
          delayedCall
        >
          <div className="row">
            <div className="col-12" style={{ height: 10 }}></div>
          </div>
        </VisibilitySensor>
      </div>
    </>
  );
}

export default Faq;
