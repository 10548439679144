import React from 'react'

function ProgramRecom({ setShowModal }) {
    return (
        <button className="btn-blue ml-2 border-0" onClick={(e) => setShowModal(true)}>
            나한테 맞는 솔루션 찾기<i className="fa-regular fa-wand-magic-sparkles ml-2" />
        </button>
    )
}

export default ProgramRecom