import './Paging.css'
import Pagination from "react-js-pagination"

function Paging({ page, count, setPage }) {
    return (
        <>
            <Pagination
                activePage={page}
                itemsCountPerPage={10}
                totalItemsCount={count}
                pageRangeDisplayed={5}
                prevPageText={<i className="fas fa-angle-left" />}
                nextPageText={<i className="fas fa-angle-right" />}
                firstPageText={<i className="fas fa-angle-double-left" />}
                lastPageText={<i className="fas fa-angle-double-right" />}
                onChange={setPage}
            />
        </>
    )
}

export default Paging;
