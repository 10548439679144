import React, { useEffect, useState } from "react";

function Tabs(props) {
  const [activeTab, setActiveTab] = useState(
    props.children[props.index].props.label
  );

  const changeTab = (tab) => {
    setActiveTab(tab);
    props.setSubTitle(tab);
  };

  let content;
  let buttons = [];

  useEffect(() => {
    props.setSubTitle(activeTab || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {React.Children.map(props.children, (child) => {
        buttons.push(child.props.label);
        if (child.props.label === activeTab) content = child.props.children;
      })}

      <TabButtons
        activeTab={activeTab}
        buttons={buttons}
        changeTab={changeTab}
      />

      <div className="tab-content">{content}</div>
    </div>
  );
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className="tab-buttons">
      {buttons.map((button, idx) => {
        return (
          <button
            key={idx}
            className={
              button === activeTab ? "tab-button active" : "tab-button"
            }
            onClick={() => changeTab(button)}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
