function FaqMenu(props) {
  return (
    <table className="table table-borderless faq-menu-wrap">
      <tbody>
        <tr>
          <td
            className={props.kind === "" ? "active" : ""}
            onClick={(e) => {
              props.setKind("");
            }}
          >
            전체
          </td>
          <td
            className={props.kind === "AUTO7" ? "active" : ""}
            onClick={(e) => {
              props.setKind("AUTO7");
            }}
          >
            오토7
          </td>
          <td
            className={props.kind === "AUTOX" ? "active" : ""}
            onClick={(e) => {
              props.setKind("AUTOX");
            }}
          >
            오토X
          </td>
          <td
            className={props.kind === "KAIMA" ? "active" : ""}
            onClick={(e) => {
              props.setKind("KAIMA");
            }}
          >
            카이마
          </td>
        </tr>
        <tr>
          <td
            className={props.kind === "AUTOPOS" ? "active" : ""}
            onClick={(e) => {
              props.setKind("AUTOPOS");
            }}
          >
            오토포스
          </td>
          <td
            className={props.kind === "NEO" ? "active" : ""}
            onClick={(e) => {
              props.setKind("NEO");
            }}
          >
            네오하이웨이
          </td>
          {props.page === "UPGRADE" ? (
            <td
              className={props.kind === "PEO" ? "active" : ""}
              onClick={(e) => {
                props.setKind("PEO");
              }}
            >
              프리미엄네오
            </td>
          ) : (
            <td
              className={props.kind === "BIZ5" ? "active" : ""}
              onClick={(e) => {
                props.setKind("BIZ5");
              }}
            >
              비즈5
            </td>
          )}

          <td
            className={props.kind === "ETC" ? "active" : ""}
            onClick={(e) => {
              props.setKind("ETC");
            }}
          >
            부가서비스
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default FaqMenu;
