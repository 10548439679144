import VisibilitySensor from "react-visibility-sensor";

function Carinfo(props) {
    const prodInfos = [
        {
            title: "오타가 잦은 차대번호 관리",
            data: "원부 조회를 통한 정확한 차대번호 및 차량 정보 확보로, 차량 등록과 관련된 실수를 방지하며, 정비 프로세스의 효율성을 높입니다.",
        },
        {
            title: "검사 만료일 관리",
            data: "고객 차량의 검사 만료일 관리를 통해, 검사 만료일 알림 서비스를 제공하여 고객에게 예방적 정비를 권장하고, 고객과의 신뢰를 강화합니다.",
        },
        {
            title: "수입차 정보의 접근성 향상",
            data: "수입차의 경우 얻기 어려운 원동기 및 엔진 형식 정보도 조회할 수 있어, 수입차 정비의 범위를 확장하고, 전문성을 강화합니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>완벽한 수리, 빈틈없는 고객 서비스를 위한!</h2>
                            <p className="pt-4 product-sub-descr1">
                                내 고객{" "}
                                <span className="pt-3 etc-service-color">
                                    차량의 모든 것을 관리
                                </span>
                                해 드리고 싶으시죠?
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/wonbu_bg01.png"
                                alt="원부조회"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/carinfo1.png" alt="" />
                                                    </li>
                                                    <li>고객 동의를 최우선으로</li>
                                                    <li className="etc-service">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        방문 고객의 명시적 동의를 바탕으로 차량의 원부 정보를 조회합니다. 이 과정은 고객의 개인 정보 보호를 강화하며, 신뢰 기반의 서비스 제공을 가능하게 합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/carinfo2.png" alt="" />
                                                </li>
                                                <li>정비의 정밀성 강화</li>
                                                <li className="etc-service">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    차대번호를 포함한 정확한 차량 정보를 통해, 부품 업체와의 원활한 소통을 가능하게 하며, 이는 더욱 정밀하고 빈틈없는 정비를 실현합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/carinfo3.png" alt="" />
                                                </li>
                                                <li>포괄적인 고객 관리</li>
                                                <li className="etc-service">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    검사 만료일, 최초 등록일 등의 중요 정보 제공을 통해, 고객 관리의 시작점을 마련합니다. 이 정보를 활용한 맞춤형 서비스 제공은 고객 만족도를 크게 높입니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper carinfo">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    완벽한 차량 관리를 위한 첫걸음, 원부조회로 시작하세요!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    성공적인 경영과 고객 만족의 핵심,
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    인트라밴의 전문 솔루션
                                </span>
                                을 경험해 보세요.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="etc-service-color">원부조회</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti etc-service-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_wonbu_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti etc-service-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_wonbu_pc02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti etc-service-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti etc-service-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti etc-service-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_wonbu_pc03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti etc-service-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod01">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>오토X</p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod03">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>프리미엄네오</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Carinfo;
