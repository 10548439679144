import { useEffect, useState } from "react";
import Header from "../comm/Header";
import History2000 from "./History2000";
import History2011 from "./History2011";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch } from "react-redux";

function History() {
  const [selectYear, setSelectYear] = useState("n");

  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="section intro">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">회사연혁</h2>
              <p className="text-white pt-3">회사소개 &gt; 회사연혁</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container pt-99 pb-99">
        <div className="row">
          <div className="col-12 text-center">
            {selectYear === "n" ? (
              <>
                <span
                  className="btn-history-01"
                  onClick={() => setSelectYear("p")}
                >
                  2000년 ~ 2010년
                </span>
                <span
                  className="btn-history-02 active"
                  onClick={() => setSelectYear("n")}
                >
                  2011년 ~ 현재
                </span>
              </>
            ) : (
              <>
                <span
                  className="btn-history-01 active"
                  onClick={() => setSelectYear("p")}
                >
                  2000년 ~ 2010년
                </span>
                <span
                  className="btn-history-02"
                  onClick={() => setSelectYear("n")}
                >
                  2011년 ~ 현재
                </span>
              </>
            )}
          </div>
        </div>

        <TransitionGroup className="transition-group">
          <CSSTransition key={selectYear} classNames="pageSlider" timeout={500}>
            {selectYear === "n" ? <History2011 /> : <History2000 />}
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );
}

export default History;
