import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../comm/Header'
import PartnerComp from '../Partner'
import VisibilitySensor from 'react-visibility-sensor'

function Partner() {
    const dispatch = useDispatch()
    const [boxAnimation1, setBoxAnimation1] = useState(null)
    const [boxAnimation2, setBoxAnimation2] = useState(null)
    const [boxAnimation3, setBoxAnimation3] = useState(null)
    const [boxAnimation4, setBoxAnimation4] = useState(null)

    const onVisibilityChange1 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation1('1')
        }
    }

    const onVisibilityChange2 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation2('1')
        }
    }

    const onVisibilityChange3 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation3('1')
        }
    }

    const onVisibilityChange4 = (isVisible) => {
        if (isVisible) {
            setBoxAnimation4('1')
        }
    }

    useEffect(() => {
        dispatch({ type: 'loadingChange', payload: true })

        setTimeout(() => {
            dispatch({ type: 'loadingChange', payload: false })
        }, 500)
    },
        // eslint-disable-next-line
        []);

    return (
        <>
            <section className="section intro">
                <Header page="sub" />

                <div className="container mt-7">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-white">업무제휴</h2>
                            <p className="text-white pt-3">회사소개 &gt; 업무제휴</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row pt-99 pb-99">
                    <div className="col-12">
                        <h2>성공 정비업의 시작!</h2>

                        <p className="pt-3">
                            <span className="text-bold">(주)인트라밴</span>은 안정적이고 신뢰도 높은 서비스를 기반으로
                        </p>
                        <p>
                            여러 기업들의 파트너로 오랫동안 함께하고 있습니다.
                        </p>
                    </div>

                    <div className="col-12 pt-5">
                        <PartnerComp kind="sub" />
                    </div>
                </div>
            </div>

            <div className="w-100 pt-99 pb-99" style={{ 'backgroundColor': '#f9f9f9' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3>(주)인트라밴은 안정적이고 신뢰도 높은 서비스로 여러 기업들의 파트너로</h3>
                            <h3 className="pt-2">오랫동안 함께 성장하고 있습니다.</h3>
                            <h4 className="pt-4 font-gray">여러분의 소중한 제안을 기다립니다.</h4>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="col-lg-3 col-md-6 col-sm-12 pt-2">
                            <div className="intro-box text-center">
                                <div
                                    className="d-flex d-flex-wrap align-items-center justify-content-center"
                                >
                                    <VisibilitySensor
                                        scrollCheck={true}
                                        onChange={onVisibilityChange1}
                                        delayedCall
                                    >
                                        <div
                                            className={`text-center opacity-0 ${(boxAnimation1 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                                        >

                                            <img src="../assets/img_asp.png" alt="Asp" />
                                            <h4 className="pt-4">ASP솔루션</h4>
                                            <p className="pt-3">1,2급 정비공장 종합 관리</p>
                                            <p>전문정비업 종합 관리</p>
                                            <p>정비업 프렌차이즈 관리</p>
                                            <p>협단체 물류 관리</p>
                                        </div>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 pt-2">
                            <div className="intro-box text-center">
                                <div
                                    className="d-flex d-flex-wrap align-items-center justify-content-center"
                                >
                                    <VisibilitySensor
                                        scrollCheck={true}
                                        onChange={onVisibilityChange2}
                                        delayedCall
                                    >
                                        <div
                                            className={`text-center opacity-0 ${(boxAnimation2 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                                        >
                                            <img src="../assets/img_consulting.png" alt="컨설팅" />
                                            <h4 className="pt-4">컨설팅</h4>
                                            <p className="pt-3">자동차 포탈 서비스</p>
                                            <p>온라인 쇼핑몰</p>
                                            <p>협단체 물류 수발주 시스템</p>
                                            <p>온라인 마케팅</p>
                                        </div>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 pt-2">
                            <div className="intro-box text-center">
                                <div
                                    className="d-flex d-flex-wrap align-items-center justify-content-center"
                                >
                                    <VisibilitySensor
                                        scrollCheck={true}
                                        onChange={onVisibilityChange3}
                                        delayedCall
                                    >
                                        <div
                                            className={`text-center opacity-0 ${(boxAnimation3 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                                        >
                                            <img src="../assets/img_si.png" alt="Si" />
                                            <h4 className="pt-4">Si</h4>
                                            <p className="pt-3">정비업 프렌차이즈 업체 관리</p>
                                            <p>각종 기관 차량관리</p>
                                            <p>보험 차량견적 시스템</p>
                                            <p>정비업 영업관리 모바일앱</p>
                                        </div>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 pt-2">
                            <div className="intro-box text-center">
                                <div
                                    className="d-flex d-flex-wrap align-items-center justify-content-center"
                                >
                                    <VisibilitySensor
                                        scrollCheck={true}
                                        onChange={onVisibilityChange4}
                                        delayedCall
                                    >
                                        <div
                                            className={`text-center opacity-0 ${(boxAnimation4 === '1') ? 'animated animatedFadeInUp fadeInUp' : ''}`}
                                        >
                                            <img src="../assets/img_si.png" alt="Si" />
                                            <h4 className="pt-4">자동차 종합 정보 플랫폼</h4>
                                            <p className="pt-3">"오토데이타뱅크"사와의 협업으로</p>
                                            <p>차량 제원, 경정비 이력 등</p>
                                            <p>진단 및 수리에 관한 종합적인</p>
                                            <p>기술 정보를 고객사에 제공</p>
                                        </div>
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="col-12 text-center">
                            <h3>귀사와 함께 할 수 있는 다양한 비즈니스에 대한 제안을 부탁드립니다.</h3>

                            <h5 className="pt-5 font-gray">제휴담당자 <i className="far fa-envelope-open" /></h5>
                            <h3 className="pt-2 font-gray">intravan@intravan.com</h3>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Partner