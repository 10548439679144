import VisibilitySensor from 'react-visibility-sensor'
import { Link } from 'react-router-dom'

function Auto7(props) {
    const prodInfos = [
        {
            'title': '타견적 불러오기',
            'data': '타 솔루션에서 작성된 견적을 오토7으로 쉽게 불러올 수 있습니다. 이는 매출 및 결제 관리를 포함한 업무 일원화를 가능하게 하여 관리의 용이성을 향상시킵니다.'
        },
        {
            'title': '차대번호 및 검사 만료일 확인 기능',
            'data': '차량의 중요 정보 조회를 통해 마케팅 전략 수립이나 부품 업체와의 신속한 연계가 가능합니다. 이는 고객 서비스 품질 향상에 기여합니다.'
        },
        {
            'title': '강력한 마케팅툴 탑재',
            'data': '다양한 고객 데이터를 기반으로 한 맞춤형 마케팅이 가능한 툴을 제공합니다. 이를 통해 고객 만족도를 높이고, 장기적인 고객 관계를 구축할 수 있습니다.'
        }
    ]

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>작업자가 언제나 정비에만 집중할 수 있도록</h2>
                            <p className="pt-4 product-sub-descr1">
                                업체 운영을 쉽게 도와주는 <span className="pt-3 auto7-color">종합 소형 1,2급 정비 공장용 프로그램 오토7</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img src="./assets/prod/auto7_bg01.png" alt="오토7" className="client-thumb" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/auto7_1.png" alt="" />
                                                    </li>
                                                    <li>
                                                        고객 맞춤 경영관리
                                                    </li>
                                                    <li className="auto7">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        다년간의 업계 점유율 1위 경험을 기반으로 한 맞춤형 경영 관리 서비스를 제공합니다. 오토7은 귀하의 업체 운영을 최적화하고 성장시킬 수 있는 강력한 도구입니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/auto7_2.png" alt="" />
                                                </li>
                                                <li>
                                                    고객 마케팅 필수 시대
                                                </li>
                                                <li className="auto7">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    오토7은 종합 소형 정비 공장을 위한 고객 관리 및 마케팅 프로그램으로, 매출 증가를 실현하는 데 필수적인 요소를 제공합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/auto7_3.png" alt="" />
                                                </li>
                                                <li>
                                                    One For All
                                                </li>
                                                <li className="auto7">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    경영, 견적, 국토부 정비 이력 전송까지, 오토7 하나로 모든 업무를 통합 관리할 수 있습니다. 이는 업무의 효율성을 극대화하고 시간을 절약할 수 있게 합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div
                            className={`col-12 text-center opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                        >
                            <img src="../assets/prod/img_auto7_map.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper auto7">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">한 번의 선택으로 정비업의 미래를 바꾸세요!</span><br />
                                <span className="product-copyright-sub text-white">전문성과 혁신을 바탕으로 한 오토7로</span><br />
                                <span className="font-weight-bold">당신의 비즈니스를 다음 단계</span>로 이끌어 보세요.<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="auto7-color">오토7</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${(props.boxAnimation02 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti auto7-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_auto7_pc01.png" alt={prodInfos[0].title} className="client-thumb" />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti auto7-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation03 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img src="../assets/prod/img_auto7_pc02.png" alt={prodInfos[1].title} className="client-thumb" />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti auto7-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti auto7-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${(props.boxAnimation04 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti auto7-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_auto7_pc03.png" alt={prodInfos[2].title} className="client-thumb" />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti auto7-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod05?tabIndex=0">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-mobile-alt" />
                                    <p>
                                        오토X 모바일
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=1">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-car" />
                                    <p>원부조회</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=2">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-comment-slash" />
                                    <p>080수신거부</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Auto7