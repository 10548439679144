import VisibilitySensor from "react-visibility-sensor";

function NeoSet(props) {
    const prodInfos = [
        {
            title: "신속한 견적 작성 지원",
            data: "교환 및 경정비 작업을 위주로 한 견적을 빠르게 작성할 수 있으며, 예상 작업 비용을 즉시 확인할 수 있습니다. 이는 작업 준비 시간을 최소화하고 고객 대응 속도를 높입니다.",
        },
        {
            title: "사용자 중심의 인터페이스",
            data: "다빈도 정비 작업 항목을 사용자가 쉽게 체크할 수 있도록 설계하여, 현장에서의 견적 작업을 효율적으로 만듭니다. 이는 작업자의 편의성을 극대화하고 업무 효율을 향상시킵니다.",
        },
        {
            title: "효율적인 서비스 관리",
            data: "현장의 태블릿 PC와 사무실이 원활하게 연동되어, 작업자는 시간과 노력을 절약하며 고객 응대의 질을 높일 수 있습니다. 이는 전반적인 서비스의 질을 향상시키는 데 기여합니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>신속한 정비 견적 작성 도우미! 여기 있습니다.</h2>
                            <p className="pt-4 product-sub-descr1">
                                입고 차량의 주요 정보와 정비 작업에 소요되는 각종 정보를 제공한{" "}
                                <span className="pt-3 etc-service-color">
                                    정비견적 작성을 위한 솔루션 네오셋
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/neoset_bg01.png"
                                alt="네오셋"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/neoset1.png" alt="" />
                                                    </li>
                                                    <li>주요 제원정보</li>
                                                    <li className="etc-service">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        차대번호에서 시작해 연식, 제작일, 연료 타입, 연료 분사 시스템, 변속기 유형 등 차량의 주요 제원 정보를 상세하게 제공합니다. 이는 정확한 견적 산출의 기반을 마련해 줍니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neoset2.png" alt="" />
                                                </li>
                                                <li>풍부한 정비 작업 항목</li>
                                                <li className="etc-service">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    약 200여 가지에 달하는 정비 작업 항목을 분류하여 제공, 소모품 교체부터 복잡한 수리 작업까지 커버합니다. 이는 서비스 범위를 확장하고 고객의 다양한 요구에 부응할 수 있게 합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neoset3.png" alt="" />
                                                </li>
                                                <li>부품 데이터의 풍부함</li>
                                                <li className="etc-service">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    약 30만 개의 부품 정보와 연동되어, 정비 작업과 관련된 정확한 부품 코드 및 가격 정보를 즉시 제공합니다. 견적 산출 시간을 대폭 단축시키며, 고객에게 빠르고 정확한 정보를 제공할 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper neoset">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    네오셋 부품, 정비 견적 작성의 혁신을 이끕니다!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    정확하고 신속한 서비스 제공으로
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    고객 만족도
                                </span>
                                를 극대화하세요.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="etc-service-color">네오셋</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span
                                    className="product-main-function-descr-ti etc-service-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_neoset_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span
                                    className="product-main-function-descr-ti etc-service-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_neoset_pc02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti etc-service-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti etc-service-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti etc-service-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_neoset_pc03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti etc-service-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod03">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>프리미엄네오</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default NeoSet;
