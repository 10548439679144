import { useEffect, useRef, useState } from "react";
import Header from "./Header";
import { useDispatch } from "react-redux";
import "./VinDecoder.css";
import LinkIcon from "./LinkIcon";
import axios from "axios";

function VinDecoder() {
  const contentsRef = useRef();

  const [userip, setUserip] = useState("");
  const [vinno, setVinno] = useState("");
  const [carinfo01Array, setCarinfo01Array] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [carinfo02Array, setCarinfo02Array] = useState([]);
  const [carinfo01, setCarinfo01] = useState({});
  const [carinfo02, setCarinfo02] = useState({});
  const [saleprice, setSaleprice] = useState("");
  const [modelnm, setModelnm] = useState("");
  const [remainCnt, setRemainCnt] = useState("10");

  const dispatch = useDispatch();

  const fetchData = async () => {
    if (Number(remainCnt) < 1) {
      alert(
        "체험가능한 건수를 모두 사용하셨습니다.\n\n서비스 이용을 원하시는 고객님께서는\n아래 홈페이지 링크를 통해 연락부탁드립니다."
      );
    } else if (vinno.length < 17) {
      alert("차대번호 17자리를 입력해 주세요.");
    } else {
      dispatch({ type: "loadingChange", payload: true });

      setCarinfo01Array([]);
      setCarinfo02Array([]);
      setCarinfo01({});
      setCarinfo02({});
      setSaleprice("");
      setModelnm("");

      const res = await axios.get(
        "https://adbcp.intravan.co.kr/apiVinOpt.aspx?vinno=" +
          vinno +
          "&ip=" +
          userip
      );

      if (res.data.result === "ok") {
        setCarinfo01Array(res.data.dataset);
        setCarinfo02Array(res.data.dataset_ext);
        setSaleprice(res.data.saleprice);
        setRemainCnt(res.data.remain_cnt);
        setModelnm(res.data.modelnm);

        if (res.data.dataset.length > 0) {
          setCarinfo01(res.data.dataset[0]);
          const tmpSeqno = res.data.dataset[0].seqno;
          const tmpArray = res.data.dataset_ext;
          const tmpCarinfo02 = tmpArray.filter((d) => d.seqno === tmpSeqno);

          if (tmpCarinfo02.length > 0) {
            setCarinfo02(tmpCarinfo02[0]);
          }

          console.log(tmpCarinfo02[0]);

          contentsRef.current?.scrollIntoView({ behavior: "smooth" });
        } else {
          alert(
            "현대, 기아, 삼성, 쌍용, 쉐보레 2000년 이후 생산된\n승용, RV, 소형승합, 소형화물에 대한 데이터만 제공됩니다.\n\n차대번호를 확인해 주세요."
          );
        }

        localStorage.setItem("remainCnt", res.data.remain_cnt);
      } else {
        alert(res.data.msg);
      }

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    }
  };

  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setUserip(res.data.IPv4);
  };

  const getRemainCnt = () => {
    const _remainCnt = localStorage.getItem("remainCnt");

    if (_remainCnt !== null) {
      setRemainCnt(_remainCnt);
    } else {
      localStorage.setItem("remainCnt", "10");
    }
  };

  const changeOpt = (value) => {
    dispatch({ type: "loadingChange", payload: true });

    const tmpCarinfo01 = carinfo01Array.filter((d) => d.seqno === value);
    const tmpCarinfo02 = carinfo02Array.filter((d) => d.seqno === value);

    if (tmpCarinfo01.length > 0) setCarinfo01(tmpCarinfo01[0]);
    if (tmpCarinfo02.length > 0) setCarinfo02(tmpCarinfo02[0]);

    setTimeout(() => {
      dispatch({ type: "loadingChange", payload: false });
    }, 500);
  };

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      getIp(); // ClientIp 획득
      getRemainCnt(); // 조회 잔여건수 획득

      //   console.log(carinfo01Array.length);
      //   console.log(carinfo02Array.length);
      //   console.log(saleprice);
      //   console.log(modelnm);

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className="vindecoder">
      <Header />

      <div className="container-vindecoder pt-7">
        <div className="container-vindecoder-inner bimg">
          <p className="vindecoder-title-descr01">
            초간단 차량 정보 조회 서비스
          </p>
          <p className="vindecoder-title-descr02 font-blue pt-2">
            차량식별정보 체험
          </p>

          <div className="container">
            <div className="row mt-6">
              <div className="col-12 vin-search-box">
                <div className="input-group">
                  <input
                    className="form-control transparent-input vin-text-input"
                    type="text"
                    name="keyword"
                    placeholder="차대번호"
                    maxLength="17"
                    value={vinno || ""}
                    onChange={(e) => setVinno(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => fetchData()}
                    >
                      <i className="fas fa-search font-blue" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12 text-right vin-leftover-label">
                체험 가능 잔여건수 : {remainCnt}건
                <br />({userip})
              </div>
            </div>

            <div className="row pt-4">
              <ul className="vin-descr">
                <li>
                  ■ 실제 솔루션에서는 차량번호와 고객명만으로 조회가 가능합니다.
                </li>
                <li>
                  ■ 본 화면은 차량의 어떤 정보가 솔루션과 연동되는지 홍보 목적의
                  화면이며 실제 솔루션 화면이 아닙니다.
                </li>
                <li>
                  ■ 현대, 기아, 삼성, 쌍용, 쉐보레 2000년 이후 생산된 승용, RV,
                  소형승합, 소형화물에 대한 데이터만 제공됩니다.
                </li>
              </ul>
            </div>

            <hr className="m-5" />

            <LinkIcon />
          </div>
        </div>
      </div>

      <div className="container-vindecoder pt-5 pb-5" ref={contentsRef}>
        <div className="container-vindecoder-inner">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center vindecoder-title-descr03">
                입력 차대번호 : {vinno || ""}
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-12">
                <span className="vin-badge">차량출고정보</span>
              </div>
            </div>

            <div className="row pt-5">
              <div className="col-6 vin-descr-text">모델명</div>
              <div className="col-6 vin-descr-text text-right">
                {modelnm || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">출고가</div>
              <div className="col-6 vin-descr-text text-right">
                {saleprice || ""}원
              </div>
            </div>

            <hr className="m-5" />

            <div className="row pt-5">
              <div className="col-9">
                <span className="vin-badge">차량식별정보</span>
              </div>
              {carinfo01Array.length > 0 ? (
                <select
                  className="col-3 form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    changeOpt(e.target.value);
                  }}
                >
                  {carinfo01Array.map((carinfo, i) => {
                    return (
                      <option key={i} value={carinfo.seqno}>
                        {carinfo.seqno}
                      </option>
                    );
                  })}
                </select>
              ) : null}
            </div>

            <div className="row pt-5">
              <div className="col-6 vin-descr-text">차량명</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo01.carname || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">연식</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo01.caryear || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">바디</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo01.opt1 || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">엔진</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo01.opt2 || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">연료분사</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo01.opt3 || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">연료</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo01.opt4 || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">변속기</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo01.opt5 || ""}
              </div>
            </div>

            <hr className="m-5" />

            <div className="row pt-5">
              <div className="col-12">
                <span className="vin-badge">차량규격정보</span>
              </div>
            </div>

            <div className="row pt-5">
              <div className="col-6 vin-descr-text">타이어(mm)</div>
              <div
                className="col-6 vin-descr-text text-right"
                dangerouslySetInnerHTML={{
                  __html: carinfo02.tire_size
                    ? carinfo02.tire_size.replace(/(?:\r\n|\r|\n)/g, "<br />")
                    : "",
                }}
              ></div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">배터리</div>
              <div
                className="col-6 vin-descr-text text-right"
                dangerouslySetInnerHTML={{
                  __html: carinfo02.battery
                    ? carinfo02.battery.replace(/(?:\r\n|\r|\n)/g, "<br />")
                    : "",
                }}
              ></div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">와이퍼(mm)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.wiper || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">엔진오일(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.engine_oil || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">미션오일(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.mission_oil || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">냉각수(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.cooling_water || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">요소수(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.diesel_water || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">에어컨가스 R134A</div>
              <div
                className="col-6 vin-descr-text text-right"
                dangerouslySetInnerHTML={{
                  __html: carinfo02.aircon_gas1
                    ? carinfo02.aircon_gas1.replace(/(?:\r\n|\r|\n)/g, "<br />")
                    : "",
                }}
              ></div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">에어컨가스 R1234YF</div>
              <div
                className="col-6 vin-descr-text text-right"
                dangerouslySetInnerHTML={{
                  __html: carinfo02.aircon_gas2
                    ? carinfo02.aircon_gas2.replace(/(?:\r\n|\r|\n)/g, "<br />")
                    : "",
                }}
              ></div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">연료탱크(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.fuel_tank || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">브레이크오일(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.break_oil || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">파워스티어링오일(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.steering_oil || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">인버터냉각수(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.inv_cooling_water || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">프런트디퍼런셜(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.front_diff || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">리어디퍼런셜(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.rear_diff || ""}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-6 vin-descr-text">트랜스퍼케이스오일(L)</div>
              <div className="col-6 vin-descr-text text-right">
                {carinfo02.trans_oil || ""}
              </div>
            </div>

            <hr className="m-5" />

            <LinkIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VinDecoder;
