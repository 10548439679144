import { Link } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

function Autopos(props) {
    const prodInfos = [
        {
            'title': '매출 누락 방지',
            'data': '오토포스는 수작업으로 인한 실수와 누락을 방지하며, 협력업체의 판매 내역 및 수수료를 정확하게 파악할 수 있는 시스템을 제공합니다. 이를 통해 모든 거래가 정확하고 투명하게 관리됩니다.'
        },
        {
            'title': '회원사 구매 이력 관리',
            'data': '각 회원사의 구매 이력을 관리하여, 시장의 변화에 능동적으로 대응할 수 있습니다. 또한, 구매가 적은 회원사에 대한 지원과 협력업체와의 상생 방안을 모색할 수 있습니다.'
        },
        {
            'title': '협력업체 판매량 파악',
            'data': '협력업체의 판매액과 유통량을 정확히 파악하여, 마케팅 전략 수립 및 수수료 수입 극대화에 기여합니다. 이를 통해 효율적인 물류 관리와 수익 증대를 도모할 수 있습니다.'
        }
    ]

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>단체와 협력업체를 위한 온라인 3자 거래 전표 시스템</h2>
                            <p className="pt-4 product-sub-descr1">
                                단체, 회원사, 협력사 모두에게 관리의 투명성 및 편리성을 제공하는 <span className="pt-3 autopos-color">협단체용 물류 관리 프로그램 오토포스</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img src="./assets/prod/autopos_bg01.png" alt="오토포스" className="client-thumb" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/autopos1.png" alt="" />
                                                    </li>
                                                    <li>
                                                        공동 이익 실현
                                                    </li>
                                                    <li className="autopos">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        단체, 회원사, 협력업체 간의 상생을 가능하게 하는 투명한 관리 기능을 제공합니다. 모든 관계자가 신뢰할 수 있는 정보 공유를 통해 공동의 이익을 실현할 수 있습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autopos2.png" alt="" />
                                                </li>
                                                <li>
                                                    손실 방지
                                                </li>
                                                <li className="autopos">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    전표 누락 방지 및 실시간으로 매출을 조회할 수 있는 기능을 통해, 재무 관리의 정확성과 신뢰성을 보장합니다. 이를 통해 재정적 손실을 방지하고 경영 효율성을 높일 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autopos3.png" alt="" />
                                                </li>
                                                <li>
                                                    빅데이터 구축
                                                </li>
                                                <li className="autopos">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    상품 유통 자료를 기반으로 한 빅데이터 구축을 통해 안정적인 운영을 실현합니다. 이는 시장의 변화와 소비자의 요구에 빠르게 대응할 수 있는 통찰력을 제공합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div
                            className={`col-12 text-center opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                        >
                            <img src="../assets/prod/img_autopos_map.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper autopos">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">성공경영의 기초는 좋은 솔루션입니다.</span><br />
                                <span className="product-copyright-sub text-white">인트라밴이 제공하는 전문관리 솔루션으로</span><br />
                                <span className="font-weight-bold">성공적인 경영을 지금 바로 실행</span>하세요!<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="autopos-color">오토포스</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${(props.boxAnimation02 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autopos-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_autopos_pc01.png" alt={prodInfos[0].title} className="client-thumb" />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autopos-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation03 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img src="../assets/prod/img_autopos_pc02.png" alt={prodInfos[1].title} className="client-thumb" />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti autopos-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti autopos-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${(props.boxAnimation04 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autopos-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_autopos_pc03.png" alt={prodInfos[2].title} className="client-thumb" />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autopos-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod05?tabIndex=2">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-mobile-alt" />
                                    <p>
                                        오토포스 모바일
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Autopos