import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";

function AutoX(props) {
    const prodInfos = [
        {
            title: "방대한 데이터",
            data: "오토X는 풍부한 공임 항목 및 부품 데이터를 탑재하여, 어떤 작업이라도 알맞은 데이터를 신속하게 제공합니다. 이를 통해 작업의 정확도와 속도를 높일 수 있습니다.",
        },
        {
            title: "국토부 정비이력 전송",
            data: "사용자 눈높이에 맞춰 국토부 정비 이력 전송을 간편하게 제공합니다. 복잡하고 번거로웠던 과정을 간소화하여, 모든 사용자가 쉽게 접근할 수 있습니다.",
        },
        {
            title: "재고관리의 편의성 극대화",
            data: "카테고리별 재고 관리 기능을 강화하여, 작업의 어려움을 최소화하였습니다. 초보자도 쉽게 작업할 수 있어, 재고 관리의 부담을 줄입니다.",
        },
        {
            title: "세심한 결산관리 기능",
            data: "차량수리, 부품매입, 일반수입비용, 할인 등 결산을 한 화면에서 일목요연하게 조회할 수 있으며, 다양한 결산 관련 인쇄물을 제공합니다. 이는 효율적인 재무 관리를 가능하게 합니다.",
        },
        {
            title: "AI 분석툴 제공",
            data: "오토X는 공장 운영 중 놓칠 수 있는 부분을 시스템이 분석하여, 각종 차트 및 통계 자료를 제공합니다. 이를 통해 경영 전략을 보다 명확하게 세우고, 운영의 효율성을 극대화할 수 있습니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>모든 정비업무를 위한 강력한 파트너</h2>
                            <p className="pt-4 product-sub-descr1">
                                오토7에서 강화된 기능을 갖춘{" "}
                                <span className="pt-3 autox-color">
                                    종합 소형 1,2급 정비 공장용 프로그램 오토X
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/autox_bg01.png"
                                alt="오토X"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/autox1.png" alt="" />
                                                    </li>
                                                    <li>모바일 업무 지원</li>
                                                    <li className="autox">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        업무에 더욱 친숙해진 모바일 영역의 지원으로, 사용자는 언제 어디서나 편리하게 업무를 처리할 수 있습니다. 이는 업무의 효율성을 대폭 향상시킵니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autox2.png" alt="" />
                                                </li>
                                                <li>클라우드 서비스</li>
                                                <li className="autox">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    미래 경영의 핵심인 데이터를 클라우드 서버에 저장하여, 각종 사고와 랜섬웨어로부터 중요한 정보를 보호합니다. 안전하고 신뢰할 수 있는 데이터 관리를 실현합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autox3.png" alt="" />
                                                </li>
                                                <li>작업의 효율성 강화</li>
                                                <li className="autox">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    작업자를 위해 기능 및 사용성이 강화되어, 기존 대비 훨씬 뛰어난 작업 효율성을 보장합니다. 이를 통해 더 많은 작업을 더 빠르고 정확하게 처리할 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="autox-color">오토X</span>
                            <span>의 차별화된 기술력</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span>Everywhere</span>
                                    <br />
                                    <br />
                                    <span>사무실, 현장, 출장지, 해외 어디서든 접속 가능</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span>Safe data</span>
                                    <br />
                                    <br />
                                    <span>
                                        최적의 데이터 보호
                                        <br />
                                        시스템 구축
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span>Any device</span>
                                    <br />
                                    <br />
                                    <span>PC, 모바일, 테블릿 등 기기 호환성 지원</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span>Strong security</span>
                                    <br />
                                    <br />
                                    <span>
                                        랜섬웨어 등의 다양한 바이러스 공격을 철저하게 방어
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span>Data service</span>
                                    <br />
                                    <br />
                                    <span>
                                        차량식별정보와
                                        <br />
                                        규격정보 제공
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-2"></div>
                    </div>

                    <div className="row pt-9">
                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox01ChangeSub}
                            delayedCall
                        >
                            <div className="row">
                                <div className="col-12">&nbsp;</div>
                            </div>
                        </VisibilitySensor>
                        <div
                            className={`col-12 text-center opacity-0 ${props.boxAnimation01_sub === "1"
                                ? "animated animatedFadeInUp fadeIn"
                                : ""
                                }`}
                        >
                            <img
                                src="../assets/prod/img_autox_map.png"
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="row pt-9">
                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox07Change}
                            delayedCall
                        >
                            <div className="row">
                                <div className="col-12">&nbsp;</div>
                            </div>
                        </VisibilitySensor>

                        <div
                            className={`col-12 opacity-0 ${props.boxAnimation07 === "1"
                                ? "animated animatedFadeInUp fadeIn"
                                : ""
                                }`}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 product-why-wrapper">
                                        <div className="text-center product-main-function-ti">
                                            <span>Why?</span>&nbsp;
                                            <span className="autox-color">AutoX</span>
                                        </div>

                                        <div className="col-12">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/ic_autox_security.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            랜섬 및 바이러스로 부터 소중한
                                                            <br />
                                                            고객데이터를 완벽하게 보호합니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/ic_autox_graph.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            바쁜 공장의 효율적이고 체계적인
                                                            <br />
                                                            관리 시스템을 실현합니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/ic_autox_manager.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            항시 공장 상황을 파악하고 긴급상황에
                                                            <br />
                                                            신속한 대처가 가능합니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper autox">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    오토X: 당신의 정비소에 혁신을 불어넣다!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    탁월한 기능으로 무장한 오토X
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    꿈꾸던 정비소 운영
                                </span>
                                을 현실로 만들어 드립니다.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="autox-color">오토X</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autox-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_autox_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autox-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_autox_pc02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti autox-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti autox-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autox-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_autox_pc03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autox-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_autox_pc04.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti autox-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti autox-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${props.boxAnimation06 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti autox-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox06Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_autox_pc05.png"
                                    alt={prodInfos[4].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti autox-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod05?tabIndex=0">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-mobile-alt" />
                                    <p>오토X 모바일</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=1">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-car" />
                                    <p>원부조회</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=2">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-comment-slash" />
                                    <p>080수신거부</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AutoX;
