import React from 'react'
import { Button, Modal } from "react-bootstrap";
import { productInfo } from '../../initialState/QuestionProdInfo';

function ModalProdInfo({ handleClose, modalShow, prodIndex }) {
    return (
        <Modal
            centered
            show={modalShow}
            onHide={handleClose}
        >
            <Modal.Header>
                <h4>{productInfo[prodIndex || 0].prodName}</h4>
            </Modal.Header>
            <Modal.Body className="question-modal pt-4">
                <h4>
                    기능설명
                </h4>

                <p
                    className="pt-2"
                    dangerouslySetInnerHTML={{
                        __html: productInfo[prodIndex || 0].funcDescr || null,
                    }}
                >
                </p>

                <hr />

                <h4>
                    활용팁
                </h4>

                <p
                    className="pt-2"
                    dangerouslySetInnerHTML={{
                        __html: productInfo[prodIndex || 0].tips || null,
                    }}
                >
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    확인
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ModalProdInfo