import icon01 from "../../assets/bt_home.png";
import icon02 from "../../assets/bt_kakao.png";
import icon03 from "../../assets/bt_blog.png";
import icon04 from "../../assets/bt_youtube.png";
import icon05 from "../../assets/bt_cafe.png";

function LinkIcon() {
  return (
    <>
      <div className="row">
        <div className="col-12 text-center vin-iv-sns-label">인트라밴 SNS</div>
      </div>
      <div className="row pt-3">
        <div className="col-12">
          <ul className="vin-sns-icon-box">
            <li>
              <a
                href="https://www.intravan.co.kr/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={icon01}
                  alt="인트라밴 홈페이지"
                  className="vin-link-icon"
                />
              </a>
              <br />
              <p>홈페이지</p>
            </li>
            <li>
              <a
                href="https://pf.kakao.com/_wLHdT"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={icon02}
                  alt="인트라밴 카카오채널"
                  className="vin-link-icon"
                />
              </a>
              <br />
              <p>카카오채널</p>
            </li>
            <li>
              <a
                href="https://blog.naver.com/kbk0437"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={icon03}
                  alt="인트라밴 블로그"
                  className="vin-link-icon"
                />
              </a>
              <br />
              <p>블로그</p>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCDzFFV5zWcuXAP1RK-LQ7UA"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={icon04}
                  alt="인트라밴 유튜브"
                  className="vin-link-icon"
                />
              </a>
              <br />
              <p>유튜브</p>
            </li>
            <li>
              <a
                href="https://cafe.naver.com/blue3rlof"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={icon05}
                  alt="인트라밴 카페"
                  className="vin-link-icon"
                />
              </a>
              <br />
              <p>카페</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default LinkIcon;
