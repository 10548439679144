import VisibilitySensor from "react-visibility-sensor";

function Cardiary(props) {
    const prodInfos = [
        {
            title: "간편한 예약 및 정확한 정비 지원",
            data: "차량 번호 입력을 통한 간편 예약, 정비일 선택, 수리 부위에 대한 사진 첨부 기능을 제공하여, 보다 정확하고 편리한 정비 서비스를 받을 수 있습니다.",
        },
        {
            title: "정밀한 점검 항목 정보 제공",
            data: "30개 이상의 소모성 부품의 예상 교환 주기와 엔진오일 교환 정보 등을 제공하여, 차량 유지관리에 필요한 주요 정보를 손쉽게 얻을 수 있습니다.",
        },
        {
            title: "체계적인 차계부 기능",
            data: "운행비용, 월별 차량 관리비 등을 관리할 수 있는 타임라인 기반의 차계부와 다양한 통계 기능을 제공하여, 차량 관리의 효율성을 극대화합니다.",
        },
        {
            title: "부가 서비스 정보 제공",
            data: "최신 유가 정보와 주변 정비소 정보 제공을 통해, 긴급 상황 발생 시 신속한 대처가 가능합니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>방문 고객을 위한 차계부 앱</h2>
                            <p className="pt-4 product-sub-descr1">
                                정비업소를 방문한 고객에게 제공되는 무료 차계부 서비스로,
                                <br />
                                <span className="pt-3 cardiary-color">
                                    차량 유지관리에 필요한 모든 정보
                                </span>
                                를 제공합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/bg01_cardiary.png"
                                alt="카다이어리"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img
                                                            src="../assets/prod/ic_cardiary_carsearch.png"
                                                            alt=""
                                                        />
                                                    </li>
                                                    <li>상세한 수리 이력 조회</li>
                                                    <li className="cardiary">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        방문한 정비업소에서의 차량 정비 이력을 날짜별로 제공받으실 수 있습니다. 이는 차량 관리에 있어서 투명성과 신뢰를 높여줍니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img
                                                        src="../assets/prod/ic_cardiary_part.png"
                                                        alt=""
                                                    />
                                                </li>
                                                <li>소모성 부품 교환 주기 제공</li>
                                                <li className="cardiary">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    엔진오일, 미션오일 등 중요한 예방 점검 항목의 교환 주기 정보를 제공받으실 수 있어, 차량을 최적의 상태로 유지할 수 있게 합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img
                                                        src="../assets/prod/ic_cardiary_writing.png"
                                                        alt=""
                                                    />
                                                </li>
                                                <li>효율적인 차계부 관리</li>
                                                <li className="cardiary">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    정비비용, 유류비 등 차량 유지비를 관리할 수 있는 차계부 기능을 통해, 차량 관리를 더욱 체계적으로 할 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper cardiary">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    차량 관리의 새로운 시작, 카다이어리와 함께하세요!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    정비업소 방문 고객을 위한 통합 차계부 서비스로,
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    편리하고 체계적인 차량 관리
                                </span>
                                를 경험해 보세요.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="cardiary-color">카다이어리</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span
                                    className="product-main-function-descr-ti cardiary-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-6 product-main-function-img">
                                <img
                                    src="../assets/prod/img_cardiary_m01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span
                                    className="product-main-function-descr-ti cardiary-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_cardiary_m02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti cardiary-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[1].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti cardiary-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[1].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti cardiary-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[2].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                <img
                                    src="../assets/prod/img_cardiary_m03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti cardiary-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[2].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_cardiary_m04.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti cardiary-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[3].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti cardiary-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[3].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod03">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>프리미엄네오</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Cardiary;
