import "./Header.css";

function Header() {
  return (
    <div className="vin-header">
      <div className="vin-header-inner">
        <img
          src="../assets/vindecoder/iv_logo.png"
          alt=""
          className="vin-logo"
        />
      </div>
    </div>
  );
}

export default Header;
