import VisibilitySensor from "react-visibility-sensor";

function AutoDL(props) {
    const prodInfos = [
        {
            title: "실수 방지 및 자동화된 작업 입력",
            data: "예를 들면 힌지, 가니시 등 작업 항목 선택 시 관련 액세서리 도장 항목이 자동으로 입력됩니다. 이는 작업의 누락을 방지하고 작업자의 편의성을 극대화합니다.",
        },
        {
            title: "공유견적",
            data: "우수한 견적 작성 내용을 공유함으로써, 작업 부위별로 쉽게 확인하고 필요한 작업 내역을 일괄적으로 불러올 수 있습니다. 이는 견적 작성의 효율성을 높이는 데 기여합니다.",
        },
        {
            title: "표준시간 건의사항 접수",
            data: "작업시간, 작업 항목 등에 대한 개선 건의 사항을 수집하여 검증한 후 신속하게 적용합니다. 이는 견적의 정확성과 공정성을 보장합니다.",
        },
        {
            title: "국토부 정비이력 전송",
            data: "국토부 정비 이력 전송을 사용자 친화적으로 간소화하여, 어렵고 번거로운 과정 없이 쉽게 이행할 수 있습니다. 이 기능은 업무의 법적 준수를 보장하며 고객 신뢰를 높입니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>보험사가 아닌 공장 사용자 중심의 견적 관리</h2>
                            <p className="pt-4 product-sub-descr1">
                                견적 작성 자동 어시스트 프로그램{" "}
                                <span className="pt-3 adl-color">
                                    Auto Deep Learning 오토디엘
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/adl_bg01.png"
                                alt="ADL"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/autodl1.png" alt="" />
                                                    </li>
                                                    <li>공표수가 완벽 탑재</li>
                                                    <li className="adl">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        오토디엘은 2018년 공표수가를 완벽하게 탑재하여, 누수 항목 없이 정확한 견적 작성이 가능합니다<br />이를 통해 모든 작업이 정밀하고 효율적으로 이루어질 수 있습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autodl2.png" alt="" />
                                                </li>
                                                <li>조합원과의 소통 및 개선</li>
                                                <li className="adl">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    작업시간에 대한 측정 오류가 있을 시, 조합원이 즉시 민원을 제기할 수 있으며, 신속하게 개선 조치가 가능합니다. 이는 공장 운영의 투명성과 신뢰성을 높입니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autodl3.png" alt="" />
                                                </li>
                                                <li>데이터베이스 구축</li>
                                                <li className="adl">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    견적사들이 견적 작성 시 사용하는 빅데이터 분석 및 머신러닝을 통해 자동견적 작성을 어시스트합니다.<br />이로써 견적 작성의 정확성과 속도가 대폭 향상됩니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper adl">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    사용할수록 더 똑똑해지는 솔루션
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    아직도 망설이시나요?
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    시간 낭비 없는 견적 작성!
                                </span>&nbsp;
                                지금 시작하세요.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="adl-color">오토디엘</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span
                                    className="product-main-function-descr-ti adl3-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_adl1_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                    className="product-main-function-descr-ti adl-color"
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_adl1_pc02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti adl-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti adl-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti adl-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_adl1_pc03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti adl-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_adl1_pc04.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti adl-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti adl-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AutoDL;
