function History2000() {
  return (
    <div className="mt-99 history-wrap">
      <div className="history-contents r">
        <h1 className="history-year r">2010</h1>

        <table>
          <tbody>
            <tr>
              <td>06月</td>
              <td>
                지식경제부, IT 활용 서비스산업 경쟁력 강화사업 개발 수행업체
                선정
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents l">
        <h1 className="history-year l">2009</h1>

        <table>
          <tbody>
            <tr>
              <td>04月</td>
              <td>자동차 정비 공장용 솔루션 "오토7" 신제품 출시</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents r">
        <h1 className="history-year r">2008</h1>

        <table>
          <tbody>
            <tr>
              <td>11月</td>
              <td>
                전남자동차검사정비사업조합, 자동차 검사 촬영 시스템 구축 협약
              </td>
            </tr>
            <tr>
              <td>03月</td>
              <td>2008 오토서비스코리아 전시회 참가</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents l">
        <h1 className="history-year l">2007</h1>

        <table>
          <tbody>
            <tr>
              <td>11月</td>
              <td>KT 비즈메카, 모바일 차계부 서비스 출시</td>
            </tr>
            <tr>
              <td>04月</td>
              <td>KT 비즈메카, 전화 사전 응대 서비스 "하이웨이콜" 출시</td>
            </tr>
            <tr>
              <td>03月</td>
              <td>
                KT 비즈메카, 자동차 정비업 홍보 솔루션 "이길로" 출시
                <br />
                서울지방 중소기업청, 기술혁신 중소기업(INNO_BIZ)으로 A등급 선정
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents r">
        <h1 className="history-year r">2006</h1>

        <table>
          <tbody>
            <tr>
              <td>07月</td>
              <td>
                인천자동차검사정비사업조합, 검사 촬영 시스템 공급 계약
                <br />
                경기북부자동차검사정비사업조합, 검사 촬영 시스템 공급 계약
              </td>
            </tr>
            <tr>
              <td>04月</td>
              <td>
                경정비업소용 ASP 웹사이트 서비스(highwaylite.bizmeka.com) 출시
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents l">
        <h1 className="history-year l">2005</h1>

        <table>
          <tbody>
            <tr>
              <td>12月</td>
              <td>
                한국IT렌탈산업협회, ASP 인증위원회 주관 ASP 인증사업자 선정 및
                인증 마크 획득
              </td>
            </tr>
            <tr>
              <td>11月</td>
              <td>
                한국 전산원 주관, 2005 대한민국 전자 상거래 대상 ASP 부문
                최우수상 수상
              </td>
            </tr>
            <tr>
              <td>01月</td>
              <td>
                정보통신부 BM 자동차 정비산업군간 ASP 연동형 Multi 협업
                e-Business 상용화
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents r">
        <h1 className="history-year r">2004</h1>

        <table>
          <tbody>
            <tr>
              <td>05月</td>
              <td>
                정보통신부, 자동차 정비산업군간 ASP 연동형 Multi 협업 e-Business
                BM 과제 수행 사업자 선정
                <br />
                엔팁스(NTIPS), 자동차 정비기술 컨텐츠 제공 협정
              </td>
            </tr>
            <tr>
              <td>03月</td>
              <td>
                전국자동차검사정비사업조합 연합회 website 구축 수주
                (www.kaima.or.kr)
              </td>
            </tr>
            <tr>
              <td>01月</td>
              <td>
                KT 비즈메카, 하이웨이 B2B 마켓 플레이스 사업 운영 업무협약
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents l">
        <h1 className="history-year l">2003</h1>

        <table>
          <tbody>
            <tr>
              <td>11月</td>
              <td>정비공장용 종합관리 프로그램 "오토4" 신제품 출시</td>
            </tr>
            <tr>
              <td>09月</td>
              <td>KT, 비즈메카 하이웨이 ASP 솔루션 사용업체 1,000개 달성</td>
            </tr>
            <tr>
              <td>07月</td>
              <td>대구자동차검사정비사업조합, 온라인 자동차 검사시스템 구축</td>
            </tr>
            <tr>
              <td>05月</td>
              <td>인천자동차검사정비사업조합, 온라인 자동차 검사시스템 구축</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents r">
        <h1 className="history-year r">2002</h1>

        <table>
          <tbody>
            <tr>
              <td>12月</td>
              <td>보험개발원, 사고차 온라인 보험 청구 시스템(AOS) 구축 수주</td>
            </tr>
            <tr>
              <td>09月</td>
              <td>
                인천자동차검사정비사업조합, 온라인 자동차 검사시스템 구축 수주
              </td>
            </tr>
            <tr>
              <td>06月</td>
              <td>KT, "비즈메카 하이웨이" ASP 솔루션 발표 (C/S Ver.)</td>
            </tr>
            <tr>
              <td>05月</td>
              <td>
                KT, "자동차정비관리(비즈메카 하이웨이)" 서비스 제공을 위한 사업
                협정 체결
              </td>
            </tr>
            <tr>
              <td>04月</td>
              <td>벤처기업 지정 (서울지방 중소기업청)</td>
            </tr>
            <tr>
              <td>01月</td>
              <td>보험개발원 자동차 기술연구소와 MOU 체결</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="history-contents l">
        <h1 className="history-year l">2000</h1>

        <table>
          <tbody>
            <tr>
              <td>06月</td>
              <td>자동차 포탈 및 ASP 웹서비스 오픈 (www.goldauto.co.kr)</td>
            </tr>

            <tr>
              <td>04月</td>
              <td>(주)인트라밴, 법인 설립</td>
            </tr>

            <tr>
              <td>02月</td>
              <td>자동차 관련 인터넷 비즈니스 모델(BM) 특허출원</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default History2000;
