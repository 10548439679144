import { Link } from "react-router-dom";

function Direction() {
    return (
        <Link to="/Direction">
            <div
                className="main-map"
            >
                    <div
                        className="d-flex d-flex-wrap align-items-center justify-content-center"
                    >
                        <div className="text-center" style={ { 'lineHeight': '500%' } }>
                            <h1 className="text-white pt-4">인트라밴 오시는 길</h1>
                            <i className="fas fa-arrow-circle-right mini-map-arrow" />
                        </div>
                    </div>
            </div>
        </Link>
    );
}

export default Direction