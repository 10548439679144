import VisibilitySensor from "react-visibility-sensor";

function NeoBook(props) {
    const prodInfos = [
        {
            title: "간편한 발행!",
            data: "사용자의 편의성을 고려하여 설계된 발행 기능을 통해, 단 한 번의 클릭으로 고객에게 네오북을 발행할 수 있습니다.",
        },
        {
            title: "현장감 있는 견적!",
            data: "주요 소모품에 대한 작업 내용을 간편하게 체크하여 고객에게 현장감 있는 견적을 제공하세요. 이는 고객의 신뢰를 쌓고, 서비스 만족도를 높입니다.",
        },
        {
            title: "다중 차량 관리!",
            data: "여러 대의 차량을 보유한 고객도 효과적으로 관리할 수 있도록, 각 차량의 상세 정보를 쉽게 업데이트하고 조회할 수 있습니다.",
        },
        {
            title: "예방항목 미리보기!",
            data: "차량의 예방 정비 항목을 실시간으로 업데이트하며, 고객에게 예방 정비 계획을 제공하여 차량 유지 관리를 최적화합니다.",
        },
        {
            title: "포인트 관리의 최적화",
            data: "고객의 포인트를 효율적으로 관리하며, 업소에서의 사용 가능 여부를 한눈에 확인할 수 있습니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>차량 관리, 이제는 네오북에 맡기세요!</h2>
                            <p className="pt-4 product-sub-descr1">
                                차량의 주요 정보와 예방정비 항목까지 한번에!{" "}
                                <span className="pt-3 neobook-color">
                                    당신의 차량 관리를 위한 해결사, 네오북
                                </span>
                                입니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/bg01_neobook.png"
                                alt="네오북"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/ic_neobook01.png" alt="" />
                                                    </li>
                                                    <li>고객 상담의 간소화</li>
                                                    <li className="neobook">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        차량 수리 이력과 오일 교환 주기 정보를 제공함으로써 고객 상담 프로세스를 간소화합니다. 더 이상 긴 시간을 들여 차량 정보를 확인할 필요가 없습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/ic_neobook02.png" alt="" />
                                                </li>
                                                <li>효율적인 커뮤니케이션</li>
                                                <li className="neobook">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    모든 수리 작업과 예방 항목을 갱신하는 데 단 한 번의 문자만 필요합니다. 이는 통신 비용을 절감하고, 업무 효율성을 높이는 동시에 고객 만족도를 증가시킵니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/ic_neobook03.png" alt="" />
                                                </li>
                                                <li>편리한 차량 정보 조회</li>
                                                <li className="neobook">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    단일 차량 뿐만 아니라 여러 차량을 보유한 고객의 경우 각 차량에 대한 정보를 한눈에 조회할 수 있습니다. 이는 다차종 관리의 복잡성을 줄이고, 고객 서비스의 질을 향상시킵니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper neobook">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    차량 및 고객 관리의 완벽한 파트너 네오북!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    네오북은 차량 및 고객 관리의 완벽한 파트너로서,
                                </span>
                                <br />
                                <span className="font-weight-bold">고객별 맞춤 관리</span>를 통해 <span className="font-weight-bold">단골 고객을 늘리는 데 기여</span>합니다.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="neobook-color">네오북</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span
                                    className="product-main-function-descr-ti neobook-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_neobook_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span
                                    className="product-main-function-descr-ti neobook-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-12 col-lg-6 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_neobook_mobile01.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti neobook-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti neobook-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neobook-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-6 product-main-function-img">
                                <img
                                    src="../assets/prod/img_neobook_mobile02.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neobook-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-12 col-lg-6 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_neobook_mobile03.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti neobook-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti neobook-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation06 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neobook-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox06Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-6 product-main-function-img">
                                <img
                                    src="../assets/prod/img_neobook_mobile04.png"
                                    alt={prodInfos[4].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neobook-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod03">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>프리미엄네오</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default NeoBook;
