import VisibilitySensor from 'react-visibility-sensor'

function NeoTab(props) {
    const prodInfos = [
        {
            'title': '차대번호 자동 인식 기능',
            'data': '오타가 잦은 차대번호 역시 자동으로 인식, 정확한 정보를 제공함으로써 완벽한 정비 서비스를 가능하게 해드립니다.'
        },
        {
            'title': '차량번호 자동 인식 기능',
            'data': '핸드폰 카메라로 차량번호를 자동으로 인식, 업무 속도를 크게 향상시킵니다.'
        },
        {
            'title': '차량번호 음성 인식 기능',
            'data': '음성 명령을 통해 차량번호를 간편하게 입력하고 검색할 수 있어, 현장에서의 작업 효율을 대폭 향상시킵니다.'
        },
        {
            'title': '부품 바코드 인식 기능',
            'data': '부품의 신속한 식별과 정확한 재고관리가 가능해집니다. 수작업으로 부품을 입력하고 관리하는 번거로움은 이제 그만~'
        },
        {
            'title': '현장에 특화된 모델',
            'data': '차량번호만으로 과거 수리 이력과 기술자 소견을 즉시 확인할 수 있어, 상담 시간을 단축시키고 서비스의 질을 높입니다.'
        },
        {
            'title': '외부에서의 경영 관리',
            'data': '언제 어디서나 작업 내역, 매출, 매입 등을 관리할 수 있어, 사무실 밖에서도 효율적인 비즈니스 운영이 가능합니다.'
        },
        {
            'title': '국토부 정비이력 전송 기능',
            'data': '국토부 정비 이력의 관리와 접근을 간편하게 만들어, 규정 준수를 쉽게 도와줍니다.'
        }
    ]

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>차세대 자동차 정비업의 혁신적인 파트너</h2>
                            <p className="pt-4 product-sub-descr1">
                                네오 하이웨이와 완벽하게 연동되는 <span className="pt-3 neotab-color">네오탭</span>은 업무 효율성과 고객 관리를 혁신적으로 향상시키는 <span className="pt-3 neotab-color">모바일 솔루션</span>입니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img src="./assets/prod/neotab_bg01.png?v=240122" alt="네오탭" className="client-thumb" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/neotab1.png?v=240122" alt="" />
                                                    </li>
                                                    <li>
                                                        실시간 정보 공유와 연동
                                                    </li>
                                                    <li className="neotab">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        네오 하이웨이 시스템과의 실시간 연동을 통해, 정비 내역의 조회 및 입력이 가능하며, 필요한 경우 단독으로도 사용할 수 있습니다. 이는 업무의 유연성을 보장합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neotab2.png?v=240122" alt="" />
                                                </li>
                                                <li>
                                                    고객 관리의 진화
                                                </li>
                                                <li className="neotab">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    현장에서 바로 사진 촬영과 저장이 가능하고, 수리 이력 조회 및 견적 작성 등 필수적인 점검 항목을 쉽게 체크할 수 있습니다. 이는 서비스의 질을 높이는 데 기여합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neotab3.png?v=240122" alt="" />
                                                </li>
                                                <li>
                                                    폭넓은 업무 지원
                                                </li>
                                                <li className="neotab">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    국토부 정비 이력 전송 등 네오 하이웨이의 주요 기능을 모두 지원하여, 업무의 효율성을 극대화합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper neotab">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">네오탭으로 정비업의 미래를 경험하세요!</span><br />
                                <span className="product-copyright-sub text-white">네오탭은 귀사의 비즈니스 성공을 위한 강력한 파트너입니다.</span><br />
                                <span className="font-weight-bold">고객 만족도를 극대화</span>하여 경쟁에서 앞서 나가세요!<br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="neotab-color">네오탭</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${(props.boxAnimation02 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neotab-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_neotab_m01.png" alt={prodInfos[0].title} />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neotab-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation03 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img src="../assets/prod/img_neotab_m02.png" alt={prodInfos[1].title} />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti neotab-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti neotab-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation04 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neotab-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_neotab_m03.png" alt={prodInfos[2].title} />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neotab-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation05 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img src="../assets/prod/img_neotab_m04.png" alt={prodInfos[3].title} />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti neotab-color">{prodInfos[3].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti neotab-color">{prodInfos[3].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation06 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neotab-color">{prodInfos[4].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox06Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                <img src="../assets/prod/img_neotab_m05.png" alt={prodInfos[4].title} />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neotab-color">{prodInfos[4].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation07 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox07Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                    <img src="../assets/prod/img_neotab_m06.png" alt={prodInfos[5].title} />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti neotab-color">{prodInfos[5].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[5].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti neotab-color">{prodInfos[5].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[5].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${(props.boxAnimation08 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neotab-color">{prodInfos[6].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[6].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox08Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img">
                                <img src="../assets/prod/img_neotab_m07.png" alt={prodInfos[6].title} />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neotab-color">{prodInfos[6].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[6].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod03">
                                <div
                                    className='related-product-box d-flex flex-column justify-content-center align-items-center'
                                >
                                    <i className="fas fa-desktop" />
                                    <p>
                                        프리미엄네오
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default NeoTab