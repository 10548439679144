import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../comm/Header";
import Ges from "./Ges"; //GES 상품소개
import AutoDL from "./AutoDL"; //AutoDL 상품소개
import AutoDL3 from "./AutoDL3"; //오토디엘(전문정비용) 상품소개
import queryString from "query-string";
import Tabs from "./Tabs";
import Tab from "./Tab";

function Prod02({ match, location, history }) {
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const tabIndex = Number(query.tabIndex) || 0;
  const [subTitle, setSubTitle] = useState();

  //탭1 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01, setBoxAnimation01] = useState(null);
  const [boxAnimation02, setBoxAnimation02] = useState(null);
  const [boxAnimation03, setBoxAnimation03] = useState(null);
  const [boxAnimation04, setBoxAnimation04] = useState(null);
  const [boxAnimation05, setBoxAnimation05] = useState(null);

  const onVisibilityBox01Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01("1");
    }
  };

  const onVisibilityBox02Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02("1");
    }
  };

  const onVisibilityBox03Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03("1");
    }
  };

  const onVisibilityBox04Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04("1");
    }
  };

  const onVisibilityBox05Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05("1");
    }
  };
  //탭1 CSS 관련 STATE 및 Function 끝

  //탭2 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_1, setBoxAnimation01_1] = useState(null);
  const [boxAnimation02_1, setBoxAnimation02_1] = useState(null);
  const [boxAnimation03_1, setBoxAnimation03_1] = useState(null);
  const [boxAnimation04_1, setBoxAnimation04_1] = useState(null);
  const [boxAnimation05_1, setBoxAnimation05_1] = useState(null);

  const onVisibilityBox01_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_1("1");
    }
  };

  const onVisibilityBox02_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_1("1");
    }
  };

  const onVisibilityBox03_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_1("1");
    }
  };

  const onVisibilityBox04_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_1("1");
    }
  };

  const onVisibilityBox05_1Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05_1("1");
    }
  };
  //탭2 CSS 관련 STATE 및 Function 끝

  //탭3 CSS 관련 STATE 및 Function 시작
  const [boxAnimation01_2, setBoxAnimation01_2] = useState(null);
  const [boxAnimation02_2, setBoxAnimation02_2] = useState(null);
  const [boxAnimation03_2, setBoxAnimation03_2] = useState(null);
  const [boxAnimation04_2, setBoxAnimation04_2] = useState(null);
  const [boxAnimation05_2, setBoxAnimation05_2] = useState(null);

  const onVisibilityBox01_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation01_2("1");
    }
  };

  const onVisibilityBox02_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation02_2("1");
    }
  };

  const onVisibilityBox03_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation03_2("1");
    }
  };

  const onVisibilityBox04_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation04_2("1");
    }
  };

  const onVisibilityBox05_2Change = (isVisible) => {
    if (isVisible) {
      setBoxAnimation05_2("1");
    }
  };
  //탭3 CSS 관련 STATE 및 Function 끝

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="section prod">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">{subTitle}</h2>
              <p className="text-white pt-3">솔루션 비즈니스 &gt; {subTitle}</p>
            </div>
          </div>
        </div>
      </section>

      <div className="tabs pt-99">
        <Tabs index={tabIndex} setSubTitle={setSubTitle}>
          <Tab label="GES">
            <Ges
              boxAnimation01={boxAnimation01}
              boxAnimation02={boxAnimation02}
              boxAnimation03={boxAnimation03}
              boxAnimation04={boxAnimation04}
              boxAnimation05={boxAnimation05}
              onVisibilityBox01Change={onVisibilityBox01Change}
              onVisibilityBox02Change={onVisibilityBox02Change}
              onVisibilityBox03Change={onVisibilityBox03Change}
              onVisibilityBox04Change={onVisibilityBox04Change}
              onVisibilityBox05Change={onVisibilityBox05Change}
            />
          </Tab>
          <Tab label="오토디엘">
            <AutoDL
              boxAnimation01={boxAnimation01_1}
              boxAnimation02={boxAnimation02_1}
              boxAnimation03={boxAnimation03_1}
              boxAnimation04={boxAnimation04_1}
              boxAnimation05={boxAnimation05_1}
              onVisibilityBox01Change={onVisibilityBox01_1Change}
              onVisibilityBox02Change={onVisibilityBox02_1Change}
              onVisibilityBox03Change={onVisibilityBox03_1Change}
              onVisibilityBox04Change={onVisibilityBox04_1Change}
              onVisibilityBox05Change={onVisibilityBox05_1Change}
            />
          </Tab>
          <Tab label="오토디엘(전문정비용)">
            <AutoDL3
              boxAnimation01={boxAnimation01_2}
              boxAnimation02={boxAnimation02_2}
              boxAnimation03={boxAnimation03_2}
              boxAnimation04={boxAnimation04_2}
              boxAnimation05={boxAnimation05_2}
              onVisibilityBox01Change={onVisibilityBox01_2Change}
              onVisibilityBox02Change={onVisibilityBox02_2Change}
              onVisibilityBox03Change={onVisibilityBox03_2Change}
              onVisibilityBox04Change={onVisibilityBox04_2Change}
              onVisibilityBox05Change={onVisibilityBox05_2Change}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default Prod02;
