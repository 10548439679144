import Header from "../comm/Header";
import Accordion from "../comm/Accordion";
import Paging from "../comm/Paging";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reqData } from "../../js/server";
// import { fnNotLogin } from "../../js/util";

function InfoList() {
  const dispatch = useDispatch();
  const postsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [posts, setPosts] = useState([]);
  const [keyword, setKeyword] = useState("");

  const setPage = (page) => {
    fetchData(page);
  };

  async function fetchData(page = 1) {
    setCurrentPage(Number(page));

    const jsonData = {
      servicename: "INFO",
      keyword: keyword,
      currentPage: page,
      maxListCount: postsPerPage,
    };

    dispatch({ type: "loadingChange", payload: true });
    reqData(jsonData)
      .then((res) => {
        if (res.data.result === "OK") {
          setTotalCount(res.data.totalCnt);
          setPosts(res.data.jsonData);
        } else {
          console.error(res.data.msg);
          setTotalCount(0);
          setPosts([]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: "loadingChange", payload: false });
        }, 500);
      });
  }

  useEffect(
    () => {
      // if (localStorage.getItem('comcode')) {
      fetchData(1);
      // } else {
      //     alert('인트라밴 솔루션 고객만 접근이 가능한 메뉴입니다.')
      //     fnNotLogin()
      // }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="section intro">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">회사소개</h2>
              <p className="text-white pt-3">회사소개 &gt; 공지사항</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row pt-99">
          <div className="col-12">
            <h2>인트라밴의 소식을 알려드립니다.</h2>

            <p className="pt-3">
              신규 서비스 론칭, 서버 점검 스케줄 등 솔루션을 통해 전하지 못한
              소식을 전해 드리겠습니다.
            </p>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-12 text-center">
            <div className="search-bar">
              <div className="input-group">
                <input
                  className="form-control transparent-input"
                  type="text"
                  name="keyword"
                  placeholder="제목 혹은 내용으로 검색이 가능합니다."
                  maxLength="20"
                  value={keyword || ""}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      fetchData(1);
                    }
                  }}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() => fetchData(1)}
                  >
                    <i className="fas fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-12 accd-wrap">
            {posts.length > 0 ? (
              posts.map((post, i) => {
                return (
                  <Accordion
                    key={i}
                    title={post.subject || ""}
                    content={post.contents || ""}
                    wdate={post.wdate ? post.wdate.substring(0, 10) : ""}
                  />
                );
              })
            ) : (
              <div style={{ padding: 20 }}>결과가 없습니다.</div>
            )}
          </div>
        </div>

        <div className="row pt-3 pb-99">
          <div className="col-12">
            <Paging page={currentPage} count={totalCount} setPage={setPage} />
          </div>
        </div>
      </div>
    </>
  );
}

export default InfoList;
