import VisibilitySensor from "react-visibility-sensor";

function Jmt(props) {
    const prodInfos = [
        {
            title: "우리 업소를 소개해 주세요.",
            data: "업소명, 사진, 주소, 약도, SNS 등을 통해 업소를 적극적으로 홍보할 수 있습니다. 귀사의 전문 작업 항목과 브랜드를 고객에게 효과적으로 전달하세요.",
        },
        {
            title: "고객에게 정확한 위치 전송",
            data: "네이버 지도 API를 활용하여 고객이 귀사의 정확한 위치를 쉽게 찾을 수 있도록 합니다. 방문 전부터 시작되는 우수한 고객 서비스를 제공합니다.",
        },
        {
            title: "정비 예약 시스템",
            data: "정비 예약 기능으로 고객은 언제 어디서나 편리하게 정비 예약을 할 수 있습니다. 이는 업소의 예약 관리를 더욱 효율적으로 만들어, 운영의 편리함을 극대화합니다.",
        },
        {
            title: "검색 기능 제공",
            data: "고객이 원하는 서비스나 위치 기반으로 쉽게 정비 업소를 찾을 수 있습니다. 브랜드, 작업 분야별로 세분화된 검색 옵션을 제공하여, 고객의 검색 경험을 향상시키고 귀사의 가시성을 높입니다.",
        },
        {
            title: "프로모션 관리 기능",
            data: "직접 프로모션을 등록하고 관리함으로써, 고객 유치 및 브랜드 인지도를 높일 수 있습니다. 특별 이벤트와 할인 쿠폰 등을 제공하여 고객 방문을 촉진합니다.",
        },
        {
            title: "개별 휴무일 설정",
            data: "업소의 운영 일정에 맞춰 휴무일을 설정할 수 있으며, 고객에게 미리 알림으로써 예약 접수가 불가능한 날짜를 명확히 합니다. 이는 더 나은 고객 서비스와 운영 효율성을 제공합니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>우리 업소도 자동차 정비 맛집이 될 수 있다?</h2>
                            <p className="pt-4 product-sub-descr1">
                                경쟁에서 앞서가고 싶으신가요? <span className="pt-3 jmt-color">고객 유치를 위한 혁신적인 솔루션</span>을 제공합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/bg01_jmt.png"
                                alt="정비맛집"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/ic_jmt_good.png" alt="" />
                                                    </li>
                                                    <li>정비 예약의 간편화</li>
                                                    <li className="jmt">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        몇 번의 클릭만으로 쉽게 정비 예약을 할 수 있습니다. 이를 통해 업소의 예약 관리를 간소화할 수 있습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/ic_jmt_ad.png" alt="" />
                                                </li>
                                                <li>업소 홍보</li>
                                                <li className="jmt">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    귀사만의 전문성과 브랜드를 고객에게 직접 어필할 기회입니다. 고유한 서비스와 특징을 통해 시장에서 돋보일 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/ic_jmt_navi.png" alt="" />
                                                </li>
                                                <li>정확한 길안내</li>
                                                <li className="jmt">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    네비게이션 및 지도 앱을 통해 고객이 귀사의 위치를 정확하게 찾을 수 있도록 도와줍니다. 편리한 접근성으로 고객의 방문을 유도합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper jmt">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    정비 분야의 선두주자가 될 수 있습니다!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    프리미엄 기능을 통한 철저한 고객 관리와 서비스 개선으로,
                                </span>
                                <br />
                                귀사를 차별화된 <span className="font-weight-bold">정비 솔루션의 선두주자</span>로 만들어 드립니다.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="jmt-color">정비맛집</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti jmt-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img text-right d-flex align-items-center">
                                <img
                                    src="../assets/prod/img_jmt_PC01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti jmt-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img d-flex align-items-center">
                                    <img
                                        src="../assets/prod/img_jmt_PC02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti jmt-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti jmt-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti jmt-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img d-flex align-items-center">
                                <img
                                    src="../assets/prod/img_jmt_PC03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti jmt-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox04Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img d-flex align-items-center">
                                    <img
                                        src="../assets/prod/img_jmt_PC04.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti jmt-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti jmt-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[3].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-6 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti jmt-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox05Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img d-flex align-items-center">
                                <img
                                    src="../assets/prod/img_jmt_PC05.png"
                                    alt={prodInfos[4].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti jmt-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[4].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation06 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox06Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-6 col-lg-6 product-main-function-img d-flex align-items-center">
                                    <img
                                        src="../assets/prod/img_jmt_PC06.png"
                                        alt={prodInfos[5].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti jmt-color">
                                        {prodInfos[5].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[5].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-6 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti jmt-color">
                                        {prodInfos[5].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[5].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod01?tabIndex=0">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>오토X</p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod03?tabIndex=0">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>프리미엄네오</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Jmt;
