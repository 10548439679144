import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import "./VinDecoder.css";
import axios from "axios";
import { Helmet } from "react-helmet";

function VinDecoder({ match, location, history }) {
  const contentsRef = useRef();
  const query = queryString.parse(location.search);
  const qVinno = query.vinno || "";
  const qPrgcom = query.prgcom || "";

  const [vinno, setVinno] = useState(qVinno);
  const [carinfo01Array, setCarinfo01Array] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [carinfo02Array, setCarinfo02Array] = useState([]);
  const [carinfo01, setCarinfo01] = useState({});
  const [carinfo02, setCarinfo02] = useState({});
  const [saleprice, setSaleprice] = useState("");
  const [modelnm, setModelnm] = useState("");

  const dispatch = useDispatch();

  const fetchData = async () => {
    if (qPrgcom === "") {
      alert("프로그램코드가 없습니다.");
      return;
    }

    if (vinno.length < 17) {
      alert("차대번호 17자리를 입력해 주세요.");
      return;
    }

    dispatch({ type: "loadingChange", payload: true });

    setCarinfo01Array([]);
    setCarinfo02Array([]);
    setCarinfo01({});
    setCarinfo02({});
    setSaleprice("");
    setModelnm("");

    const res = await axios.post(
      " https://adbcp.intravan.co.kr/apiVinOptComm.aspx?prgcom=" +
        qPrgcom +
        "&vinno=" +
        vinno
    );

    if (res.data.result === "ok") {
      console.log(res.data);
      setCarinfo01Array(res.data.dataset);
      setCarinfo02Array(res.data.dataset_ext);
      setSaleprice(res.data.saleprice);
      setModelnm(res.data.modelnm);

      if (res.data.dataset.length > 0) {
        setCarinfo01(res.data.dataset[0]);
        const tmpSeqno = res.data.dataset[0].seqno;
        const tmpArray = res.data.dataset_ext;
        const tmpCarinfo02 = tmpArray.filter((d) => d.seqno === tmpSeqno);

        if (tmpCarinfo02.length > 0) {
          setCarinfo02(tmpCarinfo02[0]);
        }

        console.log(tmpCarinfo02[0]);

        contentsRef.current?.scrollIntoView({ behavior: "smooth" });
      } else {
        alert(
          "현대, 기아, 삼성, 쌍용, 쉐보레 2000년 이후 생산된\n승용, RV, 소형승합, 소형화물에 대한 데이터만 제공됩니다.\n\n차대번호를 확인해 주세요."
        );
      }
    } else {
      alert(res.data.msg);
    }

    setTimeout(() => {
      dispatch({ type: "loadingChange", payload: false });
    }, 500);
  };

  const changeOpt = (value) => {
    dispatch({ type: "loadingChange", payload: true });

    const tmpCarinfo01 = carinfo01Array.filter((d) => d.seqno === value);
    const tmpCarinfo02 = carinfo02Array.filter((d) => d.seqno === value);

    if (tmpCarinfo01.length > 0) setCarinfo01(tmpCarinfo01[0]);
    if (tmpCarinfo02.length > 0) setCarinfo02(tmpCarinfo02[0]);

    setTimeout(() => {
      dispatch({ type: "loadingChange", payload: false });
    }, 500);
  };

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      //   console.log(carinfo01Array.length);
      //   console.log(carinfo02Array.length);
      //   console.log(saleprice);
      //   console.log(modelnm);

      if (vinno.length === 17) {
        fetchData();
      }

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Helmet>
        <title>차량식별 및 규격정보 조회</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/bi_jmt192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/bi_jmt32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/bi_jmt16x16.png"
        />
        <link rel="apple-touch-icon" href="/bi_jmt192x192.png" />

        <meta
          name="차량식별 및 규격정보 조회"
          content="초간단 차량 정보 조회 서비스"
        />
        <meta name="description" content="" />
        <meta property="og:title" content="차량식별 및 규격정보 조회" />
        <meta property="og:description" content="차량식별 및 규격정보 조회" />
      </Helmet>

      <div className="vindecoder">
        <div className="container-vindecoder pt-7">
          <div className="container-vindecoder-inner bimg">
            <p className="vindecoder-title-descr01">
              초간단 차량 정보 조회 서비스
            </p>
            <p className="vindecoder-title-descr02 font-blue pt-2">
              차량식별 및 규격정보 조회
            </p>

            <div className="container">
              <div className="row mt-6">
                <div className="col-12 vin-search-box">
                  <div className="input-group">
                    <input
                      className="form-control transparent-input vin-text-input"
                      type="text"
                      name="keyword"
                      placeholder="차대번호"
                      maxLength="17"
                      value={vinno || ""}
                      onChange={(e) => setVinno(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          fetchData();
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={() => fetchData()}
                      >
                        <i className="fas fa-search font-blue" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-4">
                <ul className="vin-descr-release">
                  <li>
                    ■ 실제 정비 또는 부품구매시에는 해당차량의 메뉴얼 및
                    정비지침서를 꼭 확인하시기 바랍니다.
                  </li>
                  <li>
                    ■ 국산차는 현대, 기아, 삼성, 쌍용, 쉐보레 2000년 이후 생산된
                    승용, RV, 소형승합, 소형화물에 대한 데이터만 제공됩니다.
                  </li>
                  <li>■ 수입차에 대한 규격정보도 제공됩니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container-vindecoder pt-5 pb-5" ref={contentsRef}>
          <div className="container-vindecoder-inner">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center vindecoder-title-descr03">
                  입력 차대번호 : {vinno || ""}
                </div>
              </div>

              {modelnm ? (
                <>
                  <div className="row pt-5">
                    <div className="col-12">
                      <span className="vin-badge">차량출고정보</span>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-6 vin-descr-text">모델명</div>
                    <div className="col-6 vin-descr-text text-right">
                      {modelnm || ""}
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-6 vin-descr-text">출고가</div>
                    <div className="col-6 vin-descr-text text-right">
                      {saleprice || ""}원
                    </div>
                  </div>
                  <hr className="m-5" />
                </>
              ) : null}

              <div className="row pt-5">
                <div className="col-9">
                  <span className="vin-badge">차량식별정보</span>
                </div>
                {carinfo01Array.length > 0 ? (
                  <select
                    className="col-3 form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      changeOpt(e.target.value);
                    }}
                  >
                    {carinfo01Array.map((carinfo, i) => {
                      return (
                        <option key={i} value={carinfo.seqno}>
                          {carinfo.seqno}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
              </div>

              <div className="row pt-5">
                <div></div>
              </div>

              {carinfo01.carname ? (
                <div className="row">
                  <div className="col-6 vin-descr-text">차량명</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo01.carname || ""}
                  </div>
                </div>
              ) : null}

              {carinfo01.caryear ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">연식</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo01.caryear || ""}
                  </div>
                </div>
              ) : null}

              {carinfo01.opt1 ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">바디</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo01.opt1 || ""}
                  </div>
                </div>
              ) : null}

              {carinfo01.opt2 ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">엔진</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo01.opt2 || ""}
                  </div>
                </div>
              ) : null}

              {carinfo01.opt3 ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">연료분사</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo01.opt3 || ""}
                  </div>
                </div>
              ) : null}

              {carinfo01.opt4 ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">연료</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo01.opt4 || ""}
                  </div>
                </div>
              ) : null}

              {carinfo01.opt5 ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">변속기</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo01.opt5 || ""}
                  </div>
                </div>
              ) : null}

              <hr className="m-5" />

              <div className="row pt-5">
                <div className="col-12">
                  <span className="vin-badge">차량규격정보</span>
                </div>
              </div>

              <div className="row pt-5">
                <div></div>
              </div>

              {carinfo02.tire_size ? (
                <div className="row">
                  <div className="col-6 vin-descr-text">타이어(mm)</div>
                  <div
                    className="col-6 vin-descr-text text-right"
                    dangerouslySetInnerHTML={{
                      __html: carinfo02.tire_size
                        ? carinfo02.tire_size.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br />"
                          )
                        : "",
                    }}
                  ></div>
                </div>
              ) : null}

              {carinfo02.battery ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">배터리</div>
                  <div
                    className="col-6 vin-descr-text text-right"
                    dangerouslySetInnerHTML={{
                      __html: carinfo02.battery
                        ? carinfo02.battery.replace(/(?:\r\n|\r|\n)/g, "<br />")
                        : "",
                    }}
                  ></div>
                </div>
              ) : null}

              {carinfo02.wiper ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">와이퍼(mm)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.wiper || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.engine_oil ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">엔진오일(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.engine_oil || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.viscosity ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">엔진오일 점도</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.viscosity || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.standardSpec ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">엔진오일 규격</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.standardSpec || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.mission_oil ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">미션오일(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.mission_oil || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.cooling_water ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">냉각수(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.cooling_water || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.diesel_water ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">요소수(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.diesel_water || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.aircon_gas1 ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">에어컨가스 R134A</div>
                  <div
                    className="col-6 vin-descr-text text-right"
                    dangerouslySetInnerHTML={{
                      __html: carinfo02.aircon_gas1
                        ? carinfo02.aircon_gas1.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br />"
                          )
                        : "",
                    }}
                  ></div>
                </div>
              ) : null}

              {carinfo02.aircon_gas2 ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">에어컨가스 R1234YF</div>
                  <div
                    className="col-6 vin-descr-text text-right"
                    dangerouslySetInnerHTML={{
                      __html: carinfo02.aircon_gas2
                        ? carinfo02.aircon_gas2.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br />"
                          )
                        : "",
                    }}
                  ></div>
                </div>
              ) : null}

              {carinfo02.fuel_tank ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">연료탱크(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.fuel_tank || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.break_oil ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">브레이크오일(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.break_oil || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.steering_oil ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">
                    파워스티어링오일(L)
                  </div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.steering_oil || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.inv_cooling_water ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">인버터냉각수(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.inv_cooling_water || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.front_diff ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">프런트디퍼런셜(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.front_diff || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.rear_diff ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">리어디퍼런셜(L)</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.rear_diff || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.trans_oil ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">
                    트랜스퍼케이스오일(L)
                  </div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.trans_oil || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.refrigerant_oil ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">냉매(냉동)오일량</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.refrigerant_oil || ""}
                  </div>
                </div>
              ) : null}

              {carinfo02.refrigerant_oil_kind ? (
                <div className="row pt-3">
                  <div className="col-6 vin-descr-text">냉매(냉동)오일종류</div>
                  <div className="col-6 vin-descr-text text-right">
                    {carinfo02.refrigerant_oil_kind || ""}
                  </div>
                </div>
              ) : null}

              <hr className="m-5" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VinDecoder;
