import { Link } from "react-router-dom";

function modalPopup(props) {
    return (
        <div className="modal show d-block ">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">공지사항</h5>
                        <button
                            type="button"
                            className="close"
                            onClick={props.handleCloseModal}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div>
                                    <Link to={`/Prod01?tabIndex=3`}>
                                        <img
                                            src="../assets/popup/info240627.jpg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={props.handleCloseModal}
                        >
                            닫기
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={props.handleHideForToday}
                        >
                            오늘 하루 이창 열지 않기
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default modalPopup;
