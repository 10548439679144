import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

function TrsSystem(props) {
    const prodInfos = [
        {
            title: "고객 주도 예약 시스템",
            data: "모바일과 PC를 통해 고객이 직접 검사 및 일반 정비의 예약을 진행할 수 있으며, 원하는 날짜와 시간을 쉽게 선택할 수 있습니다.",
        },
        {
            title: "예약 한번으로 평생 내고객",
            data: "첫 예약 시 고객 차량의 검사 만료일이 자동으로 시스템에 등록되어, 차후 검사까지 지속적으로 관리됩니다.",
        },
        {
            title: "간편한 예약이력 관리",
            data: "예약이 확정되거나 정비 전날 자동으로 고객에게 알림 문자를 발송합니다. 또한, 오토X와의 연동을 통해 매출 등록도 자동으로 이루어집니다.",
        },
        {
            title: "검사 및 일반 정비",
            data: "검사 업무 뿐만 아니라, 불합격된 차량에 대한 일반 정비 작업도 시스템을 통해 쉽게 입력할 수 있습니다.",
        },
        {
            title: "손쉬운 데이터 입력",
            data: "빔스에 입력된 데이터를 엑셀로 쉽게 내려받아, 접수 내역 및 차기 검사일 등을 자동으로 관리할 수 있습니다.",
        },
        {
            title: "한눈에 보는 결산관리",
            data: "모든 결산 관리를 한눈에 볼 수 있으며, 매출, 매입, 수입비용, 결제 현황 등을 날짜 및 차량별로 분석할 수 있습니다.",
        },
        {
            title: "고객관리를 위한 데이터 제공",
            data: "원부조회 기능을 통해 고객 차량 정보를 정확하고 쉽게 입력할 수 있으며, 검사 만료일을 자동으로 갱신하여 차기 검사일 관리를 가능하게 합니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>검사/정비 예약부터 매출등록 및 사후관리까지 한번에!</h2>
                            <p className="pt-4 product-sub-descr1">
                                비대면을 넘어서 전화통화도 필요 없는{" "}
                                <span className="pt-3 trs-system-color">검사관리시스템</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/car_inspection_bg01.png"
                                alt="검사관리시스템"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img
                                                            src="../assets/prod/ico_car_inspection01.png"
                                                            alt=""
                                                        />
                                                    </li>
                                                    <li>업무의 간편화</li>
                                                    <li className="trs-system">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        고객 스스로가 예약을 진행하고, 이를 통해 바로 예약 일정을 확인할 수 있는 사용자 친화적인 시스템을 도입하여 업무 프로세스를 단순화합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img
                                                        src="../assets/prod/ico_car_inspection02.png"
                                                        alt=""
                                                    />
                                                </li>
                                                <li>자동화된 솔루션</li>
                                                <li className="trs-system">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    예약 취소부터 예약 전날 안내 문자의 자동 발송, 검사 완료 후 차량의 차기 만료일 관리까지, 모든 과정을 자동화하여 운영의 효율을 극대화합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img
                                                        src="../assets/prod/ico_car_inspection03.png"
                                                        alt=""
                                                    />
                                                </li>
                                                <li>고객 만족도의 증대</li>
                                                <li className="trs-system">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    작업 여건에 따라 시간대별 예약 가능 수를 조정하여, 대기 시간을 줄이고 서비스 회전율을 높임으로써 고객 만족도를 상승시킵니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div
                            className="col-12 text-center product-main-function-ti"
                            style={{ fontWeight: "bold" }}
                        >
                            <span className="trs-system-color">검사관리시스템</span>
                            <span>의 차별화된 기술력</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-trs">
                                <p>
                                    <span>Everywhere</span>
                                    <br />
                                    <br />
                                    <span>사무실, 현장, 출장지, 해외 어디서든 접속 가능</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-trs">
                                <p>
                                    <span>Safe data</span>
                                    <br />
                                    <br />
                                    <span>
                                        최적의 데이터 보호
                                        <br />
                                        시스템 구축
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-trs">
                                <p>
                                    <span>Any device</span>
                                    <br />
                                    <br />
                                    <span>PC, 모바일, 테블릿 등 기기 호환성 지원</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-trs">
                                <p>
                                    <span>Strong security</span>
                                    <br />
                                    <br />
                                    <span>
                                        랜섬웨어 등의 다양한 바이러스 공격을 철저하게 방어
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-trs">
                                <p>
                                    <span>Data service</span>
                                    <br />
                                    <br />
                                    <span>
                                        차량식별정보와
                                        <br />
                                        규격정보 제공
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2-trs">
                                <p>
                                    <span>Special Benefits</span>
                                    <br />
                                    <br />
                                    <span>
                                        원부조회 기능 제공
                                        <br />
                                        홍보 플랫폼 정비맛집 등록
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="row">
                            <div className="col-12">&nbsp;</div>
                        </div>
                        <div className={`col-12`}>
                            <img
                                src="../assets/prod/img_car_inspection_proc.png"
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="row">
                            <div className="col-12">&nbsp;</div>
                        </div>

                        <div className={`col-12`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 product-why-wrapper">
                                        <div className="text-center product-main-function-ti">
                                            <span>Why?</span>&nbsp;
                                            <span className="trs-system-color">검사관리시스템</span>
                                        </div>

                                        <div className="col-12">
                                            <div className="container">
                                                <div className="row pt-5">
                                                    <div className="col-12 col-sm-12 col-lg-3 col-md-3 product-why-peo">
                                                        <img
                                                            src="../assets/prod/ic_car_inspection_customer.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p className="accent-title">
                                                            검사안내 문자 등 체계적인
                                                            <br />
                                                            고객관리를 할 수 있습니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-9 col-md-9 product-why-peo text-left side-div-line">
                                                        <p>
                                                            최초 방문 고객의 재방문을 이끌어 낼 수 있도록
                                                            체계적인 차량관리를 위해 차기검사일을 안내하고
                                                            시기에 맞게 고객에게 알려줄 수 있는 서비스가
                                                            필수인 시대입니다.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row pt-5">
                                                    <div className="col-12 col-sm-12 col-lg-3 col-md-3 product-why-peo">
                                                        <img
                                                            src="../assets/prod/ic_car_inspection_all.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p className="accent-title">
                                                            검사뿐만 아니라
                                                            <br />
                                                            일반 정비입력도 가능합니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-9 col-md-9 product-why-peo text-left side-div-line">
                                                        <p>
                                                            검사업무를 포함한 일반 정비 업무도 가능한
                                                            프로그램입니다.
                                                            <br />
                                                            아직도 일반 로드샵에서는 차량에 대한 제원 및 식별
                                                            정보를 구하기가 쉽지 않습니다.
                                                            <br />
                                                            <br />위 정보는 검사관리시스템에서 모두 제공되니
                                                            다른 솔루션이 필요가 없습니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper trs-system">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    검사관리시스템, 업무 효율성의 새 지평을 엽니다.
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    재방문율 증가와 고객 편의를 극대화하여,
                                </span>
                                <br />
                                비즈니스 운영을 <span className="font-weight-bold">혁신적으로 개선</span>
                                시킵니다.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="trs-system-color">검사관리시스템</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti trs-system-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_car_inspection01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti trs-system-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_car_inspection02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti trs-system-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[1].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti trs-system-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[1].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti trs-system-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[2].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_car_inspection03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti trs-system-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[2].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_car_inspection04.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti trs-system-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[3].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti trs-system-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[3].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation06 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti trs-system-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[4].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox06Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_car_inspection05.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti trs-system-color">
                                    {prodInfos[4].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[4].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation07 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox07Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_car_inspection06.png"
                                        alt={prodInfos[5].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti trs-system-color">
                                        {prodInfos[5].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[5].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti trs-system-color">
                                        {prodInfos[5].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[5].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${props.boxAnimation08 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti trs-system-color">
                                    {prodInfos[6].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[6].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox08Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_car_inspection07.png"
                                    alt={prodInfos[6].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti trs-system-color">
                                    {prodInfos[6].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[6].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod05?tabIndex=0">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-mobile-alt" />
                                    <p>오토X 모바일</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=1">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-car" />
                                    <p>원부조회</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <Link to="/Prod06?tabIndex=2">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-comment-slash" />
                                    <p>080수신거부</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default TrsSystem;
