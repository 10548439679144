import VisibilitySensor from "react-visibility-sensor";

function NeoScan(props) {
    const prodInfos = [
        {
            title: "입력없이 스캔으로!",
            data: "일일이 타이핑으로 입력하던 매입일지를 스캔 한 번으로 완료할 수 있습니다. 이는 업무의 효율성을 대폭 향상시킵니다.",
        },
        {
            title: "품번인식도 척척!",
            data: "재고 관리하는 부품의 품번을 인식하여, 바로 재고에 반영합니다. 이 기능은 재고 관리의 정확성과 편의성을 제공합니다.",
        },
        {
            title: "스캔부터 작성까지 편안하게!",
            data: "수십 장의 매입 전표를 일괄 불러오기 기능으로 한 번에 매입 작성이 가능합니다. 이는 대량의 매입 처리 작업을 간소화합니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>"네오스캔" 매입전표 처리의 혁신</h2>
                            <p className="pt-4 product-sub-descr1">
                                매입전표 입력 작업을 간소화하는
                                <span className="pt-3 neoscan-color">
                                    OCR과 AI 기반의 스마트 서비스
                                </span>로, 업무 효율성을 극대화합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/neoscan_bg01.png"
                                alt="네오스캔"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/neoscan1.png" alt="" />
                                                    </li>
                                                    <li>OCR 기반 자동 입력</li>
                                                    <li className="neoscan">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        매입전표를 촬영하여 OCR 인식을 통해 자동으로 입력합니다. 이 과정을 통해 작업 시간을 대폭 절약하고 입력 오류를 줄일 수 있습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neoscan2.png" alt="" />
                                                </li>
                                                <li>부품코드 인식</li>
                                                <li className="neoscan">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    전표의 품번까지 인식하여, 빠르고 정확한 부품 관리를 가능하게 합니다. 시간 절약과 오류 감소는 물론, 재고 관리의 정확성을 향상시킵니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neoscan3.png" alt="" />
                                                </li>
                                                <li>확장성과 유연성</li>
                                                <li className="neoscan">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    다양한 브랜드의 매입전표를 지원하며, 사용자의 필요에 따라 새로운 양식 추가가 가능합니다. 이로 인해 다양한 업무 환경에 유연하게 적용됩니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="neoscan-color">네오스캔</span>
                            <span>의 차별화된 기술력</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="neoscan-color">독보적인 AI 기술</span>
                                    <br />
                                    <br />
                                    <span>
                                        각 매입전표 양식을
                                        <br />
                                        AI가 분석하여 정확하게
                                        <br />
                                        데이터를 파싱하고 입력
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="neoscan-color">경쟁사 대비 우위</span>
                                    <br />
                                    <br />
                                    <span>
                                        유사한 기능을 제공하는
                                        <br />
                                        경쟁 솔루션이 없어
                                        <br />
                                        시장에서 독보적인 위치 선점
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12 text-center pt-5">
                            <div className="product-descr2">
                                <p>
                                    <span className="neoscan-color">간편하고 직관적인 UI</span>
                                    <br />
                                    <br />
                                    <span>
                                        로그인 후 간단한 촬영과
                                        <br />
                                        업로드로 매입전표 처리 가능
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox05Change}
                            delayedCall
                        >
                            <div className="row">
                                <div className="col-12">&nbsp;</div>
                            </div>
                        </VisibilitySensor>

                        <div
                            className={`col-12 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeIn"
                                : ""
                                }`}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 product-why-wrapper">
                                        <div className="text-center product-main-function-ti">
                                            <span>Why?</span>&nbsp;
                                            <span className="neoscan-color">NeoScan</span>
                                        </div>

                                        <div className="col-12">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/icon04_neoscan.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            매입전표 입력 작업의 시간과 노력을 대폭 줄여주어,
                                                            카센터 및 정비공장의 업무 효율성을 극대화합니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/icon05_neoscan.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            AI 기술을 통한 정확한 데이터 파싱으로 매입전표
                                                            처리의 정확성을 높입니다.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-4 col-md-4 pt-5 product-why">
                                                        <img
                                                            src="../assets/prod/icon06_neoscan.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>
                                                            사용자 친화적인 인터페이스와 간편한 처리 과정으로
                                                            누구나 쉽게 사용할 수 있습니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper honors">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    업무 효율을 혁신하는 네오스캔!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    (주)인트라밴이 선사하는 차세대 OCR 및 AI 기술로 매입전표
                                    처리의
                                </span>
                                <br />
                                <span className="font-weight-bold">
                                    신세계를 경험해 보세요!
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="neoscan-color">네오스캔</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neoscan-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_neoscan_mobile01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neoscan-color">
                                    {prodInfos[0].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/img_neoscan_mobile02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti neoscan-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti neoscan-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neoscan-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/img_neoscan_mobile03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neoscan-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="related-product">
                <div className="container">
                    <div className="row pt-99">
                        <div className="col-12 text-center related-product-title">
                            함께 사용하면 좋은 제품
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 col-md-4 col-sm-2 pt-3">
                            <a href="/Prod01?tabIndex=3">
                                <div className="related-product-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="fas fa-desktop" />
                                    <p>아너스하이웨이</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default NeoScan;
