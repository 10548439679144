import VisibilitySensor from 'react-visibility-sensor'

function NeoChain(props) {
    const prodInfos = [
        {
            'title': '통합 경영 관리',
            'data': '본사에서는 고객 정보, 차량 데이터, 지점별 성과를 포함한 다양한 업무를 한눈에 파악하며, 경영의 미세한 조정도 놓치지 않고 실행할 수 있습니다.'
        },
        {
            'title': '물류 및 통계의 세밀한 관리',
            'data': '매출입 통계와 소비 패턴 분석을 비롯해 물류 관리자 기능을 통해 부품 및 재고 상황을 체계적으로 관리하며, 필요시 신속한 조치를 취할 수 있습니다.'
        },
        {
            'title': '지점 간 유연한 연동',
            'data': '지점에서도 네오하이웨이의 전문 정비 프로그램 기능을 활용하여, 본사와 실시간으로 정보를 공유하고, 각 지점의 운영 효율성을 극대화합니다.'
        }
    ]

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>본사와 지점 간 효율적인 통합 관리! 지금 시작하세요</h2>
                            <p className="pt-4 product-sub-descr1">
                                본사와 여러 지점 간의 효율적인 업무와 업무 최적화를 도와주는 <span className="pt-3 neochain-color">체인점용 네오하이웨이</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img src="./assets/prod/neochain_bg01.png" alt="네오체인점" className="client-thumb" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/neochain1.png" alt="" />
                                                    </li>
                                                    <li>
                                                        본사 경영 통찰력 강화
                                                    </li>
                                                    <li className="neochain">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        매출과 재고, 수·발주는 물론 체인점 운영 및 물류까지 모든 측면에서 본사의 경영을 철저히 모니터링하고 관리할 수 있는 종합적인 기능을 제공합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neochain2.png" alt="" />
                                                </li>
                                                <li>
                                                    일관된 고객 서비스 제공
                                                </li>
                                                <li className="neochain">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    체인점 간 정보 공유를 통해 고객이 어떤 지점을 방문하더라도 일관된 서비스를 제공받을 수 있습니다.<br />이는 고객 만족도를 높이고 브랜드 신뢰성을 강화합니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/neochain3.png" alt="" />
                                                </li>
                                                <li>
                                                    심층적인 경영 분석
                                                </li>
                                                <li className="neochain">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    실시간으로 접근 가능한 경영 정보와 자금 흐름, 매출 동향 등을 분석할 수 있는 고급 분석 기능을 통해 전략적 의사결정을 지원합니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-9">
                        <div
                            className={`col-12 text-center opacity-0 ${(props.boxAnimation01 === '1') ? 'animated animatedFadeInUp fadeIn' : ''}`}
                        >
                            <img src="../assets/prod/img_neochain_map.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper neochain">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">본사와 지점의 원활한 협력, 네오하이웨이 체인점!</span><br />
                                <span className="product-copyright-sub text-white">체인점 운영의 미래를 지금 바로 경험해 보세요.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="neochain-color">네오하이웨이 체인점</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${(props.boxAnimation02 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neochain-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_neochain_pc01.png" alt={prodInfos[0].title} className="client-thumb" />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neochain-color">{prodInfos[0].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[0].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${(props.boxAnimation03 === '1') ? 'animated animatedFadeInUp fadeInRight' : ''}`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img src="../assets/prod/img_neochain_pc02.png" alt={prodInfos[1].title} className="client-thumb" />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti neochain-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti neochain-color">{prodInfos[1].title}</span><br />
                                    <span className="product-copyright-sub text-left">
                                        {prodInfos[1].data}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 opacity-0 ${(props.boxAnimation04 === '1') ? 'animated animatedFadeInUp fadeInLeft' : ''}`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti neochain-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img src="../assets/prod/img_neochain_pc03.png" alt={prodInfos[2].title} className="client-thumb" />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti neochain-color">{prodInfos[2].title}</span><br />
                                <span className="product-copyright-sub text-left">
                                    {prodInfos[2].data}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default NeoChain