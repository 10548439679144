import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../comm/Header";

function Page404() {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: false });
      dispatch({ type: "pageChange", payload: "page404" });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <section className="appoinment section">
      <Header page="main" />

      <div className="container">
        <div className="row pt-99 pb-99">
          <div className="col-lg-12 text-center">
            <div className="mt-3">
              <div className="feature-icon mb-3">
                <i className="icofont-support text-lg"></i>
              </div>
              <span className="warning-icon">
                <i className="fas fa-exclamation-circle" />
              </span>
              <br />
              <br />
              <span className="h3">404 Error (페이지가 없습니다.)</span>
              <br />
              <br />
              <span className="h5">
                같은 증상이 반복되는 경우 고객센터로 연락주세요
              </span>
              <br />
              <br />
              <hr />
              <br />
            </div>

            <Link className="btn btn-main btn-round-full" to="/">
              <i className="fas fa-home" />
              &nbsp; 홈으로 이동
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Page404;
