import VisibilitySensor from "react-visibility-sensor";

function AutoDL3(props) {
    const prodInfos = [
        {
            title: "실수 방지 및 자동화된 작업 입력",
            data: "예를 들면 힌지, 가니시 등 작업 항목 선택 시 관련 액세서리 도장 항목이 자동으로 입력됩니다. 이는 작업의 누락을 방지하고 작업자의 편의성을 극대화합니다.",
        },
        {
            title: "세트 작업 견적의 투명성",
            data: "현대, 기아 차량에 대한 세트 작업을 제공하여, 고객에게 신뢰를 구축하고, 정확한 수리비 견적을 제공합니다.",
        },
        {
            title: "원활한 보험 청구 절차",
            data: "사고 차량의 견적 작성과 동시에 보험사에 바로 청구할 수 있는 기능을 통해, 처리 과정을 간편하게 만듭니다.",
        },
        {
            title: "국토부 정비이력 쉬운 전송",
            data: "사용자가 이해하기 쉬운 방식으로 국토부 정비 이력 전송을 단순화하여, 법적 요구사항을 쉽게 충족시킬 수 있습니다.",
        },
    ];

    return (
        <div className="animated2 animatedFadeInUp fadeIn2">
            <section className="product-sub-title">
                <div className="container">
                    <div className="row pt-9">
                        <div className="col-12">
                            <h2>전문 정비업도 보험 견적 관리가 가능합니다!</h2>
                            <p className="pt-4 product-sub-descr1">
                                견적 작성 자동 어시스트 프로그램{" "}
                                <span className="pt-3 adl3-color">
                                    Auto Deep Learning 오토디엘(전문정비업용)
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-sub-photo">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12">
                            <img
                                src="./assets/prod/adl_bg03.png"
                                alt="AutoDL3"
                                className="client-thumb"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0 col-lg-12">
                                    <div
                                        className={`row opacity-0 ${props.boxAnimation01 === "1"
                                            ? "animated animatedFadeInUp fadeIn"
                                            : ""
                                            }`}
                                    >
                                        <VisibilitySensor
                                            scrollCheck={true}
                                            onChange={props.onVisibilityBox01Change}
                                            delayedCall
                                        >
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <ul className="prod-info">
                                                    <li>
                                                        <img src="../assets/prod/autodl3-1.png" alt="" />
                                                    </li>
                                                    <li>정확한 견적 기준 제공</li>
                                                    <li className="adl3">
                                                        <div></div>
                                                    </li>
                                                    <li>
                                                        2018년 공표수가 완전 탑재로, 특정 공임 항목에 대한 명확한 작업 범위를 제시합니다. 이는 견적의 정밀도를 높이며, 고객에게 투명한 서비스를 약속합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </VisibilitySensor>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autodl3-2.png" alt="" />
                                                </li>
                                                <li>직관적 작업 환경</li>
                                                <li className="adl3">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    작업 항목이 그림과 함께 직관적으로 표현되어, 클릭 한 번으로 필요한 작업 내용을 불러올 수 있습니다. 이는 견적 작성 과정을 대폭 간소화시킵니다.
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <ul className="prod-info">
                                                <li>
                                                    <img src="../assets/prod/autodl3-3.png" alt="" />
                                                </li>
                                                <li>정교한 작업 최적화</li>
                                                <li className="adl3">
                                                    <div></div>
                                                </li>
                                                <li>
                                                    자동견적 및 견적 점검 기능을 통해 실수 없는 작업이 가능해집니다. 이로써 누락되는 작업 없이 효율적으로 업무를 진행할 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-copyright-wrapper autodl3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-copyright">
                            <p>
                                <span className="product-copyright-ti">
                                    오토디엘과 함께라면 견적 관리도 한층 업그레이드!
                                </span>
                                <br />
                                <span className="product-copyright-sub text-white">
                                    아직도 망설이시나요?
                                </span>
                                <br />
                                <span className="font-weight-bold">낭비 없는 견적 관리!</span>
                                지금 시작할 때입니다.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-main-function">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center product-main-function-ti">
                            <span className="adl3-color">오토디엘(전문정비용)</span>
                            <span>의 주요기능</span>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-9 pb-99 opacity-0 ${props.boxAnimation02 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span
                                    className="product-main-function-descr-ti adl3-color"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-12 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/adl2_pc01.png"
                                    alt={prodInfos[0].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].title || null,
                                    }}
                                    className="product-main-function-descr-ti adl-color"
                                ></span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[0].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation03 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox03Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/adl2_pc02.png"
                                        alt={prodInfos[1].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti adl3-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[1].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti adl3-color">
                                        {prodInfos[1].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[1].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation04 === "1"
                            ? "animated animatedFadeInUp fadeInLeft"
                            : ""
                            }`}
                    >
                        <div className="col-4 product-main-function-descr">
                            <p>
                                <span className="product-main-function-descr-ti adl3-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[2].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>

                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={props.onVisibilityBox04Change}
                            delayedCall
                        >
                            <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                <img
                                    src="../assets/prod/adl2_pc03.png"
                                    alt={prodInfos[2].title}
                                    className="client-thumb"
                                />
                            </div>
                        </VisibilitySensor>

                        <div className="col-12 pt-5 product-main-function-descr-sm">
                            <p>
                                <span className="product-main-function-descr-ti adl3-color">
                                    {prodInfos[2].title}
                                </span>
                                <br />
                                <span
                                    className="product-copyright-sub text-left"
                                    dangerouslySetInnerHTML={{
                                        __html: prodInfos[2].data || null,
                                    }}
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray">
                    <div className="container">
                        <div
                            className={`row pt-99 pb-99 opacity-0 ${props.boxAnimation05 === "1"
                                ? "animated animatedFadeInUp fadeInRight"
                                : ""
                                }`}
                        >
                            <VisibilitySensor
                                scrollCheck={true}
                                onChange={props.onVisibilityBox05Change}
                                delayedCall
                            >
                                <div className="col-sm-12 col-md-8 col-lg-8 product-main-function-img">
                                    <img
                                        src="../assets/prod/adl2_pc04.png"
                                        alt={prodInfos[3].title}
                                        className="client-thumb"
                                    />
                                </div>
                            </VisibilitySensor>

                            <div className="col-12 pt-5 product-main-function-descr-sm">
                                <p>
                                    <span className="product-main-function-descr-ti adl3-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[3].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>

                            <div className="col-4 product-main-function-descr">
                                <p>
                                    <span className="product-main-function-descr-ti adl3-color">
                                        {prodInfos[3].title}
                                    </span>
                                    <br />
                                    <span
                                        className="product-copyright-sub text-left"
                                        dangerouslySetInnerHTML={{
                                            __html: prodInfos[3].data || null,
                                        }}
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AutoDL3;
