import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch } from "react-redux";
import { reqData } from "../js/server";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function HighwayTv() {
    const dispatch = useDispatch()
    const [posts, setPosts] = useState([])
    
    const fetchData = () => {
        const jsonData = {
            "servicename": "HIGHWAYTV"
        }

        dispatch({ type: 'loadingChange', payload: true })
        reqData(jsonData).then((res) => {
            if (res.data.result === 'OK') {
                setPosts(res.data.jsonData)
            } else {
                alert(res.data.msg)
            }
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setTimeout(() => {
                dispatch({ type: 'loadingChange', payload: false })
            }, 500)
        })
    }

    useEffect(() => {
        dispatch({ type: 'loadingChange', payload: true })

        fetchData()
        // setTimeout(() => {
        //     dispatch({ type: 'loadingChange', payload: false })
        // }, 500)
    },
    // eslint-disable-next-line
    []);

    return (
        <div className="row">
            <div className="col-12 mt-3">
                <Carousel
                    responsive={responsive}
                    draggable={true}
                    autoPlay={true}
                    keyBoardControl={false}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    infinite={true}
                >
                    {
                        (posts.length > 0)
                        ? posts.map((post, i) => {
                            return (
                                <div className="carousel-tv-item-wrapper" key={i}>
                                    <iframe 
                                        src={post.contents}
                                        title={post.title}
                                        width="100%"
                                        height="100%"
                                        frameBorder="0" 
                                        allow="encrypted-media;" 
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            )
                        })
                        : (
                            <>
                                <div className="carousel-tv-item-wrapper">
                                </div>
                                <div className="carousel-tv-item-wrapper">
                                </div>
                                <div className="carousel-tv-item-wrapper">
                                </div>
                                <div className="carousel-tv-item-wrapper">
                                </div>
                                <div className="carousel-tv-item-wrapper">
                                </div>
                            </>
                        )
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default HighwayTv