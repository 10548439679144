import Header from "../comm/Header";
import FaqMenu from "./FaqMenu";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { reqData } from "../../js/server";
import VisibilitySensor from "react-visibility-sensor";
import { cloneObj, fnNotLogin } from "../../js/util";

function Upgrade({ match, location, history }) {
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const [keyword, setKeyword] = useState("");
  const [kind, setKind] = useState(query.kind || "");
  const comcode = query.comcode;
  const [orgPost, setOrgPost] = useState([]);
  const [posts, setPosts] = useState([]);
  const [jsonLength, setJsonLength] = useState(0);
  let isWorking = false;

  const fetchData = () => {
    const jsonData = {
      servicename: "UPGRADE",
      prgname: kind,
      keyword: keyword,
    };

    dispatch({ type: "loadingChange", payload: true });
    reqData(jsonData)
      .then((res) => {
        if (res.data.result === "OK") {
          setJsonLength(res.data.jsonData.length);
          setOrgPost(res.data.jsonData);

          if (res.data.jsonData.length > 20) {
            setPosts(res.data.jsonData.slice(0, 20));
          } else {
            setPosts(res.data.jsonData);
          }
        } else {
          alert(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: "loadingChange", payload: false });
        }, 500);
      });
  };

  const onVisibilityChangeFaqPaging = (isVisible) => {
    if (isVisible) {
      const postsLength = posts.length;
      if (postsLength <= jsonLength) {
        if (!isWorking) {
          dispatch({ type: "loadingChange", payload: true });

          isWorking = true;

          let Obj = cloneObj(posts);

          for (let i = postsLength; i < postsLength + 10; i++) {
            if (posts.length >= jsonLength) {
              break;
            }

            Obj.push(orgPost[i]);
            setPosts(Obj);
          }

          setTimeout(() => {
            dispatch({ type: "loadingChange", payload: false });
          }, 500);
        }
      }

      isWorking = false;
    }
  };

  function fnGetPrgname(prgname) {
    if (prgname.toLowerCase() === "auto7") {
      return "오토7";
    } else if (prgname.toLowerCase() === "autox") {
      return "오토X";
    } else if (prgname.toLowerCase() === "kaima") {
      return "카이마";
    } else if (prgname.toLowerCase() === "autopos") {
      return "오토포스";
    } else if (prgname.toLowerCase() === "neo") {
      return "네오하이웨이";
    } else if (prgname.toLowerCase() === "peo") {
      return "프리미엄네오";
    } else if (prgname.toLowerCase() === "etc") {
      return "부가서비스";
    }
  }

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      if (comcode) {
        localStorage.setItem("comcode", comcode);
      }

      fetchData();
      // setTimeout(() => {
      //     dispatch({ type: 'loadingChange', payload: false })
      // }, 500)
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (localStorage.getItem("comcode")) {
        fetchData();
      } else {
        alert("인트라밴 솔루션 고객만 접근이 가능한 메뉴입니다.");
        fnNotLogin();
      }
    },
    // eslint-disable-next-line
    [kind]
  );

  return (
    <>
      <section className="section cscenter">
        <Header page="sub" />

        <div className="container mt-7">
          <div className="row">
            <div className="col-12">
              <h2 className="text-white">신규기능소개</h2>
              <p className="text-white pt-3">고객센터 &gt; 신규기능소개</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row pt-99">
          <div className="col-12">
            <h2>인트라밴 솔루션의 신규기능을 소개합니다.</h2>

            <p className="pt-3">
              이거 정말 필요하셨던 기능이시라고요? 고객님의 니즈를 최대한 반영할
              수 있도록 최선을 다하겠습니다.
            </p>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-10 mx-auto">
            <FaqMenu kind={kind} setKind={setKind} page="UPGRADE" />
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-12 text-center">
            <div className="search-bar">
              <div className="input-group">
                <input
                  className="form-control transparent-input"
                  type="text"
                  name="keyword"
                  placeholder="제목으로 검색이 가능합니다."
                  maxLength="20"
                  value={keyword || ""}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={fetchData}
                  >
                    <i className="fas fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-5 pb-99">
          <div className="col-12 accd-wrap">
            <table className="table table-hover">
              <tbody>
                {posts.length > 0 ? (
                  posts.map((post, i) => {
                    return (
                      <tr
                        key={i}
                        className="info-table-row"
                        onClick={() => {
                          window.open(post.link, "_blank");
                        }}
                      >
                        <td className="p-4">{fnGetPrgname(post.prgname)}</td>
                        <td className="p-4">
                          {post.title || ""}
                          {post.link.indexOf("blog") > 0 ? (
                            <img
                              src="../assets/iconBlog.png"
                              alt="blog"
                              className="naverIcon"
                            />
                          ) : (
                            <img
                              src="../assets/iconCafe.png"
                              alt="cafe"
                              className="naverIcon"
                            />
                          )}
                        </td>
                        <td className="p-4 text-right">
                          {post.wdate ? post.wdate.substring(0, 10) : ""}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="p-4">결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <VisibilitySensor
          scrollCheck={true}
          onChange={onVisibilityChangeFaqPaging}
          delayedCall
        >
          <div className="row">
            <div className="col-12" style={{ height: 10 }}></div>
          </div>
        </VisibilitySensor>
      </div>
    </>
  );
}

export default Upgrade;
